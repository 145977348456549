import React from 'react';
import PropTypes from 'prop-types';

const SelectBox = ({ label, options, onChange, className, ...props }) => {
	return (
		<>
			{label && <label style={{ color: '#757575' }}>{label}</label>}
			<select
				{...props}
				className={`browser-default custom-select ${className}`}
				onChange={onChange}
			>
				{options.map(({ value, option }) => (
					<option key={option} value={value}>
						{option}
					</option>
				))}
			</select>
		</>
	);
};

SelectBox.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	options: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired
};

SelectBox.defaultProps = {
	className: '',
	label: ''
};

export default SelectBox;
