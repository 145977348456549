import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { MDBModalBody, MDBIcon, MDBBtn, MDBModal, MDBModalHeader, MDBModalFooter } from 'mdbreact';

import {
	Alert,
	CheckboxList,
	ErrorMsg,
	ExpiredTokenAlert,
	ModalSuccess,
	SearchBar,
	StyledModal
} from 'components';
import { clone, selectErrMsg, checkExpiredSession } from 'util/general';
import { companyAPI, userAPI, qboAPI } from 'api';

const StyledBody = styled(MDBModalBody)`
	overflow-y: auto;
	max-height: calc(100vh - 200px);
`;

const original = {
	companies: [],
	customers: []
};

class ImportUserModal extends Component {
	initialState = {
		checkedInputs: [],
		companies: [],
		createLoading: false,
		customers: [],
		error: '',
		errors: [],
		expiredToken: false,
		isOpen: false,
		lastPressedKey: '',
		loading: true,
		success: false,
		showCompanies: false,
		showCustomers: false
	};

	state = clone(this.initialState);

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.isOpen && this.state.isOpen) {
			this.setState({ loading: true });
			qboAPI
				.getUsers()
				.then(res => {
					const { companies, customers } = res.data.result;
					original.companies = companies;
					original.customers = customers;
					this.setState({ companies, customers, loading: false, success: false });
				})
				.catch(e => {
					const expiredToken = checkExpiredSession(e);
					this.setState({
						expiredToken,
						loading: false,
						success: false,
						error: !expiredToken && selectErrMsg(e)
					});
				});
		}
	}

	onChange = e => {
		let current = [...this.state.checkedInputs];
		const index = current.indexOf(e.target.value);
		if (index > -1) {
			current.splice(index, 1);
		} else {
			current.push(e.target.value);
		}
		this.setState({
			checkedInputs: current
		});
	};

	onSubmit = () => {
		this.setState({ createLoading: true });
		const { companies, customers, checkedInputs } = this.state;
		const allUsers = [...companies, ...customers];
		const newUsers = checkedInputs
			.map(userId => allUsers.find(x => userId === x.qboId))
			.reduce(
				(acc, value) => {
					if (value.permissions === 'customer') {
						acc.customers.push(value);
					} else {
						acc.companies.push(value);
					}
					return acc;
				},
				{
					customers: [],
					companies: []
				}
			);
		Promise.all([
			...newUsers.customers.map(x => userAPI.create(x).catch(e => e.response)),
			...newUsers.companies.map(x => companyAPI.createCompany(x).catch(e => e.response))
		]).then(res => {
			const errors = res
				.filter(x => x.status === 500)
				.map(x => {
					const data = JSON.parse(x.config.data);
					return `${data.email}: ${x.data.message}`;
				});

			this.setState({
				success: errors.length < 1 ? true : false,
				createLoading: false,
				errors
			});
		});
	};

	onOpen = () => this.setState({ isOpen: true });
	onClose = () => this.setState(clone(this.initialState));

	customerLabel = input => (
		<>
			<p>Email: {input.email}</p>
			<p>Display Name: {input.displayName}</p>
			<p className="mb-0">Company: {input.ownCompany}</p>
			{!input.email && (
				<p className="text-danger">
					There is no email address for this user. Please update the user in QBO in order to import.
				</p>
			)}
			{!input.CompanyId && (
				<p className="text-danger">
					There is no parent company for this user saved to the site. Please import the parent
					company first.
				</p>
			)}
		</>
	);

	companyLabel = input => (
		<>
			<p>Display Name: {input.displayName}</p>
			<p className="mb-0">Company: {input.name}</p>
			{!input.name && (
				<p className="text-danger">
					There is no company for this user. Please update the user in QBO in order to import.
				</p>
			)}
		</>
	);

	renderChecklist = type => () => this.setState({ [type]: !this.state[type] });

	onSearch = type => e => {
		const arr = original[type];
		const val = e.target.value;
		this.setState({
			[type]: !val ? arr : arr.filter(x => x.displayName.toLowerCase().includes(val.toLowerCase()))
		});
	};

	render() {
		const {
			error,
			errors,
			checkedInputs,
			companies,
			createLoading,
			customers,
			expiredToken,
			isOpen,
			loading,
			success,
			showCompanies,
			showCustomers
		} = this.state;
		return (
			<>
				<MDBBtn onClick={this.onOpen}>
					<MDBIcon icon="file-import" />
					{'  '}
					Import User
				</MDBBtn>
				<StyledModal>
					<MDBModal toggle={this.onClose} isOpen={isOpen} size="lg">
						<MDBModalHeader toggle={this.onClose}>Import users from QBO</MDBModalHeader>
						<StyledBody>
							<ModalSuccess success={success} message="Users have been added!">
								<Fragment>
									{loading ? (
										<div className="text-center">
											<MDBIcon icon="spinner" spin />
										</div>
									) : (
										<>
											<div>
												<h4 className="my-2 mr-2 d-inline-block">Customers</h4>
												<MDBIcon
													onClick={this.renderChecklist('showCustomers')}
													style={{ color: '#407ca0', fontSize: '22px' }}
													className="pointer"
													icon={showCustomers ? 'minus' : 'plus'}
												/>
												{showCustomers && (
													<>
														<SearchBar
															className="w-50"
															onChange={this.onSearch('customers')}
															searchPlaceholder="customer display name"
														/>
														<CheckboxList
															checkedInputs={checkedInputs}
															disabled={input => !input.email || !input.CompanyId}
															label={this.customerLabel}
															inputs={customers}
															onChange={this.onChange}
															title="Customers"
														/>
													</>
												)}
											</div>
											<div>
												<h4 className="my-2 mr-2 d-inline-block">Companies</h4>
												<MDBIcon
													onClick={this.renderChecklist('showCompanies')}
													style={{ color: '#407ca0', fontSize: '22px' }}
													className="pointer"
													icon={showCompanies ? 'minus' : 'plus'}
												/>
												{showCompanies && (
													<>
														<SearchBar
															className="w-50"
															onChange={this.onSearch('companies')}
															searchPlaceholder="company display name"
														/>
														<CheckboxList
															checkedInputs={checkedInputs}
															disabled={input => !input.name}
															label={this.companyLabel}
															inputs={companies}
															onChange={this.onChange}
														/>
													</>
												)}
											</div>
										</>
									)}
									<ExpiredTokenAlert expiredToken={expiredToken} />
									{error && <ErrorMsg message={error} />}
									{errors.map(err => (
										<Alert key={err} show={err} color="danger">
											{err}
										</Alert>
									))}
								</Fragment>
							</ModalSuccess>
						</StyledBody>
						<MDBModalFooter>
							<div className="text-right">
								<MDBBtn
									disabled={!checkedInputs.length || createLoading}
									color="default"
									onClick={this.onSubmit}
									size="md"
								>
									Submit
									{createLoading && <MDBIcon icon="spinner" spin />}
								</MDBBtn>
							</div>
						</MDBModalFooter>
					</MDBModal>
				</StyledModal>
			</>
		);
	}
}

export default ImportUserModal;
