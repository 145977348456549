import Methods from './Methods';

class EmailAPI extends Methods {
	sendUpdateOrderEmail(type, id, data) {
		return super.post(`/send/orderUpdate/${type}/${id}`, data);
	}

	sendContactEmail(data) {
		return super.post('/send/info', data);
	}

	sendResetEmail(data) {
		return super.post('/send/reset', data);
	}

	sendNewEstimateEmail(data) {
		return super.post('/send/newEstimate', data);
	}

	sendWelcomeEmail(data) {
		return super.post('/send/welcomeEmail', data);
	}
}

const emailAPI = new EmailAPI();

export default emailAPI;
