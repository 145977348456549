import Methods from './Methods';

class InvoiceAPI extends Methods {
	get(id = '', params) {
		return super.get(`/invoice/${id}`, params);
	}

	create(data) {
		return super.post('/invoice', data);
	}

	update(id, data) {
		return super.put(`/invoice/${id}`, data);
	}
}

const invoiceAPI = new InvoiceAPI();

export default invoiceAPI;
