import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledEmptyCart = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 70vh;
`;

const EmptyCart = () => (
	<StyledEmptyCart>
		<h3>Your cart is empty</h3>
		<br />
		<Link to="/inventory/BARS/Bar%20Front">Browse our inventory to get started!</Link>
	</StyledEmptyCart>
);

export default EmptyCart;
