import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import CartContainer from './cartView/CartContainer';
import EventFormContainer from './eventForm/EventFormContainer';
import SummaryContainer from './summary/SummaryContainer';
import { HideSideNav, SecuredRoute } from 'components';

const Routes = ({ match: { path } }) => {
	return (
		<SecuredRoute requiredPermissions={['customer']}>
			<HideSideNav>
				<Switch>
					<Route exact path={`${path}/cart`} component={CartContainer} />
					<Route exact path={`${path}/event`} component={EventFormContainer} />
					<Route exact path={`${path}/summary`} component={SummaryContainer} />
					<Route path={`${path}`} component={CartContainer} />
				</Switch>
			</HideSideNav>
		</SecuredRoute>
	);
};

Routes.propTypes = {
	match: PropTypes.object.isRequired
};

export default Routes;
