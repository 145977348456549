import { clone } from 'util/general';

export const selectPrimaryImg = images => {
	if (!images.length) {
		return 'https://dummyimage.com/350x350/20232a/bababa.png&text=Product+Image+Coming+Soon';
	}
	const primary = images.filter(img => img.primary);
	if (primary.length > 0) {
		return primary[0].url;
	} else {
		return images[0].url;
	}
};

export const sortImages = images => {
	return images.sort((a, b) => (a.primary === b.primary ? 0 : a.primary ? -1 : 1)).map(x => x.url);
};

export const formatQboCreateProduct = data => ({
	Name: data.name,
	Type: 'Service',
	Sku: data.id,
	Level: 2,
	SubItem: true,
	UnitPrice: data.price,
	Description: data.description,
	ParentRef: {
		value: data.subcategory.split('__')[0]
	}
});

export const formatLocalProduct = data => {
	const local = clone(data);
	const [, categoryName] = local.category.split('__');
	const [subcategoryId, subcategoryName] = local.subcategory.split('__');
	data.category = categoryName;
	data.subcategory = subcategoryName;
	data.qboParentRef = subcategoryId;
	return data;
};

export const selectFileNames = files => {
	const trimFileNames = names => (names.length > 30 ? `${names.substring(0, 30)}...` : names);
	const names = files.map(file => file.name).toString();
	return names.length ? trimFileNames(names) : 'Choose Image';
};

export const formatImagesForDb = images =>
	images.map(img => {
		img.url = img.url.split('?')[0];
		return img;
	});
