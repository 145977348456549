import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ProfileView from './ProfileView';
import { HideSideNav, SecuredRoute } from 'components';

const Routes = () => {
	return (
		<SecuredRoute requiredPermissions={['admin', 'superadmin', 'customer']}>
			<HideSideNav>
				<Switch>
					<Route path="/profile" component={ProfileView} />
				</Switch>
			</HideSideNav>
		</SecuredRoute>
	);
};

export default Routes;
