import React from 'react';

import DropdownItem from './DropdownItem';

const CustomerDropdown = () => {
	return (
		<>
			<DropdownItem icon="shopping-cart" to="/carts">
				Carts
			</DropdownItem>
			<DropdownItem icon="archive" to="/orders">
				Orders
			</DropdownItem>
			<DropdownItem icon="shopping-basket" to="/checkout/cart">
				Checkout
			</DropdownItem>
		</>
	);
};

export default CustomerDropdown;
