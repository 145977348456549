import React, { Component } from 'react';
import { MDBIcon, MDBTooltip } from 'mdbreact';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BackButton, OrderTabs } from 'components';

const SpanLeft = styled.div`
	display: inline-block;
	border-right: solid 1px #b8b8b8;
	padding-right: 15px;
	cursor: pointer;
`;
const SpanCenter = styled.div`
	display: inline-block;
	padding: 0 15px;
	cursor: pointer;
`;

const SpanRight = styled.div`
	display: inline-block;
	border-left: solid 1px #b8b8b8;
	padding-left: 15px;
	cursor: pointer;
`;

const StyledDisplayText = styled.div`
	font-size: 0.8rem;

	@media (min-width: 768px) {
		font-size: initial;
	}
`;

class UserDetails extends Component {
	static propTypes = {
		user: PropTypes.object,
		parentCompany: PropTypes.object
	};

	state = {
		params: { UserId: this.props.user.id }
	};

	render() {
		const { user, parentCompany } = this.props;
		return (
			<>
				<BackButton />
				<StyledDisplayText className="space-between">
					<div className="mb-4">
						<div>
							<div className="mb-3">
								<h4>
									<MDBTooltip domElement placement="bottom">
										<SpanLeft>{`${parentCompany.name}`}</SpanLeft>
										<span>Parent company</span>
									</MDBTooltip>
									<MDBTooltip domElement placement="bottom">
										<SpanCenter>{`${user.displayName}`}</SpanCenter>
										<span>Display name</span>
									</MDBTooltip>
									<MDBTooltip domElement placement="bottom">
										<SpanRight>{`${user.ownCompany}`}</SpanRight>
										<span>Company</span>
									</MDBTooltip>
								</h4>
							</div>
							<div className="mb-3">
								<SpanLeft>{`${user.firstName} ${user.lastName}`}</SpanLeft>
								<SpanRight>
									{`${parentCompany.street}, ${parentCompany.city}, ${parentCompany.state}, ${
										parentCompany.zipCode
									}`}
								</SpanRight>
							</div>
							<div className="mb-3">
								{user.phone && (
									<span style={{ display: 'inline-block', paddingRight: '15px' }}>
										<MDBIcon icon="phone" size="1x" className="mr-2" />
										{'     '}
										{user.phone}
									</span>
								)}
								<span style={{ display: 'inline-block', paddingLeft: '15px' }}>
									<MDBIcon icon="envelope" size="1x" className="mr-2" />
									{'     '}
									{user.email}
								</span>
							</div>
						</div>
					</div>
				</StyledDisplayText>
				<OrderTabs {...this.props} user={user} params={this.state.params} />
			</>
		);
	}
}

export default UserDetails;
