const qboObject = customer => ({
	GivenName: customer.firstName,
	FamilyName: customer.lastName,
	DisplayName: customer.displayName,
	Active: true,
	PrimaryEmailAddr: {
		Address: customer.email
	},
	CompanyName: customer.company,
	PrimaryPhone: {
		FreeFormNumber: customer.phone
	},
	BillAddr: {
		City: customer.city,
		Line1: customer.street,
		PostalCode: customer.zipCode,
		CountrySubDivisionCode: customer.state
	}
});

export default qboObject;
