import React, { useState, Fragment } from 'react';
import { ModalBody, MDBBtn, MDBIcon, Modal, ModalHeader } from 'mdbreact';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { StyledModal } from 'components';
import browserHistory from 'util/general';

const SubmitModal = props => {
	const [modal, setModal] = useState(false);

	const onClick = () => {
		setModal(!modal);
		props.onSubmit();
	};

	const closeModal = () => browserHistory.replace('/');

	return (
		<StyledModal>
			<MDBBtn
				size="md"
				style={{ cursor: 'pointer' }}
				className="m-0"
				color="unique"
				disabled={!props.checkedTerms}
				onClick={onClick}
			>
				Submit Order
				{'   '}
				<MDBIcon icon="rocket" />
			</MDBBtn>
			<Modal toggle={closeModal} isOpen={modal} size="default">
				<Link style={{ color: 'black' }} to="/">
					<ModalHeader toggle={closeModal}>{props.success || props.error}</ModalHeader>
				</Link>
				<ModalBody>
					{props.loading ? (
						<MDBIcon icon="spinner" spin style={{ color: '#4b515d' }} />
					) : (
						<Fragment>
							<MDBIcon
								far
								size="5x"
								icon={props.success ? 'check-circle' : 'exclamation-triangle'}
								className={props.success ? 'green-text' : 'red-text'}
							/>
							<div style={{ margin: '8px 0' }}>
								{props.success ? (
									<div>
										Your order has been submitted and is pending review. We will reach out to you as
										soon as possible with an update on the status of your order.
										<br />
										<br />
										<div
											className="blue-link"
											onClick={() =>
												browserHistory.replace(`/orders/estimate/${props.newEstimateId}`)
											}
										>
											Click here to view and modify your order
										</div>
									</div>
								) : (
									'An error occurred while submitting the estimate'
								)}
							</div>
						</Fragment>
					)}
				</ModalBody>
			</Modal>
		</StyledModal>
	);
};

SubmitModal.propTypes = {
	newEstimateId: PropTypes.string,
	checkedTerms: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	success: PropTypes.string,
	error: PropTypes.string,
	history: PropTypes.object
};

export default SubmitModal;
