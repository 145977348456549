import { createStore, applyMiddleware, compose } from 'redux';
import promise from 'redux-promise-middleware';

import reducers from './reducers';

const composeEnhancers =
	process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				name: 'Scottsdale Event Decor'
		  })
		: compose;

const enhancer = composeEnhancers(applyMiddleware(promise));
const store = createStore(reducers, enhancer);

export default store;
