import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Context } from './Context';
import { Table, Form } from 'components';

const EventDetails = props => {
	const ctx = useContext(Context);
	const onChange = e => {
		ctx.setState({
			hasBeenModified: true,
			order: {
				...ctx.state.order,
				[e.target.name]: e.target.value
			}
		});
	};

	return (
		<>
			{ctx.state.editMode ? (
				<Form hideBtn={true} autoFocus={false} onChange={onChange} inputs={props.inputs} />
			) : (
				<Table data={props.tableData} />
			)}
		</>
	);
};

EventDetails.propTypes = {
	inputs: PropTypes.array,
	tableData: PropTypes.object
};

export default EventDetails;
