import React from 'react';
import PropTypes from 'prop-types';

const DisplayPrice = ({ id, showProduct, children }) => {
	if (showProduct) {
		return children;
	}
	return (
		<>
			<b>Call or Email us for Details!</b>
			<p>SKU: {id}</p>
			<br />
		</>
	);
};

DisplayPrice.propTypes = {
	id: PropTypes.string.isRequired,
	showProduct: PropTypes.bool.isRequired,
	children: PropTypes.array.isRequired
};

export default DisplayPrice;
