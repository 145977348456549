import { MDBInput } from 'mdbreact';

import { SelectBox } from 'components';

export const formData = (values, categories) => {
	return [
		{
			inputType: MDBInput,
			value: values.name,
			id: 'name',
			name: 'name',
			label: 'Name',
			group: true,
			required: true,
			type: 'text'
		},
		{
			className: 'mb-3',
			inputType: SelectBox,
			id: 'category',
			name: 'category',
			required: true,
			label: 'Category',
			value: values.category,
			options: [
				{
					option: 'Choose a category',
					value: ''
				},
				...categories.categories.map(category => ({
					key: category.Id,
					option: category.Name,
					value: `${category.Id}__${category.Name}`
				}))
			]
		},
		{
			className: 'mb-3',
			inputType: SelectBox,
			id: 'subcategory',
			name: 'subcategory',
			required: true,
			label: 'Sub category',
			value: values.subcategory,
			options: [
				{
					option: 'Choose a sub category',
					value: ''
				},
				...categories.subcategories
					.filter(category => category.ParentRef.value === values.category.split('__')[0])
					.map(sub => {
						return {
							key: sub.Id,
							option: sub.Name,
							value: `${sub.Id}__${sub.Name}`
						};
					})
			]
		},
		{
			inputType: MDBInput,
			value: values.price.toString(),
			id: 'price',
			name: 'price',
			label: 'Price',
			group: true,
			required: true,
			type: 'number'
		},
		{
			inputType: MDBInput,
			value: values.quantity.toString(),
			id: 'quantity',
			name: 'quantity',
			label: 'Quantity',
			group: true,
			required: true,
			type: 'number'
		},
		{
			inputType: MDBInput,
			value: values.description,
			id: 'description',
			name: 'description',
			label: 'Description',
			group: true,
			required: true,
			type: 'textarea',
			rows: '3'
		}
	];
};
