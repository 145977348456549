import React from 'react';
import PropTypes from 'prop-types';
import { MDBIcon } from 'mdbreact';
import styled from 'styled-components';

import browserHistory from 'util/general';

const StyledDiv = styled.div`
	font-size: 1rem;

	@media (min-width: 768px) {
		font-size: 1.25rem;
	}
`;

const BackButton = ({ to, state }) => {
	return (
		<StyledDiv
			className="blue-link pointer mb-3"
			onClick={() => {
				return to
					? browserHistory.push({
							pathname: to,
							state
					  })
					: browserHistory.goBack();
			}}
		>
			<MDBIcon icon="arrow-left" />
			<span className="ml-2">Go Back</span>
		</StyledDiv>
	);
};

BackButton.propTypes = {
	to: PropTypes.string,
	state: PropTypes.object
};

export default BackButton;
