import { MDBInput } from 'mdbreact';

export const formData = values => {
	return [
		{
			inputType: MDBInput,
			value: values.email,
			id: 'email',
			name: 'email',
			label: 'Email',
			group: true,
			required: true,
			type: 'email',
			disabled: true
		},
		{
			inputType: MDBInput,
			value: values.password,
			id: 'password',
			name: 'password',
			label: 'Password',
			group: true,
			required: true,
			type: 'password',
			'data-error-msg': 'Password must be at least 3 characters',
			minLength: 3
		},
		{
			inputType: MDBInput,
			value: values.password2,
			id: 'password2',
			name: 'password2',
			label: 'Confirm Password',
			group: true,
			required: true,
			type: 'password',
			minLength: 3,
			'data-error-msg': 'Password must be at least 3 characters'
		}
	];
};
