import React from 'react';
import { MDBNavItem, MDBNavLink } from 'mdbreact';
import PropTypes from 'prop-types';

const NavItem = ({ active, to, children }) => {
	return (
		<MDBNavItem>
			<MDBNavLink className={`${active === to && 'activeTab'} p-1`} to={to}>
				<span>{children}</span>
			</MDBNavLink>
		</MDBNavItem>
	);
};

NavItem.propTypes = {
	active: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
	children: PropTypes.string.isRequired
};

export default NavItem;
