import React, { Component, Fragment } from 'react';
import { ModalBody } from 'mdbreact';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { selectResponse } from 'util/general';
import { authAPI, emailAPI } from 'api';
import { updateUserInState } from 'redux/actions';
import { Form, Alert } from 'components';
import { formData, resetData } from './formData';

const StyledTos = styled(Link)`
	text-align: center;
	font-size: 12px;
	color: #6c757d !important;

	&:hover {
		color: black !important;
	}
`;

class LoginForm extends Component {
	static propTypes = {
		updateUserInState: PropTypes.func.isRequired,
		toggle: PropTypes.func
	};

	state = {
		loading: false,
		showResetEmail: false,
		credentials: {
			email: '',
			password: ''
		},
		resetEmail: '',
		error: '',
		success: ''
	};

	resetPassBtn = () => this.setState({ showResetEmail: !this.state.showResetEmail });

	onChange = ({ target: { name, value } }) => {
		if (name === 'resetEmail') {
			return this.setState({ [name]: value });
		}
		this.setState({
			credentials: {
				...this.state.credentials,
				[name]: value
			}
		});
	};

	resetRequest = () => {
		this.setState({ loading: true });
		emailAPI
			.sendResetEmail({ email: this.state.resetEmail })
			.then(() => this.setState({ loading: false, success: true }))
			.catch(() => this.setState({ loading: false, success: true }));
	};

	onSubmit = () => {
		const {
			credentials: { email, password }
		} = this.state;
		this.setState({ loading: true });
		authAPI
			.fetchUser(email, password)
			.then(user => {
				if (user.data.result.permissions !== 'customer' && user.data.redirecturl) {
					const redirecturl = user.data.redirecturl;
					window.location = redirecturl;
					this.setState({ success: 'Re-directing to Quickbooks...', error: '', loading: false });
				} else {
					this.setState({ success: 'Login Successful!', loading: false });
					setTimeout(() => {
						this.props.toggle();
						this.props.updateUserInState({ user: selectResponse(user) });
					}, 500);
				}
			})
			.catch(error => {
				let message = 'An error occurred while attempting to log in.';
				if (error.response.status === 403) {
					message = 'Incorrect username or password';
				}
				this.setState({ error: message, loading: false });
			});
	};

	onDismiss = () => {
		this.setState({ success: '', error: '' });
	};

	render() {
		const {
			onChange,
			onSubmit,
			onDismiss,
			resetRequest,
			resetPassBtn,
			state: { error, loading, success, credentials, showResetEmail, resetEmail }
		} = this;
		return (
			<ModalBody>
				{!showResetEmail && (
					<Fragment>
						<Form
							btnText="Log In"
							btnPosition="text-left"
							loading={loading}
							onChange={onChange}
							onSubmit={onSubmit}
							inputs={formData(credentials)}
						/>
						<Alert
							dismiss
							show={error || success}
							color={error ? 'danger' : 'success'}
							onDismiss={onDismiss}
						>
							{error || success}
						</Alert>
						<p className="font-small grey-text d-flex justify-content-end">
							Forgot{' '}
							<span
								onClick={resetPassBtn}
								style={{ cursor: 'pointer' }}
								className="dark-grey-text font-weight-bold ml-1"
							>
								Password?
							</span>
						</p>
						<StyledTos onClick={this.props.toggle} to="/tos">
							<p>View our Privacy Policy & End User License Agreement</p>
						</StyledTos>
					</Fragment>
				)}
				{showResetEmail && (
					<Fragment>
						<p>
							Reset Your Email, or{' '}
							<span className="pointer text-primary" onClick={resetPassBtn}>
								click here to return to main login
							</span>
						</p>
						<Form
							loading={loading}
							onChange={onChange}
							onSubmit={resetRequest}
							btnText="Reset Password"
							inputs={resetData(resetEmail)}
						/>
						<Alert show={success} color="success" dismiss onDismiss={onDismiss}>
							An email has been sent to reset your password. If you do not have an account with us,
							you will not receive an email.
						</Alert>
					</Fragment>
				)}
			</ModalBody>
		);
	}
}

export default connect(
	null,
	{ updateUserInState }
)(LoginForm);
