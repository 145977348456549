import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { userAPI } from 'api';
import { Table, Loading, Alert, InfiniteScroll } from 'components';
import { adminTableData, customerTableData } from './tableData';
import DeleteUserModal from './DeleteUserModal';

const tableType = {
	admin: adminTableData,
	customer: customerTableData
};

class UserTable extends Component {
	static propTypes = {
		clear: PropTypes.func,
		newUserPermissions: PropTypes.string,
		permissions: PropTypes.string.isRequired,
		params: PropTypes.object
	};

	static defaultProps = {
		clear: () => {},
		newUserPermissions: ''
	};

	state = {
		loading: false,
		error: '',
		users: [],
		showModal: false,
		userIdToDelete: '',
		suspendError: ''
	};

	componentDidMount() {
		this.setState({ loading: true });
		this.fetchUsers(this.props.params);
	}

	componentDidUpdate(prevProps) {
		if (this.props.params !== prevProps.params) {
			this.fetchUsers(this.props.params);
		}
		if (this.props.newUserPermissions === this.props.permissions) {
			this.props.clear();
			this.fetchUsers(this.props.params);
		}
	}

	fetchUsers = (params = {}) => {
		userAPI
			.get(undefined, {
				...params,
				limit: 20,
				offset: 0,
				order: ['ownCompany', 'ASC'],
				permissions: this.props.permissions
			})
			.then(res => {
				this.setState({
					users: res.data.result,
					totalCount: res.headers.total_count,
					loading: false,
					error: ''
				});
			})
			.catch(() =>
				this.setState({ error: 'An error occurred while retrieving the users', loading: false })
			);
	};

	suspendUser = (id, activeState) => () => {
		userAPI
			.update(id, { active: !activeState })
			.then(() => this.fetchUsers(this.props.params))
			.catch(() =>
				this.setState({
					suspendError: 'An error occurred while suspending the user',
					loading: false
				})
			);
	};

	showModal = userIdToDelete => () => {
		this.setState({ showModal: true, userIdToDelete });
	};

	hideModal = () => {
		this.setState({ showModal: false, userIdToDelete: '' });
	};

	onScroll = params => {
		return userAPI.get(undefined, {
			...params,
			order: ['ownCompany', 'ASC'],
			permissions: this.props.permissions
		});
	};

	render() {
		const {
			showModal,
			loading,
			error,
			users,
			userIdToDelete,
			suspendError,
			totalCount
		} = this.state;
		const tableData = tableType[this.props.permissions];
		return (
			<Loading loading={loading} notFound={users.length < 1} error={error} notFoundSize="2x">
				<Alert
					dismiss
					color="danger"
					show={suspendError}
					onDismiss={() => this.setState({ suspendError: '' })}
				>
					{suspendError}
				</Alert>
				<InfiniteScroll
					totalCount={totalCount}
					onScroll={this.onScroll}
					initialData={users}
					onError="An error occurred while retrieving the users"
				>
					{pagedData => <Table data={tableData(pagedData, this.suspendUser, this.showModal)} />}
				</InfiniteScroll>
				<DeleteUserModal
					show={showModal}
					userIdToDelete={userIdToDelete}
					fetchUsers={this.fetchUsers}
					hideModal={this.hideModal}
				/>
			</Loading>
		);
	}
}

export default UserTable;
