import React, { Component } from 'react';
import { MDBIcon } from 'mdbreact';
import PropTypes from 'prop-types';

import { Alert } from 'components';
import { qboAPI } from 'api';

class ExpiredTokenAlert extends Component {
	static propTypes = {
		expiredToken: PropTypes.bool.isRequired
	};

	state = {
		error: '',
		loading: false,
		success: false
	};

	renewQboToken = () => {
		this.setState({ loading: true });
		qboAPI
			.renewToken()
			.then(() => {
				this.setState({ loading: false, error: '', expiredToken: false, success: true });
			})
			.catch(() =>
				this.setState({ error: 'There was an error renewing your token', loading: false })
			);
	};

	onDismiss = () => this.setState({ success: false, error: '' });

	render() {
		const { success, error, loading } = this.state;
		if (!this.props.expiredToken) {
			return null;
		}
		if (success) {
			return (
				<Alert show={success} dismiss onDismiss={this.onDismiss} color="success">
					Your session with QuickBooks has been renewed! Please try your previous request again.
				</Alert>
			);
		}

		if (error) {
			return (
				<Alert show={error} dismiss onDismiss={this.onDismiss} color="danger">
					{error}
				</Alert>
			);
		}

		return (
			<Alert show={this.props.expiredToken} color="info">
				Your session with QuickBooks has expired, and the request was not completed!{' '}
				<span className="pointer text-info" onClick={this.renewQboToken}>
					Click here{' '}
				</span>
				to renew your session.
				{loading && <MDBIcon icon="spinner" spin />}
			</Alert>
		);
	}
}

export default ExpiredTokenAlert;
