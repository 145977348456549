import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ErrorMsg } from 'components';

const SecuredRoute = props => {
	if (props.error) {
		return (
			<ErrorMsg message="An error occurred while connecting to the server. Try refreshing the page" />
		);
	}

	if (!sessionStorage.getItem('token')) {
		return <Redirect to="/" />;
	}

	if (!props.permissions) {
		return <div className="loader" />;
	}

	return props.requiredPermissions.includes(props.permissions) ? (
		props.children
	) : (
		<Redirect to="/" />
	);
};

SecuredRoute.propTypes = {
	error: PropTypes.bool,
	children: PropTypes.node,
	permissions: PropTypes.string,
	requiredPermissions: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

const mapStateToProps = ({ userDetails: { user, error } }) => ({
	error,
	permissions: user && user.permissions
});

export default connect(mapStateToProps)(SecuredRoute);
