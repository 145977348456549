import { productAPI, qboAPI, cartAPI } from 'api';
import { clone } from 'util/general';
import { formatQboCreateProduct, formatImagesForDb, formatLocalProduct } from './helpers';

export function saveProduct(user, product, selectedQuantity) {
	const productToSave = {
		price: product.price * selectedQuantity,
		qty: selectedQuantity
	};
	return cartAPI.saveCartProduct(user.cart.id, product.id, productToSave);
}

export async function uploadImagesToAWS(images, product) {
	let finalURLs;
	if (images.length) {
		const req = images.map(file => ({
			id: product.id,
			category: product.category,
			type: file.type
		}));
		const urls = await productAPI.presignedUrl(product.id, req);
		finalURLs = images.map((x, i) => ({ file: x, url: urls.data.result[i].url }));
		await uploadToAWS(finalURLs);
	} else {
		finalURLs = Promise.resolve([
			{
				url: 'https://dummyimage.com/350x350/20232a/bababa.png&text=Product+Image+Coming+Soon'
			}
		]);
	}
	return finalURLs;
}

export async function createProduct(data, images) {
	const qboRes = await qboAPI.qboCreateProduct(formatQboCreateProduct(data));
	const dataForLocalProduct = formatLocalProduct(data);
	const localImages = formatImagesForDb(images);
	localImages[0].primary = true;
	dataForLocalProduct.qboId = qboRes.data.result.Id;
	const finalRes = await productAPI.create({ product: dataForLocalProduct, images: localImages });
	return finalRes;
}

export async function updateProduct(data) {
	const qboData = formatQboCreateProduct(clone(data));
	qboData.Id = data.qboId;
	qboData.SyncToken = data.syncToken;
	const qboRes = await qboAPI.qboUpdateProduct(qboData);
	const localData = formatLocalProduct({ ...data, syncToken: qboRes.data.result.SyncToken });
	const res = await productAPI.update(data.id, localData);
	return res;
}

async function uploadToAWS(data) {
	for (const item of data) {
		await productAPI.uploadImageToAWS(item);
	}
}

export function createCategory(data) {
	const category = {
		Name: data.name,
		SubItem: data.isSubCategory,
		Type: 'Category'
	};
	if (data.isSubCategory) {
		category.ParentRef = { value: data.category };
	}
	return qboAPI.qboCreateProduct(category);
}
