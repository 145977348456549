import React from 'react';
import PropTypes from 'prop-types';

const FileUpload = React.forwardRef(({ id, onChange, label, name, className, ...props }, ref) => {
	return (
		<div className="custom-file">
			<input
				{...props}
				ref={ref}
				id={id}
				name={name}
				onChange={onChange}
				type="file"
				accept="image/*"
				className={`custom-file-input pointer ${className}`}
			/>
			<label className="custom-file-label" htmlFor={id}>
				{label}
			</label>
		</div>
	);
});

FileUpload.propTypes = {
	id: PropTypes.string.isRequired,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	onChange: PropTypes.func.isRequired
};

FileUpload.defaultProps = {
	className: ''
};

export default FileUpload;
