import React, { useContext, Fragment } from 'react';
import {
	MDBIcon,
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBBtn
} from 'mdbreact';
import styled from 'styled-components';

import request from 'api/request';
import { Context } from './Context';
import { CustomerOptions } from './CustomerOptions';
import { AdminOptions } from './AdminOptions';
import { Alert, ToolTipConditional } from 'components';

const StyledDropdown = styled(MDBDropdown)`
	.dropdown-toggle::after {
		display: none;
	}

	@media (min-width: 768px) {
		.dropdown-toggle::after {
			display: inherit;
		}
	}

	@media (max-width: 768px) {
		button {
			padding: 0.84rem;
		}
	}
`;

const Dropdown = () => {
	const ctx = useContext(Context);
	const status = ctx.props.order.status;
	const disabled = status === 'Closed' || status === 'Completed' || status === 'Voided';

	const cancelEdit = () => {
		ctx.setState({
			hasBeenModified: false,
			editMode: false,
			deletedProducts: [],
			cart: {
				...ctx.props.cart
			}
		});
	};

	const onSubmit = async () => {
		// cancels edit if nothing was changed when they hit confirm
		if (!ctx.state.hasBeenModified) {
			ctx.setState({ editMode: false });
			return;
		}
		try {
			const {
				state: { cart, order, deletedProducts },
				props: { orderType }
			} = ctx;
			ctx.setState({ loading: true });
			const productsToSave = cart.CartProducts.map(product => {
				return {
					ProductId: product.id,
					CartId: product.CartId,
					qty: product.qty,
					price: product.qty * product.price
				};
			});
			const productsToDelete = () => {
				if (deletedProducts.length > 0) {
					return request('delete', `/cartProduct/deleteBulk?ids=${deletedProducts}`);
				} else {
					return Promise.resolve();
				}
			};

			// done first to compare the new cart/estimate with the old values on the back-end. Then the order gets updated.
			await request('post', `/send/orderUpdate/${orderType}/${order.id}`, {
				cart: cart.CartProducts,
				order: order
			});

			await Promise.all([
				request('put', `/${orderType.toLowerCase()}/${order.id}`, {
					...order,
					hasBeenModified: true
				}),
				request('post', `/saveBulk/cartProduct/${order.UserId}`, productsToSave),
				productsToDelete()
			]);

			await ctx.afterUpdate({ deletedProducts: [], editMode: false });
		} catch (e) {
			ctx.setState({ error: 'There was an error while submitting your request', loading: false });
		}
	};

	const ViewLinkedOrder = () => {
		const { history, order, orderType } = ctx.props;
		const goToOrder = async () => {
			if (orderType === 'Estimate') {
				try {
					const res = await request('get', '/invoice', null, {
						UserId: order.UserId,
						EstimateId: order.id
					});
					history.push(`/orders/invoice/${res.data.result[0].id}`);
				} catch (e) {
					ctx.setState({ error: 'There was an error while requesting the invoice' });
				}
			}
			if (orderType === 'Invoice') {
				history.push(`/orders/estimate/${order.EstimateId}`);
			}
		};
		return order.hasInvoice || order.EstimateId ? (
			<MDBDropdownItem className="pointer" onClick={goToOrder}>
				{`View Linked ${orderType === 'Estimate' ? 'Invoice' : 'Estimate'}`}
			</MDBDropdownItem>
		) : null;
	};

	const InvoiceOptions = () => {
		const completeOrder = async () => {
			ctx.setState({ loading: true });
			try {
				await request('put', `/invoice/${ctx.props.match.params.id}`, {
					hasBeenModified: false,
					status: 'Completed'
				});
				await ctx.afterUpdate();
			} catch (e) {
				ctx.setState({ error: 'There was an error while updating the invoice', loading: false });
			}
		};

		return (
			<Fragment>
				{ctx.props.user.permissions !== 'customer' && (
					<ToolTipConditional
						shouldRender={ctx.props.order.status === 'Pending'}
						placement="bottom"
						tooltipContent="This invoice must be opened before it can be confirmed"
					>
						<MDBDropdownItem
							disabled={disabled || ctx.props.order.status === 'Pending'}
							className="pointer"
							onClick={completeOrder}
						>
							Complete Order
						</MDBDropdownItem>
					</ToolTipConditional>
				)}
			</Fragment>
		);
	};

	const setEditMode = () => ctx.setState({ editMode: true });

	const editOrderTooltipContent = !ctx.props.canBeModified
		? 'This event will occur in less than 3 days. If you need to change your order please contact us.'
		: `This order is ${ctx.props.order.status}`;

	return (
		<div>
			<div>
				<StyledDropdown>
					<MDBDropdownToggle disabled={ctx.state.loading} caret color="primary">
						{ctx.state.loading ? <MDBIcon spin icon="spinner" /> : 'Options'}
					</MDBDropdownToggle>
					<MDBDropdownMenu basic>
						{!ctx.state.editMode && (
							<ToolTipConditional
								shouldRender={!ctx.props.canBeModified || disabled}
								placement="bottom"
								tooltipContent={editOrderTooltipContent}
							>
								<MDBDropdownItem
									disabled={!ctx.props.canBeModified || disabled}
									className="pointer"
									onClick={setEditMode}
								>
									Edit Order
								</MDBDropdownItem>
							</ToolTipConditional>
						)}
						{ctx.props.user.permissions === 'customer' ? (
							<CustomerOptions disabled={disabled} />
						) : (
							<AdminOptions disabled={disabled} />
						)}
						{ctx.props.orderType === 'Invoice' && <InvoiceOptions />}
						<ViewLinkedOrder />
					</MDBDropdownMenu>
				</StyledDropdown>
				{ctx.state.editMode && (
					<div>
						<MDBBtn
							outline
							color="danger"
							size="md"
							disabled={ctx.state.loading}
							onClick={cancelEdit}
						>
							Cancel
						</MDBBtn>
						<MDBBtn size="md" color="dark-green" disabled={ctx.state.loading} onClick={onSubmit}>
							Update Order
						</MDBBtn>
					</div>
				)}
			</div>
			{ctx.state.success && (
				<Alert
					color="success"
					show
					onDismiss={setTimeout(() => ctx.setState({ success: false }), 2500)}
				>
					<MDBIcon icon="check" />
					{'  '}
					Success
				</Alert>
			)}
		</div>
	);
};

export default Dropdown;
