import moment from 'moment';
import { formatColumnName } from 'util/general';

export const productData = data => {
	const rows = data.map(item => {
		return {
			id: item.id,
			Product: item.name,
			Quantity: item.qty,
			Price: `$${item.price}`,
			Total: `$${item.total}`
		};
	});

	const columns = ['Product', 'Quantity', 'Price', 'Total'];

	return {
		columns,
		rows
	};
};

export const formatEventData = (data, keys) => {
	const rows = keys.map((item, index) => {
		let eventDetail = data[item];
		if (item.includes('Date') && eventDetail) {
			eventDetail = moment(eventDetail).format('MMMM Do YYYY');
		}
		if (item.includes('Time') && eventDetail) {
			eventDetail = moment(eventDetail, 'HH:mm').format('h:mm A');
		}
		return {
			id: index,
			'Event Details': formatColumnName(item),
			'Your Event': eventDetail
		};
	});

	const columns = ['Event Details', 'Your Event'];

	return {
		columns,
		rows
	};
};

// TODO: remove this function and use above function
export const eventData = data => {
	const rows = data.map((item, index) => {
		return {
			id: index,
			'Event Details': item['Event Details'],
			'Your Event': item['Your Event']
		};
	});

	const columns = ['Event Details', 'Your Event'];

	return {
		columns,
		rows
	};
};
