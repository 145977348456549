import React, { Component } from 'react';
import { MDBIcon } from 'mdbreact';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import EditProductModal from './EditProductModal';

const ButtonGroup = styled.div`
	border: solid 1px #d8d8d8;
	border-radius: 2px;

	div {
		display: inline-block;
		padding: 6px 11px;
		border-right: solid 1px #d8d8d8;
	}

	div:last-of-type {
		border-right: none;
	}
`;

class EditProductContainer extends Component {
	static propTypes = {
		product: PropTypes.object,
		permissions: PropTypes.string,
		fetchProduct: PropTypes.func.isRequired,
		fetchCategories: PropTypes.func.isRequired,
	};

	state = {
		showModal: false,
		deleteModal: false,
	};

	onClose = async () => {
		this.setState({ showModal: false });
		this.props.fetchProduct();
		this.props.fetchCategories(false);
	};

	toggleModal = (deleteModal) => () => this.setState({ showModal: true, deleteModal });

	render() {
		const { permissions, product } = this.props;
		const perms = permissions || '';
		if (!perms.includes('admin')) return null;
		return (
			<div className="mb-3 d-flex justify-content-end">
				<ButtonGroup>
					<div className="pointer" onClick={this.toggleModal(false)}>
						<MDBIcon style={{ color: '#475e94' }} icon="pencil-alt" />
					</div>
					<div className="text-danger pointer" onClick={this.toggleModal(true)}>
						<MDBIcon icon="trash-alt" />
					</div>
				</ButtonGroup>
				<EditProductModal
					product={product}
					onClose={this.onClose}
					permissions={permissions}
					showModal={this.state.showModal}
					deleteModal={this.state.deleteModal}
				/>
			</div>
		);
	}
}

export default EditProductContainer;
