import React, { Component } from 'react';
import { MDBCol, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBInput, MDBIcon } from 'mdbreact';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { cartAPI } from 'api';

const StyledCard = styled(MDBCardBody)`
	padding-left: 0px;
	div:first-child,
	div:last-child {
		padding-left: 1.25rem;
	}

	div:nth-child(2) {
		margin: 0.5rem 0px;
		span {
			padding: 0.25rem 1.25rem;
		}
	}
`;

class CartItem extends Component {
	static propTypes = {
		cart: PropTypes.shape({
			id: PropTypes.string,
			date: PropTypes.string,
			UserId: PropTypes.string,
			isActive: PropTypes.bool,
			cartName: PropTypes.string,
			cartTotal: PropTypes.number,
			didCheckOut: PropTypes.bool,
			CartProducts: PropTypes.array
		}),
		setCart: PropTypes.func,
		getCarts: PropTypes.func,
		handleError: PropTypes.func
	};

	state = {
		error: false,
		changeName: false,
		cartName: this.props.cart.cartName
	};

	changeName = () => {
		this.setState({ changeName: !this.state.changeName });
		if (this.state.changeName) {
			this.setState({ changeName: !this.state.changeName });
			cartAPI.updateCart(this.props.cart.id, { cartName: this.state.cartName }).catch(() => {
				this.props.handleError('An error occurred while updating the cart name');
			});
		}
	};

	setActiveCart = () => {
		cartAPI
			.getCart(this.props.cart.id, { isActive: true })
			.then(res => {
				return Promise.all([
					cartAPI.updateCart(res.data.result[0].id, { isActive: false }),
					cartAPI.updateCart(this.props.cart.id, { isActive: true })
				]);
			})
			.then(() => {
				this.props.setCart(this.props.cart);
				this.props.getCarts();
			})
			.catch(() => this.props.handleError('An error occurred while updating the cart'));
	};

	onChange = e => {
		this.setState({
			cartName: e.target.value
		});
	};

	delete = () => {
		cartAPI
			.deleteCart(this.props.cart.id)
			.then(() => this.props.getCarts())
			.catch(() => this.props.handleError('An error occurred while deleting the cart'));
	};

	render() {
		return (
			<MDBCol style={{ marginBottom: '30px' }} lg="4">
				<MDBCard style={{ minHeight: '303px' }}>
					<StyledCard>
						<div>
							{this.state.changeName ? (
								<MDBInput
									type="text"
									name="cartName"
									value={this.state.cartName}
									onChange={this.onChange}
								/>
							) : (
								<MDBCardTitle>{this.state.cartName}</MDBCardTitle>
							)}
						</div>
						<div>
							{this.props.cart.isActive ? (
								<span className="bg-success text-white rounded-right">Active</span>
							) : (
								<br />
							)}
						</div>
						<div>
							<Link className="text-white" to={`carts/${this.props.cart.id}`}>
								<MDBBtn size="md" className="text-white w-100 mx-0">
									View
								</MDBBtn>
							</Link>
							{!this.props.cart.isActive && (
								<MDBBtn onClick={this.setActiveCart} size="md" className="text-white w-100 mx-0">
									Set Active Cart
								</MDBBtn>
							)}
							<MDBBtn onClick={this.changeName} size="md" className="text-white w-100 mx-0">
								{this.state.changeName ? 'Save' : 'Change Name'}
							</MDBBtn>
							{!this.props.cart.isActive && (
								<MDBBtn onClick={this.delete} className="text-white w-100 mx-0" size="md">
									<MDBIcon far icon="trash-alt" />
									{'   '}
									Delete
								</MDBBtn>
							)}
						</div>
					</StyledCard>
				</MDBCard>
			</MDBCol>
		);
	}
}

export default CartItem;
