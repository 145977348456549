import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { HideSideNav } from 'components';

import ResetPassContainer from './ResetPassContainer';

const Routes = () => (
	<HideSideNav>
		<Switch>
			<Route path="/reset/:token" component={ResetPassContainer} />
		</Switch>
	</HideSideNav>
);

export default Routes;
