import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import styled from 'styled-components';
import store from './redux/store';
import App from './pages/App';

const MainStyle = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const ScottsdaleEventDecor = () => (
	<Provider store={store}>
		<MainStyle>
			<App />
		</MainStyle>
	</Provider>
);

ReactDOM.createRoot(document.getElementById('root')).render(<ScottsdaleEventDecor />);
