import React, { Component } from 'react';
import { MDBCol, MDBRow, MDBCardBody } from 'mdbreact';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Alert, BackButton } from 'components';
import { saveProduct } from '../requests';
import AddProductBtn from '../AddProductBtn';
import RenderImages from './RenderImages';
import { sortImages } from '../helpers';
import EditProductContainer from '../editProduct/EditProductContainer';

const MainImage = styled.img`
	max-width: 300px;
	max-height: 300px;

	@media (min-width: 768px) {
		max-width: 600px;
		max-height: 600px;
	}
`;

class ShowPage extends Component {
	static propTypes = {
		user: PropTypes.object,
		product: PropTypes.object,
		fetchProduct: PropTypes.func,
		fetchCategories: PropTypes.func,
	};

	state = {
		editMode: false,
		selectedQuantity: 0,
		images: [],
		success: false,
		error: false,
	};

	componentDidMount() {
		this.setState({
			images: sortImages(this.props.product.Images),
		});
	}

	componentDidUpdate(prevProps) {
		const prev = prevProps.product.Images;
		const current = this.props.product.Images;
		if (prev.length !== current.length) {
			this.setState({ images: sortImages(current) });
		}
	}

	onChange = ({ target: { value } }) => {
		this.setState({ selectedQuantity: parseInt(value) });
	};

	pictureMover = (index) => {
		const newImagesArray = [...this.state.images];
		const newVariable = newImagesArray.splice(index, 1);
		newImagesArray.unshift(newVariable);
		this.setState({ images: newImagesArray });
	};

	// css styles for the extra images
	respClasses = (leftCol, btmRow) => {
		const { images } = this.state;
		const display = (offset = '') =>
			`col-12 ${images.length > 1 ? 'col-lg-10' + offset : 'col-lg-12'} text-center`;

		if (leftCol) {
			return display();
		}

		if (btmRow) {
			return display(' offset-lg-2');
		}
	};

	saveToCart = () => {
		const { user, product } = this.props;
		const { selectedQuantity } = this.state;
		if (selectedQuantity > 0 && selectedQuantity <= product.quantity) {
			saveProduct(user, product, selectedQuantity)
				.then(() => this.setState({ success: true, error: false }))
				.catch(() => this.setState({ error: true, success: false }));
		}
	};

	onDismiss = () => {
		this.setState({ success: false, error: false });
	};

	onEdit = () => this.setState({ editMode: !this.state.editMode });

	render() {
		const product = this.props.product;
		const { images, success, error } = this.state;
		const permissions = this.props.user && this.props.user.permissions;
		return (
			<>
				<div className="space-between">
					<BackButton />
					<EditProductContainer
						product={product}
						fetchCategories={this.props.fetchCategories}
						permissions={permissions}
						fetchProduct={this.props.fetchProduct}
					/>
				</div>
				<MDBRow>
					<RenderImages
						images={images}
						size="lg"
						name={product.name}
						className="col-lg-2 d-none d-lg-block"
						pictureMover={this.pictureMover}
					/>
					<MDBCol className={this.respClasses(true, false)}>
						<h2 className="mb-0">{product.name}</h2>
						<MainImage
							src={images[0]}
							className="d-block img-fluid mx-auto my-3 z-depth-1 main-show"
							alt={product.name}
						/>
						<p style={{ fontSize: '20px' }}>{product.description}</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCardBody className="text-center col-12">
						<div className="col-md-12 border-bottom pb-3 pb-sm-3">
							<MDBCol className={this.respClasses(false, true)}>
								<AddProductBtn
									permissions={permissions}
									product={this.props.product}
									onChange={this.onChange}
									saveToCart={this.saveToCart}
									selectedQuantity={this.state.selectedQuantity}
								/>
								<Alert
									show={success}
									className="mt-2 mx-auto w-25"
									color="success"
									onDismiss={this.onDismiss}
									dismiss
								>
									Success!
								</Alert>
								<Alert
									show={error}
									className="mt-2 mx-auto w-25"
									color="danger"
									onDismiss={this.onDismiss}
									dismiss
								>
									an error occurred
								</Alert>
							</MDBCol>
						</div>
					</MDBCardBody>
					<RenderImages
						images={images}
						size="sm"
						name={product.name}
						className="col-3 px-2 d-lg-none"
						pictureMover={this.pictureMover}
					/>
				</MDBRow>
			</>
		);
	}
}

export default ShowPage;
