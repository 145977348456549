import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CartListContainer from './cartList/CartListContainer';
import CartViewContainer from './cartView/CartViewContainer';
import { SecuredRoute, HideSideNav } from 'components';

const Routes = () => (
	<SecuredRoute requiredPermissions={['customer']}>
		<HideSideNav>
			<Switch>
				<Route exact path="/carts" component={CartListContainer} />
				<Route exact path="/carts/:id" component={CartViewContainer} />
			</Switch>
		</HideSideNav>
	</SecuredRoute>
);

export default Routes;
