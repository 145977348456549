import React from 'react';
import { MDBIcon } from 'mdbreact';

import { InventorySelect } from 'components';

const trashIcon = (deleteProduct, product) => (
	<MDBIcon far icon="trash-alt" className="text-danger pointer" onClick={deleteProduct(product)} />
);

export const tableData = (data, onChange, deleteProduct, isOrderView) => {
	const rows = data.map((product, i) => {
		return {
			id: product.id,
			Product: product.name,
			Quantity: (
				<InventorySelect
					startAt={1}
					lable="Current:"
					name={product.name}
					data-id={product.id}
					onChange={onChange}
					value={product.qty}
					data-price={product.price}
					quantity={product.quantity}
				/>
			),
			Available: product.quantity,
			Price: `$${product.price}`,
			Total: `$${product.total}`,
			'': isOrderView
				? data.length > 1 && trashIcon(deleteProduct, product)
				: trashIcon(deleteProduct, product)
		};
	});

	const columns = ['Product', 'Quantity', 'Available', 'Price', 'Total', ''];

	return {
		columns,
		rows
	};
};
