import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CartList from './CartList';
import { setCart } from 'redux/actions';
import { Loading } from 'components';
import { cartAPI } from 'api';

class CartListContainer extends Component {
	static propTypes = {
		user: PropTypes.object
	};

	state = {
		carts: [],
		error: '',
		loading: false,
		totalCount: ''
	};

	componentDidMount() {
		this.setState({ loading: true });
		this.getCarts();
	}

	getCarts = () => {
		cartAPI
			.getCart(undefined, {
				didCheckOut: false,
				order: ['createdAt', 'DESC'],
				limit: 20,
				offset: 0
			})
			.then(res => {
				this.setState({
					carts: res.data.result,
					totalCount: res.headers.total_count,
					loading: false,
					error: ''
				});
			})
			.catch(() =>
				this.setState({ error: 'An error occurred while loading your carts', loading: false })
			);
	};

	render() {
		const { loading, error, carts, totalCount } = this.state;
		return (
			<Loading loading={loading} error={error} notFound={carts.length < 1}>
				<CartList
					{...this.props}
					getCarts={this.getCarts}
					carts={this.state.carts}
					totalCount={totalCount}
				/>
			</Loading>
		);
	}
}

const mapStateToProps = ({ userDetails: { user } }) => {
	return {
		user
	};
};

export default connect(
	mapStateToProps,
	{ setCart }
)(CartListContainer);
