import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { MDBDropdownItem } from 'mdbreact';

import { checkExpiredSession, selectErrMsg } from 'util/general';
import { ToolTipConditional } from 'components';
import { Context } from './Context';

export const AdminOptions = ({ disabled }) => {
	const ctx = useContext(Context);

	const sendToQbo = async () => {
		try {
			if (ctx.props.orderType === 'Estimate') {
				ctx.setState({ loading: true });
				await ctx.qbo.createEstimate();
			} else {
				ctx.setState({ loading: true });
				await ctx.qbo.createInvoice();
			}
			await ctx.afterUpdate();
		} catch (e) {
			const expiredToken = checkExpiredSession(e);
			ctx.setState({
				expiredToken,
				loading: false,
				error: !expiredToken && selectErrMsg(e)
			});
		}
	};

	const createInvoice = async () => {
		try {
			ctx.setState({ loading: true });
			await ctx.qbo.createInvoice();
			await ctx.afterUpdate();
		} catch (e) {
			const expiredToken = checkExpiredSession(e);
			ctx.setState({
				expiredToken,
				loading: false,
				error: !expiredToken && selectErrMsg(e)
			});
		}
	};

	const isSendToQboDisabled =
		disabled || ctx.props.order.isInQbo === true || ctx.props.order.hasInvoice === true;

	const sendToQboTooltipContent =
		ctx.props.order.isInQbo === true || ctx.props.order.hasInvoice
			? 'This order is already in Quick Books.'
			: disabled
			? `This order is ${ctx.props.order.status}.`
			: 'This option is disabled.';

	const createInvoiceTooltipContent =
		ctx.props.order.hasInvoice === true
			? 'An invoice has already been created for this order.'
			: `An invoice cannot be created because the status of this order is ${
					ctx.props.order.status
			  }`;

	return (
		<Fragment>
			{!ctx.props.order.isInqbo && (
				<ToolTipConditional
					shouldRender={isSendToQboDisabled}
					placement="bottom"
					tooltipContent={sendToQboTooltipContent}
				>
					<MDBDropdownItem disabled={isSendToQboDisabled} onClick={sendToQbo} className="pointer">
						Send to Quick Books
					</MDBDropdownItem>
				</ToolTipConditional>
			)}
			{ctx.props.orderType === 'Estimate' && (
				<ToolTipConditional
					shouldRender={disabled || ctx.props.order.hasInvoice === true}
					placement="bottom"
					tooltipContent={createInvoiceTooltipContent}
				>
					<MDBDropdownItem
						disabled={disabled || ctx.props.order.hasInvoice === true}
						onClick={createInvoice}
						className="pointer"
					>
						Create Invoice
					</MDBDropdownItem>
				</ToolTipConditional>
			)}
			<ToolTipConditional
				shouldRender={disabled}
				placement="bottom"
				tooltipContent={`The status of this order is ${
					ctx.props.order.status
				}, and cannot be cancelled from the site.`}
			>
				<MDBDropdownItem
					disabled={disabled}
					onClick={() => ctx.setState({ showCancelModal: true })}
					className="pointer"
				>
					Cancel Order
				</MDBDropdownItem>
			</ToolTipConditional>
		</Fragment>
	);
};

AdminOptions.propTypes = {
	disabled: PropTypes.bool
};
