import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { MDBDropdownItem } from 'mdbreact';

import { checkExpiredSession, selectErrMsg } from 'util/general';

import request from 'api/request';
import { ToolTipConditional } from 'components';
import { Context } from './Context';

export const CustomerOptions = ({ disabled }) => {
	const ctx = useContext(Context);

	const copyCart = () => {
		ctx.setState({ loading: true });
		request('put', `/cart/${ctx.props.cart.id}/copy/${ctx.props.user.cart.id}`)
			.then(() => ctx.setState({ loading: false, success: true }))
			.catch(() =>
				ctx.setState({ loading: false, error: 'An error occurred while copying the cart' })
			);
	};

	const tooltipContent = ctx.props.order.hasInvoice
		? 'This estimate is linked to an existing invoice.'
		: ctx.props.order.status === 'Pending'
		? 'You may request an invoice once your estimate has been approved.'
		: disabled
		? `This order is ${ctx.props.order.status}`
		: ctx.props.order.hasBeenModified
		? 'This order has been modified. You can request an invoice after your update has been approved.'
		: 'This option is disabled';

	const requestInvoice = async () => {
		try {
			ctx.setState({ loading: true });
			await Promise.all([
				request('post', '/invoice', {
					...ctx.state.order,
					qboId: null,
					isInQbo: false,
					status: 'Pending',
					UserId: ctx.state.order.UserId,
					CartId: ctx.state.order.CartId,
					EstimateId: ctx.state.order.id
				}),
				request('put', `/estimate/${ctx.props.order.id}`, {
					...ctx.state.order,
					hasInvoice: true,
					status: 'Closed',
					hasBeenModified: false
				})
			]);

			await ctx.afterUpdate();
		} catch (e) {
			const expiredToken = checkExpiredSession(e);
			ctx.setState({
				expiredToken,
				loading: false,
				error: !expiredToken && selectErrMsg(e)
			});
		}
	};

	return (
		<>
			{ctx.props.orderType === 'Estimate' && (
				<ToolTipConditional
					shouldRender={
						disabled ||
						ctx.props.order.hasBeenModified ||
						ctx.props.order.hasInvoice ||
						ctx.props.order.status === 'Pending'
					}
					placement="bottom"
					tooltipContent={tooltipContent}
				>
					<MDBDropdownItem
						disabled={
							disabled ||
							ctx.props.order.hasBeenModified ||
							ctx.props.order.hasInvoice ||
							ctx.props.order.status === 'Pending'
						}
						className="pointer"
						onClick={requestInvoice}
					>
						Request Invoice
					</MDBDropdownItem>
				</ToolTipConditional>
			)}
			<MDBDropdownItem className="pointer" onClick={copyCart}>
				Copy To Active Cart
			</MDBDropdownItem>
		</>
	);
};

CustomerOptions.propTypes = {
	disabled: PropTypes.bool
};
