import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Loading } from 'components';
import CompanyDetails from './CompanyDetails';
import { companyAPI } from 'api';

const CompanyDetailsContainer = props => {
	const [company, setCompany] = useState([]);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		companyAPI
			.getCompany(props.match.params.id)
			.then(res => {
				setCompany(res.data.result);
				setError('');
				setLoading(false);
			})
			.catch(() => {
				setCompany([]);
				setError('An error occurred while retrieving the company');
				setLoading(false);
			});
	}, [props.match.params.id]);

	return (
		<Loading loading={loading} error={error} notFound={company.length < 1}>
			<CompanyDetails {...props} company={company[0]} />
		</Loading>
	);
};

CompanyDetailsContainer.propTypes = {
	match: PropTypes.object
};

export default CompanyDetailsContainer;
