import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import Tab from './Tab';

const StyledContent = styled.div`
	margin-top: 15px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const StyledTabPane = styled.div`
	-webkit-animation: ${fadeIn} 0.15s linear;
	-moz-animation: ${fadeIn} 0.15s linear;
	animation: ${fadeIn} 0.15s linear;
`;

class Tabs extends Component {
	static propTypes = {
		defaultActiveKey: PropTypes.number,
		children: PropTypes.array.isRequired
	};

	state = {
		activeTab: this.props.children[0].props.label
	};

	onClickTabItem = tab => {
		this.setState({ activeTab: tab });
	};

	render() {
		const {
			onClickTabItem,
			props: { children },
			state: { activeTab }
		} = this;

		return (
			<div className="tabs">
				<ul role="tablist" className="nav nav-tabs">
					{children.map((child, index) => {
						if (child) {
							const { label } = child.props;
							return (
								<Tab activeTab={activeTab} key={label} label={label} onClick={onClickTabItem} />
							);
						}
						return null;
					})}
				</ul>
				<StyledContent>
					{children.map(child => {
						if (!child || child.props.label !== activeTab) return undefined;
						return <StyledTabPane key={child.props.label}>{child.props.children}</StyledTabPane>;
					})}
				</StyledContent>
			</div>
		);
	}
}

export default Tabs;
