import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	MDBIcon,
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBDropdownItem
} from 'mdbreact';

import { emailAPI } from 'api';

const UserOptions = ({ user, onSuspend, showModal }) => {
	const [loading, setLoading] = useState(false);
	const icon = loading ? 'spinner' : 'cog';
	return (
		<MDBDropdown size="sm">
			<MDBDropdownToggle outline caret color="blue">
				<MDBIcon icon={icon} spin={loading} size="1x" />
			</MDBDropdownToggle>
			<MDBDropdownMenu color="danger" basic>
				<MDBDropdownItem onClick={onSuspend}>{user.active ? 'Suspend' : 'Enable'}</MDBDropdownItem>
				<MDBDropdownItem onClick={showModal}>Delete</MDBDropdownItem>
				<MDBDropdownItem
					onClick={() => {
						setLoading(true);
						emailAPI
							.sendWelcomeEmail({ email: user.email })
							.then(() => {
								setLoading(false);
							})
							.catch(() => {
								setLoading(false);
							});
					}}
				>
					Resend welcome email
				</MDBDropdownItem>
			</MDBDropdownMenu>
		</MDBDropdown>
	);
};

UserOptions.propTypes = {
	user: PropTypes.object,
	onSuspend: PropTypes.func,
	showModal: PropTypes.func
};

export default UserOptions;
