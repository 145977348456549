import React, { Component } from 'react';
import { MDBModal, MDBModalHeader, MDBNavItem } from 'mdbreact';

import LoginForm from './LoginForm';
import { StyledModal } from 'components';

class LoginModal extends Component {
	state = {
		showModal: false
	};

	toggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	render() {
		return (
			<>
				<MDBNavItem className="pointer p-1" onClick={this.toggleModal}>
					Login
				</MDBNavItem>
				<StyledModal>
					<MDBModal isOpen={this.state.showModal} toggle={this.toggleModal} size="default">
						<MDBModalHeader toggle={this.toggleModal}>Welcome</MDBModalHeader>
						<LoginForm toggle={this.toggleModal} />
					</MDBModal>
				</StyledModal>
			</>
		);
	}
}

export default LoginModal;
