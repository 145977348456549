import { formatColumnName, sumTotal } from 'util/general';
import { estimateAPI, invoiceAPI, qboAPI } from 'api';

const eventDetails = {
	customerName: '',
	groupName: '',
	location: '',
	operationsManager: '',
	eventDate: '',
	eventStartTime: '',
	eventEndTime: '',
	room: '',
	commentsOnEvent: '',
	loadInTime: '',
	setByTime: '',
	strikeTime: '',
	commentsOnSetup: '',
	willCallCustomerName: '',
	willCallPickupDate: '',
	willCallPickupTime: '',
	willCallReturnDate: '',
	willCallReturnTime: ''
};

class QboRequest {
	constructor(cart, order, orderType, warehouse) {
		this.cart = cart;
		this.order = order;
		this.orderType = orderType;
		this.warehouse = warehouse;
	}

	setPrivateNote = () => {
		let privateNote = '';
		for (const key in eventDetails) {
			const value = this.order[key];
			if (value) {
				privateNote += `${formatColumnName(key)}: ${value}\n`;
			}
		}
		return privateNote;
	};

	orderObject = () => ({
		Line: this.lineItems(),
		CustomerRef: {
			value: this.order.User.qboId
		},
		TxnDate: this.order.eventDate,
		DueDate: this.order.eventDate,
		PrivateNote: this.setPrivateNote(),
		CustomField: [
			{
				DefinitionId: '1',
				Type: 'StringType',
				Name: 'Group Name',
				StringValue: this.order.groupName
			},
			{
				DefinitionId: '2',
				Type: 'StringType',
				Name: 'Event Time',
				StringValue: this.order.eventStartTime
			},
			{
				DefinitionId: '3',
				Type: 'StringType',
				Name: 'Location',
				StringValue: this.order.location
			}
		]
	});

	lineItems = () => {
		let labor = sumTotal(this.cart) > 600 ? parseFloat(Math.round(sumTotal(this.cart) * 0.15 * 100) / 100).toFixed(2) : 100
		const products = this.cart.CartProducts.map(item => {
			return {
				DetailType: 'SalesItemLineDetail',
				SalesItemLineDetail: {
					Qty: item.qty,
					UnitPrice: item.price,
					ItemRef: {
						name: item.name,
						value: item.qboId
					},
					TaxCodeRef: {
						value: 'TAX'
					},
					ServiceDate: this.order.eventDate
				},
				Amount: item.total,
				Description: item.description
			};
		});
		this.warehouse.forEach((item = {}) => {
			products.push({
				DetailType: 'SalesItemLineDetail',
				SalesItemLineDetail: {
					Qty: item.qty,
					UnitPrice: item.name === 'Labor' ? labor : item.price,
					ItemRef: {
						name: item.name,
						value: item.qboId
					},
					TaxCodeRef: {
						value: 'TAX'
					},
					ServiceDate: this.order.eventDate
				},
				Amount: item.name === 'Labor' ? labor : item.price,
				Description: item.description
			});
		});
		return products;
	};

	rejectEstimate = async () => {
		const obj = this.orderObject();
		obj.TxnStatus = 'Rejected';
		obj.Id = this.order.qboId;
		obj.SyncToken = this.order.syncToken;
		obj.sparse = true;
		await qboAPI.updateEstimateInQbo(obj);
	};

	voidInvoice = async () => {
		await qboAPI.updateInvoiceInQbo({
			void: true,
			Id: this.order.qboId,
			sparse: true,
			SyncToken: this.order.syncToken
		});
	};

	createEstimate = async (sendConfirmation = true) => {
		try {
			const obj = this.orderObject();
			obj.ExpirationDate = this.order.eventDate;
			obj.TxnStatus = 'Accepted';
			const result = await qboAPI.sendEstimateToQbo(obj);
			await Promise.all([
				sendConfirmation
					? qboAPI.sendConfirmation('estimate', result.data.result.Id, this.order.User.email)
					: Promise.resolve(),
				estimateAPI.update(this.order.id, {
					...this.order,
					isInQbo: true,
					status: 'Open',
					hasBeenModified: false,
					DocNumber: result.data.result.DocNumber,
					qboId: result.data.result.Id,
					syncToken: result.data.result.SyncToken
				})
			]);
			return Promise.resolve(result.data.result.Id);
		} catch (e) {
			return Promise.reject(e);
		}
	};

	updateEstimate = async (qboInvoiceId = null, sendConfirmation = true) => {
		try {
			const estimateId = this.orderType === 'Estimate' ? this.order.id : this.order.EstimateId;
			const obj = this.orderObject();
			obj.sparse = true;
			obj.Id = this.order.qboId;
			obj.SyncToken = this.order.syncToken;
			if (qboInvoiceId) {
				obj.LinkedTxn = [
					{
						TxnId: qboInvoiceId,
						TxnType: 'Invoice'
					}
				];
			}
			const result = await qboAPI.updateEstimateInQbo(obj);
			await Promise.all([
				sendConfirmation
					? qboAPI.sendConfirmation('estimate', result.data.result.Id, this.order.User.email)
					: Promise.resolve(),
				estimateAPI.update(estimateId, {
					...this.order,
					isInQbo: true,
					hasBeenModified: false,
					qboId: result.data.result.Id,
					DocNumber: result.data.result.DocNumber,
					syncToken: result.data.result.SyncToken,
					status: qboInvoiceId ? 'Closed' : 'Open',
					hasInvoice: qboInvoiceId ? true : false
				})
			]);
		} catch (e) {
			return Promise.reject(e);
		}
	};

	createInvoice = async () => {
		try {
			let qboEstimateId;
			if (this.orderType === 'Estimate' && !this.order.qboId) {
				const estimateId = await this.createEstimate(false);
				qboEstimateId = estimateId;
			} else if (this.orderType === 'Estimate' && this.order.qboId) {
				qboEstimateId = this.order.qboId;
			} else if (this.orderType === 'Invoice') {
				qboEstimateId = this.order.Estimate.qboId;
			}
			const obj = this.orderObject();
			obj.TxnStatus = 'Open';
			obj.LinkedTxn = [
				{
					TxnId: qboEstimateId,
					TxnType: 'Estimate'
				}
			];
			const result = await qboAPI.sendInvoiceToQbo(obj);
			await Promise.all([
				qboAPI.sendConfirmation('invoice', result.data.result.Id, this.order.User.email),
				this.updateEstimate(result.data.result.Id, false),
				this.orderType === 'Estimate'
					? invoiceAPI.create({
							...this.order,
							syncToken: '0',
							isInQbo: true,
							status: 'Open',
							hasBeenModified: false,
							qboId: result.data.result.Id,
							UserId: this.order.UserId,
							CartId: this.order.CartId,
							DocNumber: result.data.result.DocNumber,
							EstimateId: this.order.id
					  })
					: this.updateLocalInvoice(result)
			]);
		} catch (e) {
			return Promise.reject(e);
		}
	};

	updateInvoice = async () => {
		try {
			const obj = this.orderObject();
			obj.sparse = true;
			obj.Id = this.order.qboId;
			obj.SyncToken = this.order.syncToken;

			const result = await qboAPI.updateInvoiceInQbo(obj);
			await Promise.all([
				qboAPI.sendConfirmation('invoice', result.data.result.Id, this.order.User.email),
				this.updateLocalInvoice(result)
			]);
		} catch (e) {
			return Promise.reject(e);
		}
	};

	updateLocalInvoice = invoice => {
		return invoiceAPI.update(this.order.id, {
			...this.order,
			isInQbo: true,
			hasBeenModified: false,
			qboId: invoice.data.result.Id,
			DocNumber: invoice.data.result.DocNumber,
			syncToken: invoice.data.result.SyncToken,
			status: 'Open'
		});
	};
}

export default QboRequest;
