import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Carousel, CarouselInner, CarouselItem, View, Row, Col, Card, Button } from 'mdbreact';
import styled from 'styled-components';

// import Contact from './Contact';

const carouselImages = [
	'https://s3-us-west-2.amazonaws.com/scottsdaleevents/carousel/Bistro1.jpg',
	'https://s3-us-west-2.amazonaws.com/scottsdaleevents/carousel/Bistro2.jpg',
	'https://s3-us-west-2.amazonaws.com/scottsdaleevents/carousel/Bistro3.jpg',
	'https://s3-us-west-2.amazonaws.com/scottsdaleevents/carousel/Papel+Picado.jpg',
];

const CardStyle = styled(Card)`
	max-width: 500px;
	max-height: 500px;

	> img {
		:hover {
			transform: scale(1.05) translate(0px, 0px);
		}
		transition: transform 300ms ease-in-out, box-shadow 300ms ease-in-out;
	}
`;

const ImgStyle = styled.img`
	max-height: 628px;
`;
const ImgLogo = styled.img`
	max-height: 300px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 50px;
	width: 40%;
`;

const GalleryBtn = styled(Button)`
	margin: 0 auto;
`;

const Home = () => {
	return (
		<Fragment>
			<section>
				<ImgLogo
					src="https://scottsdaleevents.s3-us-west-2.amazonaws.com/home/sed.png"
					alt="Logo"
				/>

				<Carousel
					activeItem={1}
					length={carouselImages.length}
					showControls={true}
					showIndicators={false}
					className="z-depth-1"
				>
					<CarouselInner>
						{carouselImages.length &&
							carouselImages.map((image, index) => (
								<CarouselItem key={index} itemId={index + 1}>
									<View>
										<ImgStyle className="d-block w-100" src={image} alt="First slide" />
									</View>
								</CarouselItem>
							))}
					</CarouselInner>
				</Carousel>
			</section>
			<section className="mt-4">
				<Card className="p-3">
					<h2 className="h1-responsive font-weight-bold text-center my-4">About us</h2>

					<p>
						Founder and owner of Scottsdale Event Decor and Scottsdale Music &amp; Entertainment,
						Kaine Stathakis is a veteran in the corporate event industry with over 20 years of
						experience. Having worked events at some of the finest resorts in the region, including
						The Phoenician, Royal Palms, Four Seasons Scottsdale, J.W. Marriott Desert Ridge, J.W.
						Marriott Camelback Inn, Ritz Carlton Phoenix, Westin Kierland, Hyatt Gainey Ranch, The
						Boulders, Fairmont Scottsdale Princess and Omni Montelucia in Arizona, The Lodge at
						Pebble Beach, Spanish Bay, Baccara, Hotel Del Coronado, Four Seasons Aviara, The Montage
						Laguna Beach, Marriott San Diego and St Regis Monarch Beach in California as well as
						Wynn, Bellagio, Caesars Palace and Mandalay Bay in Las Vegas, it&#39;s no question that
						Kaine has been such a prominent figure in Arizona&#39;s corporate event industry working
						with passion for providing the finest in products and service.
					</p>
					<p>
						On the other hand, founder and owner Cristina Stathakis recognized a need for luxury
						rentals in the corporate event market. Having been involved in over a thousand events
						since 2013, Cristina&#39;s eye for design craved something more from what was currently
						available in the marketplace. As a result, Scottsdale Event Decor came to life. Her love
						for beautiful events helped in the design of our collections.
					</p>
					<p>
						Nowadays, we have an extensive, creative and innovative product inventory designed to
						satisfy the expectations of our clients by creating the perfect atmosphere at your event
						and decorating the entire space with spectacular items. We are eager to meet you and to
						hear more about your upcoming event.
					</p>
				</Card>
			</section>
			<section className="text-center my-4">
				<h2 className="h1-responsive font-weight-bold text-center my-4">Our Decor</h2>
				<p className="grey-text text-center w-responsive mx-auto mb-5">
					At Scottsdale Event Decor we are proud to deliver premium quality rental items that are
					designed and manufactured locally in our warehouse.
				</p>
				<Row>
					<Col xs="12" lg="4" md="4" className="mb-lg-0 mb-4">
						<CardStyle collection className="z-depth-1-half mx-auto mx-md-3">
							<img
								src={'https://s3-us-west-2.amazonaws.com/scottsdaleevents/gallery_photos/13.JPG'}
								className="img-fluid"
								alt="decor item"
							/>
						</CardStyle>
					</Col>
					<Col xs="12" lg="4" md="4" className="mb-lg-0 mb-4">
						<CardStyle collection className="z-depth-1-half mx-auto mx-md-3">
							<img
								src={'https://s3-us-west-2.amazonaws.com/scottsdaleevents/gallery_photos/23.JPG'}
								className="img-fluid"
								alt="decor item"
							/>
						</CardStyle>
					</Col>
					<Col xs="12" lg="4" md="4" className="mb-lg-0 mb-4">
						<CardStyle collection className="z-depth-1-half mx-auto mx-md-3">
							<img
								src={'https://s3-us-west-2.amazonaws.com/scottsdaleevents/gallery_photos/26.JPG'}
								className="img-fluid"
								alt="decor item"
							/>
						</CardStyle>
					</Col>
					<Link to="/gallery" style={{ margin: '0 auto' }}>
						{' '}
						<GalleryBtn className="my-4">See more photos</GalleryBtn>
					</Link>
				</Row>
			</section>
			<section className="my-5">
				<h2 className="h1-responsive font-weight-bold text-center my-5">What sets us apart?</h2>
				<p className="lead grey-text w-responsive text-center mx-auto mb-5">
					Our collections have been handpicked and handcrafted with one specific goal in mind: to
					make your event look spectacular.
				</p>
				<Row>
					<Col md="4" className="md-0 mb-5">
						<h4 className="font-weight-bold text-center">Quality</h4>
						<p className="grey-text text-justify">
							As a boutique rental company, our commitment to quality is second to none. From
							seeking out the best materials, to painstakingly handcrafting our items, our furniture
							grade rentals are pieces that our clients even want for their own homes! We provide
							the quality deserving of display in the finest resorts in the area.
						</p>
					</Col>
					<Col md="4" className="md-0 mb-5">
						<h4 className="font-weight-bold text-center">The Industry Choice</h4>
						<p className="grey-text text-justify">
							It comes as no surprise that our commitment to service &amp; quality has made us the
							industry choice for many years running. Our clientele knows that when only the best
							will do, Scottsdale Event Décor will get the job done right!
						</p>
					</Col>
					<Col md="4" className="md-0 mb-5">
						<h4 className="font-weight-bold text-center">Service</h4>
						<p className="grey-text text-justify">
							From the initial email to the strike of the event, our team is committed to your
							satisfaction. Unlike other rental companies who just “drop off&quot; the order, our
							crew works with your onsite operations team to make sure everything gets to the right
							place and set in the right area. Bottom line, Scottsdale Event Décor gets the job done
							right.
						</p>
					</Col>
				</Row>
			</section>
			{/* <Contact /> */}
			<section>
				<Link to="/tos" className="text-center">
					<p>View our Privacy Policy & End User License Agreement</p>
				</Link>
			</section>
		</Fragment>
	);
};
export default Home;
