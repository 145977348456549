import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import OrderDetails from './OrderDetails';
import { Loading } from 'components';
import { setCart } from 'redux/actions';
import request from 'api/request';
import { upperCaseFirstLetter } from 'util/general';

class OrderDetailsContainer extends Component {
	static propTypes = {
		user: PropTypes.object,
		match: PropTypes.object,
		location: PropTypes.object,
		estimate: PropTypes.object
	};

	state = {
		order: {},
		orderType: undefined,
		cart: undefined,
		error: '',
		loading: true,
		notFound: false,
		warehouse: []
	};

	componentDidMount() {
		this.setState({ loading: true });
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.setState({ loading: true });
			this.fetchData();
		}
	}

	fetchData = async () => {
		try {
			const params = this.props.location.pathname.split('/');
			const [order, warehouse] = await Promise.all([
				request('get', `/${params[2]}/${params[3]}`),
				request('get', '/product?category=Warehouse')
			]);
			if (order.data.result.length < 1) {
				this.setState({ notFound: true, loading: false });
				return;
			}
			const cart = await request('get', `/cart/${order.data.result[0].CartId}/products`);
			this.setState({
				canBeModified:
					this.props.user.permissions.includes('admin') ||
					order.headers['can-be-modified'] === 'true',
				cart: cart.data.result[0],
				loading: false,
				order: order.data.result[0],
				orderType: upperCaseFirstLetter(params[2]),
				warehouse: warehouse.data.result
			});
		} catch (e) {
			this.setState({ error: 'An error occurred while fetching the order', loading: false });
		}
	};

	render() {
		const {
			loading,
			error,
			orderType,
			order,
			cart,
			notFound,
			canBeModified,
			warehouse
		} = this.state;
		return (
			<Loading loading={loading} error={error} notFound={notFound}>
				<OrderDetails
					{...this.props}
					order={order}
					orderType={orderType}
					cart={cart}
					fetchData={this.fetchData}
					canBeModified={canBeModified}
					warehouse={warehouse}
				/>
			</Loading>
		);
	}
}

const mapStateToProps = ({ userDetails: { user } }) => ({ user });

export default connect(
	mapStateToProps,
	{ setCart }
)(OrderDetailsContainer);
