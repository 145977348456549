import React from 'react';
import PropTypes from 'prop-types';
import { MDBCard, MDBIcon } from 'mdbreact';

import browserHistory from 'util/general';

const CartIcon = ({ user }) => {
	const total = user && user.cart ? user.cart.cartTotal : 0;
	if (!user) return null;
	if (user.permissions !== 'customer') return null;
	return (
		<div style={{ position: 'fixed', bottom: '3%', right: '9%', fontSize: '18px' }}>
			<MDBCard className="p-3">
				<span className="pointer" onClick={() => browserHistory.push('/checkout/cart')}>
					<MDBIcon icon="shopping-cart" />
					<span className="ml-2">Cart total: ${total}</span>
				</span>
			</MDBCard>
		</div>
	);
};

CartIcon.propTypes = {
	user: PropTypes.object
};

export default CartIcon;
