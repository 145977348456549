import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import PropTypes from 'prop-types';

import { BackButton, Alert, ExpiredTokenAlert } from 'components';
import { clone } from 'util/general';
import Dropdown from './Dropdown';
import Details from './Details';
import QboRequest from './QboRequest';
import CancelModal from './CancelModal';
import { Context } from './Context';
import CartDetails from './CartDetails';
import EventDetails from './EventDetails';

import {
	eventDetailsCol1,
	eventDetailsCol2,
	willCallDetails
} from 'pages/checkout/eventForm/formData';
import { formatEventData } from 'pages/checkout/summary/tableData';

const col1TableData = [
	'customerName',
	'eventDate',
	'groupName',
	'location',
	'room',
	'operationsManager'
];

const col2TableData = [
	'eventStartTime',
	'eventEndTime',
	'loadInTime',
	'setByTime',
	'strikeTime',
	'commentsOnEvent',
	'commentsOnSetup'
];

const willCallTableData = [
	'willCallCustomerName',
	'willCallPickupDate',
	'willCallPickupTime',
	'willCallReturnDate',
	'willCallReturnTime'
];

class OrderDetails extends Component {
	static propTypes = {
		user: PropTypes.object,
		cart: PropTypes.object,
		order: PropTypes.object,
		match: PropTypes.object,
		setCart: PropTypes.func,
		fetchData: PropTypes.func,
		history: PropTypes.object,
		orderType: PropTypes.string,
		canBeModified: PropTypes.bool,
		warehouse: PropTypes.array
	};

	static defaultProps = {
		user: { cart: {} },
		cart: { CartProducts: [] },
		order: {}
	};

	state = {
		cart: clone(this.props.cart),
		deletedProducts: [],
		error: '',
		editMode: false,
		expiredToken: false,
		hasBeenModified: false,
		loading: false,
		renewdSuccess: false,
		order: clone(this.props.order),
		showCancelModal: false,
		success: false
	};

	componentDidUpdate(prevProps) {
		if (prevProps.order !== this.props.order) {
			this.setState({
				order: this.props.order
			});
		}
	}

	showEventDetailsWillCall = () => {
		const { order } = this.state;
		for (const key in order) {
			if (key.includes('willCall') && order[key]) {
				return true;
			}
		}
		return false;
	};

	afterUpdate = async (newState = {}) => {
		await this.props.fetchData();
		this.setState({ loading: false, success: true, ...newState });
	};

	render() {
		const { error, order } = this.state;
		return (
			<Context.Provider
				value={{
					afterUpdate: this.afterUpdate,
					props: this.props,
					setState: state => this.setState(state),
					state: this.state,
					qbo: new QboRequest(
						this.props.cart,
						this.props.order,
						this.props.orderType,
						this.props.warehouse
					)
				}}
			>
				<BackButton />
				<div className="space-between">
					<Details />
					<Dropdown />
				</div>
				<ExpiredTokenAlert expiredToken={this.state.expiredToken} />
				<Alert
					color="danger"
					dismiss
					onDismiss={() => this.setState({ error: '', expiredToken: false, renewedSuccess: false })}
					show={error}
				>
					{error}
				</Alert>
				<MDBRow>
					<MDBCol lg="12">
						<CartDetails />
					</MDBCol>
				</MDBRow>
				<MDBRow className="mt-3">
					<MDBCol lg="12">
						<MDBCard>
							<MDBCardBody>
								<h4 className="text-center mb-3">Event Details</h4>
								<MDBRow>
									<MDBCol md="6">
										<EventDetails
											inputs={eventDetailsCol1(order)}
											tableData={formatEventData(order, col1TableData)}
										/>
									</MDBCol>
									<MDBCol md="6">
										<EventDetails
											inputs={eventDetailsCol2(order)}
											tableData={formatEventData(order, col2TableData)}
										/>
									</MDBCol>
								</MDBRow>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
				{order && this.showEventDetailsWillCall() && (
					<MDBRow className="mt-3">
						<MDBCol lg="12">
							<MDBCard>
								<MDBCardBody>
									<h4 className="text-center mb-2">Will Call Details</h4>
									<EventDetails
										inputs={willCallDetails(order)}
										tableData={formatEventData(order, willCallTableData)}
									/>
								</MDBCardBody>
							</MDBCard>
						</MDBCol>
					</MDBRow>
				)}
				<CancelModal />
			</Context.Provider>
		);
	}
}

export default OrderDetails;
