import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Alert extends Component {
	static propTypes = {
		show: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
		color: PropTypes.string.isRequired,
		dismiss: PropTypes.bool,
		onDismiss: PropTypes.any,
		className: PropTypes.string,
		children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool]).isRequired
	};

	static defaultProps = {
		dismiss: false,
		onDismiss: () => {},
		className: ''
	};

	onClose = () => {
		this.alertNode.style.opacity = '0';
		setTimeout(() => this.props.onDismiss(), 150);
	};

	render() {
		if (!this.props.show) {
			return null;
		}
		return (
			<div
				ref={ref => (this.alertNode = ref)}
				className={`alert alert-${this.props.color} ${this.props.className} ${this.props.dismiss &&
					'alert-dismissible fade show'} mt-3`}
			>
				{this.props.children}
				{this.props.dismiss && (
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={this.onClose}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				)}
			</div>
		);
	}
}

export default Alert;
