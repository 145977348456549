import React, { Component } from 'react';
import moment from 'moment';
import { MDBCollapse, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';

import { estimateAPI, emailAPI, cartAPI } from 'api';
import { BackButton, CalculateTotal, Table } from 'components';
import { productData, eventData } from './tableData';
import { formatColumnName } from 'util/general';
import SubmitModal from './SubmitModal';

class Summary extends Component {
	static propTypes = {
		hasState: PropTypes.bool,
		user: PropTypes.object,
		location: PropTypes.object,
		eventDetails: PropTypes.object,
		setCart: PropTypes.func,
		products: PropTypes.array,
		history: PropTypes.object,
	};

	static defaultProps = {
		products: [],
		eventDetails: {
			commentsOnEvent: '',
			commentsOnSetup: '',
			customerName: '',
			eventDate: '',
			eventEndTime: '',
			eventStartTime: '',
			groupName: '',
			loadInTime: '',
			location: '',
			operationsManager: '',
			room: '',
			setByTime: '',
			strikeTime: '',
			willCall: {
				willCallCustomerName: '',
				willCallPickupDate: '',
				willCallPickupTime: '',
				willCallReturnDate: '',
				willCallReturnTime: '',
			},
		},
	};

	state = {
		newEstimateId: '',
		eventDetails: this.props.eventDetails && this.props.eventDetails,
		collapse: false,
		checkedTerms: false,
		loading: false,
		success: '',
		error: '',
	};

	componentDidMount() {
		if (!this.props.hasState) {
			this.props.history.push('/');
		}
	}

	formatEventDetails = (start, end) => {
		const { eventDetails } = this.state;
		const keys = Object.keys(eventDetails).slice(start, end);
		return keys.map((key) => {
			let eventDetail = eventDetails[key];
			if (key.includes('Date') && eventDetail) {
				eventDetail = moment(eventDetail).format('MMMM Do YYYY');
			}
			if (key.includes('Time') && eventDetail) {
				eventDetail = moment(eventDetail, 'HH:mm').format('h:mm A');
			}
			return {
				'Event Details': formatColumnName(key),
				'Your Event': eventDetail,
			};
		});
	};

	eventDetailsWillCall = () => {
		const { willCall } = this.state.eventDetails;
		const keys = Object.keys(willCall);
		return keys.map((key) => ({
			'Event Details': formatColumnName(key),
			'Your Event': willCall[key],
		}));
	};

	showEventDetailsWillCall = () => {
		let totalFilledIn = 0;
		Object.values(this.state.eventDetails.willCall).forEach((value) => {
			// eslint-disable-next-line
			if (value != false) {
				totalFilledIn++;
			}
		});
		if (totalFilledIn >= 2) return true;
		return false;
	};

	toggleCollapse = () => this.setState({ collapse: !this.state.collapse });

	handleCheck = () => this.setState({ checkedTerms: !this.state.checkedTerms });

	sumTotal = () => {
		const total = this.props.products.reduce(
			(total, currentValue) => (total += Number(currentValue.total)),
			0
		);
		return total;
	};

	onSubmit = () => {
		this.setState({ loading: true });
		const CartId = this.props.user.cart.id;
		const estimate = {
			CartId,
			status: 'Pending',
			...this.state.eventDetails,
			...this.state.eventDetails.willCall,
		};
		Promise.all([
			estimateAPI.create(estimate),
			cartAPI.updateCart(CartId, { isActive: false, didCheckOut: true }),
			cartAPI.createCart({ isActive: true }),
			emailAPI.sendNewEstimateEmail(estimate),
		])
			.then((res) => {
				this.props.setCart(res[2].data.result);
				this.setState({
					success: true,
					loading: false,
					error: '',
					newEstimateId: res[0].data.result.id,
				});
			})
			.catch(() => this.setState({ error: 'an error occurred', loading: false, success: '' }));
	};

	render() {
		const total = this.sumTotal();
		const { eventDetails, success, error, collapse, checkedTerms, loading } = this.state;
		const { products, history } = this.props;
		return (
			<>
				<BackButton to="/checkout/event" state={{ products, eventDetails }} />
				<MDBRow>
					<MDBCol lg="12">
						<MDBCard>
							<MDBCardBody>
								<h4 className="text-center mb-2">Summary</h4>
								<Table data={productData(products)} />
								<CalculateTotal total={total} />
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
				<MDBRow className="mt-3">
					<MDBCol lg="12">
						<MDBCard>
							<MDBCardBody>
								<h4 className="text-center mb-2">Event Details</h4>
								<MDBRow>
									<MDBCol md="6">
										<Table data={eventData(this.formatEventDetails(0, 6))} />
									</MDBCol>
									<MDBCol md="6">
										<Table data={eventData(this.formatEventDetails(6, 13))} />
									</MDBCol>
								</MDBRow>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
				{eventDetails && this.showEventDetailsWillCall() && (
					<MDBRow className="mt-3">
						<MDBCol lg="12">
							<MDBCard>
								<MDBCardBody>
									<Table data={eventData(this.eventDetailsWillCall())} />
								</MDBCardBody>
							</MDBCard>
						</MDBCol>
					</MDBRow>
				)}
				<div className="mt-3 text-center">
					<div className="my-3">
						<MDBBtn
							size="md"
							style={{ margin: '0 auto' }}
							outline
							color="warning"
							onClick={this.toggleCollapse}
						>
							Terms & Conditions
						</MDBBtn>
						<MDBCollapse isOpen={collapse}>
							<p>
								In the event of damage to the equipment, client agrees to pay any & all reasonable
								cost to return equipment to its original condition.
							</p>
							<p>
								Overtime fee: $35.00 per staff per hour (or any part thereof) will be charged in
								addition to client if set and/or strike can not take place at time indicated on
								contract. If a strike will occur at or after 11:00pm, additional labor costs will
								apply. Additionally, a $100.00 minimum service charge will be assessed on any orders
								of $600.00 or less (Not including taxes and trucking).
							</p>
							<p>
								This order is only a request and will be reviewed by Scottsdale Event Décor. You
								will be notified of the approval, denial, or necessary changes of service as soon as
								possible.
							</p>
							<p>
								Special Orders will require a 50% Non-Refundable upfront payment. This payment will
								be due within one week of receiving a signed contract.
							</p>
							<p>
								Trucking and Labor costs are only estimated, as larger orders may require multiple
								trucks and the additional labor costs. If you have any questions please feel free to
								contact us through phone or email.
							</p>
						</MDBCollapse>
						<div className="custom-control custom-checkbox animated jello my-2">
							<input
								type="checkbox"
								className="custom-control-input"
								id="validateTerms"
								onChange={this.handleCheck}
								required
							/>
							<label className="custom-control-label" htmlFor="validateTerms">
								Agree To Terms and Conditions
							</label>
							<div className="invalid-feedback">You must agree before submitting.</div>
						</div>
					</div>
					<div>
						<SubmitModal
							newEstimateId={this.state.newEstimateId}
							checkedTerms={checkedTerms}
							onSubmit={this.onSubmit}
							loading={loading}
							success={success}
							error={error}
							history={history}
						/>
					</div>
				</div>
			</>
		);
	}
}

export default Summary;
