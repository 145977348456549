import React, { Component } from 'react';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import PropTypes from 'prop-types';

import { productAPI } from 'api';
import { Alert, InventorySelect, SelectBox } from 'components';

class QuickAdd extends Component {
	static propTypes = {
		user: PropTypes.object,
		onSubmit: PropTypes.func
	};

	state = {
		selectedCategory: '',
		categories: [],
		error: '',
		products: [],
		selectedProduct: '',
		selectedQuantity: '1'
	};

	componentDidMount() {
		productAPI
			.fetchCategories()
			.then(res => {
				this.setState({
					categories: res.data.result
				});
			})
			.catch(() => this.setState({ error: 'Failed to load products for quick add' }));
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.selectedCategory !== this.state.selectedCategory) {
			productAPI
				.fetch(undefined, { category: this.state.selectedCategory, gt: { quantity: { $gt: 0 } } })
				.then(res => {
					this.setState({
						products: [
							{ option: 'Select a product', value: '' },
							...res.data.result.map(x => {
								x.value = x.id;
								x.option = x.name;
								return x;
							})
						]
					});
				})
				.catch(() => this.setState({ error: 'An error occurred while fetching the products' }));
		}
	}

	formatOptions = values => {
		const categories = Object.keys(values).reduce((acc, value) => {
			const obj = {};
			obj.option = value;
			obj.value = value;
			acc.push(obj);
			return acc;
		}, []);
		categories.unshift({ option: 'Select a category', value: '' });
		return categories;
	};

	render() {
		const {
			error,
			selectedProduct,
			selectedCategory,
			categories,
			products,
			selectedQuantity
		} = this.state;

		return (
			<>
				<h5>Quick Add</h5>
				<Alert show={error} color="danger">
					{error}
				</Alert>
				<MDBRow className="mb-2">
					<MDBCol md="3">
						<SelectBox
							className="d-block mb-2"
							options={this.formatOptions(categories)}
							onChange={e => {
								this.setState({
									selectedCategory: e.target.value,
									selectedProduct: '',
									selectedQuantity: '1'
								});
							}}
						/>
						{selectedCategory && (
							<SelectBox
								className="d-block"
								options={products}
								onChange={e => {
									this.setState({
										selectedProduct: products.find(x => x.id === e.target.value)
									});
								}}
							/>
						)}
					</MDBCol>
					<MDBCol md="3">
						{selectedProduct && (
							<>
								<InventorySelect
									style={{ display: 'block' }}
									startAt={1}
									name="quantity"
									label="Quantity"
									quantity={selectedProduct.quantity}
									onChange={e => {
										this.setState({ selectedQuantity: e.target.value });
									}}
									value={selectedQuantity}
								/>
								<MDBBtn
									className="ml-0"
									size="sm"
									onClick={() => {
										this.props.onSubmit(selectedProduct, selectedQuantity);
									}}
								>
									Add
								</MDBBtn>
							</>
						)}
					</MDBCol>
				</MDBRow>
			</>
		);
	}
}

export default QuickAdd;
