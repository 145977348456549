import { MDBInput } from 'mdbreact';

import { FileUpload, SelectBox } from 'components';
import { selectFileNames } from '../helpers';

export const productForm = (values, ref, categories) => {
	return [
		{
			inputType: MDBInput,
			value: values.name,
			id: 'name',
			name: 'name',
			label: 'Name',
			group: true,
			required: true,
			type: 'text'
		},
		{
			inputType: MDBInput,
			value: values.id,
			id: 'id',
			name: 'id',
			label: 'Sku',
			group: true,
			required: true,
			type: 'text'
		},
		{
			className: 'mb-3',
			inputType: SelectBox,
			id: 'category',
			name: 'category',
			required: true,
			label: 'Category',
			options: [
				{
					option: 'Choose a category',
					value: ''
				},
				...categories.categories.map(category => ({
					key: category.Id,
					option: category.Name,
					value: `${category.Id}__${category.Name}`
				}))
			]
		},
		{
			className: 'mb-3',
			inputType: SelectBox,
			id: 'subcategory',
			name: 'subcategory',
			required: true,
			label: 'Sub category',
			options: [
				{
					option: 'Choose a sub category',
					value: ''
				},
				...categories.subcategories
					.filter(category => category.ParentRef.value === values.category.split('__')[0])
					.map(sub => {
						return {
							key: sub.Id,
							option: sub.Name,
							value: `${sub.Id}__${sub.Name}`
						};
					})
			]
		},
		{
			inputType: MDBInput,
			value: values.price,
			id: 'price',
			name: 'price',
			label: 'Price',
			group: true,
			required: true,
			type: 'number'
		},
		{
			inputType: MDBInput,
			value: values.quantity,
			id: 'quantity',
			name: 'quantity',
			label: 'Quantity',
			group: true,
			required: true,
			type: 'number'
		},
		{
			inputType: MDBInput,
			value: values.description,
			id: 'description',
			name: 'description',
			label: 'Description',
			group: true,
			required: true,
			type: 'textarea',
			rows: '3'
		},
		{
			inputType: FileUpload,
			id: 'imagesToUpload',
			name: 'imagesToUpload',
			label: selectFileNames(values.imagesToUpload),
			multiple: true,
			ref
		}
	];
};

export const categoryForm = (values, categories) => {
	const inputs = [
		{
			inputType: MDBInput,
			id: 'name',
			name: 'name',
			label: 'Name',
			required: true,
			value: values.name
		},
		{
			inputType: MDBInput,
			filled: true,
			checked: values.isSubCategory,
			id: 'isSubCategory',
			name: 'isSubCategory',
			label: 'Is sub-category?',
			type: 'checkbox'
		}
	];
	if (values.isSubCategory) {
		inputs.push({
			inputType: SelectBox,
			id: 'category',
			name: 'category',
			label: 'Category',
			required: true,
			options: [
				{
					option: 'Choose a category',
					value: ''
				},
				...categories.categories.map(category => ({
					key: category.Id,
					option: category.Name,
					value: `${category.Id}`
				}))
			]
		});
	}
	return inputs;
};
