import React from 'react';
import { connect } from 'react-redux';

import EventFormView from './EventFormView';

const EventFormContainer = props => <EventFormView {...props} />;

const mapStateToProps = ({ userDetails: { user } }, ownProps) => {
	const products = ownProps.location.state.products;
	const eventDetails = ownProps.location.state.eventDetails;
	return {
		user,
		products,
		eventDetails
	};
};

export default connect(
	mapStateToProps,
	null
)(EventFormContainer);
