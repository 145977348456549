import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MDBCard, MDBCardBody } from 'mdbreact';
import { selectResponse } from 'util/general';
import { estimateAPI, invoiceAPI } from 'api';
import { Table, Loading, InfiniteScroll, SearchBar } from 'components';
import { tableData } from './tableData';

const orderTypes = {
	estimate: estimateAPI,
	invoice: invoiceAPI
};

class OrdersTable extends Component {
	static propTypes = {
		orderType: PropTypes.string,
		user: PropTypes.object,
		params: PropTypes.object
	};

	static defaultProps = {
		params: {}
	};

	state = {
		params: {},
		loading: false,
		error: '',
		orders: [],
		totalCount: ''
	};

	componentDidMount() {
		this.setState({ loading: true });
		this.fetchOrders(this.props.params);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.params !== this.props.params) {
			this.fetchOrders(this.props.params);
		}
	}

	fetchOrders = params => {
		orderTypes[this.props.orderType]
			.get(undefined, {
				...params,
				order: ['createdAt', 'DESC'],
				limit: 20,
				offset: 0
			})
			.then(res => {
				this.setState({
					orders: selectResponse(res),
					totalCount: res.headers.total_count,
					loading: false,
					error: ''
				});
			})
			.catch(() =>
				this.setState({ error: 'An error occurred while retrieving your orders', loading: false })
			);
	};

	onScroll = params => {
		return orderTypes[this.props.orderType].get(undefined, {
			...params,
			order: ['createdAt', 'DESC']
		});
	};

	onSearchChange = e => {
		const params = e.target.value ? { like: { DocNumber: { $like: `${e.target.value}%` } } } : {};
		this.fetchOrders(params);
	};

	render() {
		const { orderType } = this.props;
		const { loading, error, orders, totalCount } = this.state;
		return (
			<MDBCard>
				<MDBCardBody>
					<SearchBar
						className="ml-auto"
						onChange={this.onSearchChange}
						searchPlaceholder={`${orderType} ID`}
					/>
					<Loading loading={loading} error={error} notFound={orders.length < 1} notFoundSize="2x">
						<InfiniteScroll
							totalCount={totalCount}
							onScroll={this.onScroll}
							initialData={orders}
							onError="An error occurred while retrieving your orders"
						>
							{pagedData => <Table data={tableData(pagedData, orderType)} />}
						</InfiniteScroll>
					</Loading>
				</MDBCardBody>
			</MDBCard>
		);
	}
}

export default OrdersTable;
