import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { companyAPI } from 'api';
import { Alert, Table, Loading, InfiniteScroll } from 'components';
import { companyTableData } from './tableData';

class UserTable extends Component {
	static propTypes = {
		clear: PropTypes.func.isRequired,
		newUserPermissions: PropTypes.string.isRequired,
		permissions: PropTypes.string.isRequired,
		params: PropTypes.object
	};

	state = {
		loading: false,
		error: '',
		companies: []
	};

	componentDidMount() {
		this.setState({ loading: true });
		this.fetchCompanies();
	}

	componentDidUpdate(prevProps) {
		if (this.props.params !== prevProps.params) {
			this.fetchCompanies(this.props.params);
		}
		if (this.props.newUserPermissions === this.props.permissions) {
			this.props.clear();
			this.fetchCompanies(this.props.params);
		}
	}

	fetchCompanies = (params = {}) => {
		companyAPI
			.getCompany(undefined, {
				...params,
				limit: 20,
				offset: 0,
				order: ['displayName', 'ASC']
			})
			.then(res => {
				this.setState({
					companies: res.data.result,
					totalCount: res.headers.total_count,
					loading: false,
					error: ''
				});
			})
			.catch(() =>
				this.setState({ error: 'An error occurred while retrieving the companies', loading: false })
			);
	};

	onScroll = params => {
		return companyAPI.getCompany(undefined, {
			...params,
			order: ['displayName', 'ASC']
		});
	};

	render() {
		const { loading, error, companies, totalCount } = this.state;
		return (
			<Loading loading={loading} notFound={companies.length < 1} error={error} notFoundSize="2x">
				<Alert dismiss color="danger" show={error} onDismiss={() => this.setState({ error: '' })}>
					{error}
				</Alert>
				<InfiniteScroll
					totalCount={totalCount}
					onScroll={this.onScroll}
					initialData={companies}
					onError="An error occurred while retrieving the companies"
				>
					{pagedData => <Table data={companyTableData(pagedData)} />}
				</InfiniteScroll>
			</Loading>
		);
	}
}

export default UserTable;
