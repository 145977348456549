import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSelect = styled.select`
	width: 35%;
	@media (min-width: 768px) {
		width: 25%;
	}
`;

class SelectOrder extends Component {
	static propTypes = {
		onSelect: PropTypes.func.isRequired,
		hideQboSelect: PropTypes.bool
	};

	static defaultProps = {
		hideQboSelect: false
	};

	state = {
		params: {}
	};

	params = {
		all: {},
		pending: { status: 'pending' },
		closed: { status: 'closed' },
		open: { status: 'open' },
		completed: { status: 'completed' },
		voided: { status: 'voided' },
		hasBeenModified: { hasBeenModified: true }
	};

	onSelect = e => {
		this.props.onSelect(this.params[e.target.value]);
	};

	render() {
		return (
			<StyledSelect
				onChange={this.onSelect}
				className="browser-default custom-select"
				name="status"
			>
				<option defaultValue value="all">
					All
				</option>
				<option value="pending">Pending</option>
				<option value="open">Open</option>
				<option value="completed">Completed</option>
				<option value="hasBeenModified">Modified</option>
				<option value="closed">Closed</option>
				<option value="voided">Voided</option>
			</StyledSelect>
		);
	}
}

export default SelectOrder;
