import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Loading } from 'components';
import UserDetails from './UserDetails';
import { companyAPI, userAPI } from 'api';

class UserDetailsContainer extends Component {
	static propTypes = {
		user: PropTypes.object,
		match: PropTypes.object
	};

	state = {
		user: null,
		error: '',
		loading: true,
		parentCompany: null
	};

	componentDidMount() {
		let user;
		userAPI
			.get(this.props.match.params.id)
			.then(res => {
				user = res.data.result[0];
				return companyAPI.getCompany(res.data.result[0].CompanyId);
			})
			.then(res => {
				this.setState({ user, loading: false, error: '', parentCompany: res.data.result[0] });
			})
			.catch(() =>
				this.setState({
					loading: false,
					error: 'An error occurred while retrieving the user',
					user: null
				})
			);
	}

	render() {
		const { user, error, loading, parentCompany } = this.state;
		return (
			<Loading loading={loading} error={error}>
				<UserDetails {...this.props} user={user} parentCompany={parentCompany} />
			</Loading>
		);
	}
}

export default UserDetailsContainer;
