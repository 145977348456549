import React, { Component, Fragment } from 'react';
import { MDBModalBody, MDBIcon, MDBBtn, MDBModal, MDBModalHeader } from 'mdbreact';
import PropTypes from 'prop-types';

import { selectErrMsg, checkExpiredSession } from 'util/general';
import SelectUserType from './SelectUserType';
import qboObject from './qboObject';
import { companyForm, subCustomerForm, adminForm } from './formData';
import { Form, Alert, ModalSuccess, ExpiredTokenAlert, StyledModal } from 'components';
import { companyAPI, userAPI, qboAPI } from 'api';

class CreateUserModal extends Component {
	static propTypes = {
		setNewUserPermissions: PropTypes.func.isRequired
	};

	initialState = {
		loading: false,
		companies: [],
		companiesLoading: false,
		modal: false,
		success: false,
		error: '',
		expiredToken: false,
		showForm: false,
		formType: '',
		company: {
			company: '',
			displayName: '',
			zipCode: '',
			street: '',
			city: '',
			state: '',
			phone: ''
		},
		customer: {
			permissions: 'customer',
			CompanyId: '',
			company: '',
			displayName: '',
			email: '',
			firstName: '',
			lastName: ''
		},
		admin: {
			permissions: 'admin',
			email: '',
			firstName: '',
			lastName: '',
			phone: ''
		}
	};

	state = {
		...this.initialState
	};

	toggle = () => {
		this.setState({
			...this.initialState,
			modal: !this.state.modal
		});
	};

	getCompanies = () => {
		this.setState({ companiesLoading: true });
		companyAPI
			.getCompany(undefined, { attributes: ['qboId', 'name', 'id', 'displayName'] })
			.then(res => this.setState({ companies: res.data.result, companiesLoading: false }))
			.catch(() =>
				this.setState({
					error: 'An error occurred while loading your customers',
					companiesLoading: false
				})
			);
	};

	createCustomer = () => {
		const { customer } = this.state;
		const qboData = qboObject(customer);
		const [qboCompanyId, CompanyId, displayName] = customer.CompanyId.split(' ');
		qboData.Job = true;
		qboData.ParentRef = {
			value: qboCompanyId,
			name: displayName
		};

		qboAPI
			.qboCreateUser(qboData)
			.then(res => {
				const data = {
					...customer,
					CompanyId,
					ownCompany: customer.company,
					qboId: res.data.result.Id
				};
				return userAPI.create(data);
			})
			.then(() => {
				this.setState({ loading: false, error: '', success: true });
				this.props.setNewUserPermissions('customer');
			})
			.catch(e => {
				const expiredToken = checkExpiredSession(e);
				this.setState({
					expiredToken,
					loading: false,
					success: false,
					error: !expiredToken && selectErrMsg(e)
				});
			});
	};

	createCompany = () => {
		const { company } = this.state;
		company.displayName = company.company;
		const qboData = qboObject(company);
		qboAPI
			.qboCreateUser(qboData)
			.then(res => {
				const data = {
					...company,
					name: company.company,
					qboId: res.data.result.Id
				};
				return companyAPI.createCompany(data);
			})
			.then(() => {
				this.props.setNewUserPermissions('company');
				this.setState({ loading: false, error: '', success: true });
			})
			.catch(e => {
				const expiredToken = checkExpiredSession(e);
				this.setState({
					expiredToken,
					loading: false,
					success: false,
					error: !expiredToken && selectErrMsg(e)
				});
			});
	};

	createAdmin = () => {
		const { admin } = this.state;
		userAPI
			.create(admin)
			.then(() => {
				this.setState({ loading: false, error: '', success: true });
				this.props.setNewUserPermissions('admin');
			})
			.catch(e =>
				this.setState({
					loading: false,
					success: false,
					error: selectErrMsg(e)
				})
			);
	};

	onSubmit = userType => () => {
		const action =
			userType === 'customer'
				? this.createCustomer
				: userType === 'admin'
				? this.createAdmin
				: this.createCompany;

		this.setState({ loading: true, error: '', success: false, expiredToken: false });
		action();
	};

	onChange = userType => e => {
		this.setState({
			...this.state,
			[userType]: {
				...this.state[userType],
				[e.target.name]: e.target.value
			}
		});
	};

	setFormType = formType => {
		this.setState(
			{ showForm: true, formType },
			() => formType === 'customer' && this.getCompanies()
		);
	};

	showForm = formType => {
		const { company, customer, admin, loading, companies } = this.state;
		const inputs =
			formType === 'customer'
				? subCustomerForm(customer, companies)
				: formType === 'admin'
				? adminForm(admin)
				: companyForm(company);

		return (
			<Form
				columns={2}
				onChange={this.onChange(formType)}
				onSubmit={this.onSubmit(formType)}
				loading={loading}
				inputs={inputs}
			/>
		);
	};

	render() {
		const { modal, success, error, expiredToken, formType, companiesLoading } = this.state;
		return (
			<>
				<MDBBtn style={{ cursor: 'pointer' }} onClick={this.toggle}>
					<MDBIcon icon="plus" />
					{'  '}
					Create User
				</MDBBtn>
				<StyledModal>
					<MDBModal toggle={this.toggle} isOpen={modal} size="lg">
						<MDBModalHeader toggle={this.toggle}>Create new {formType}</MDBModalHeader>
						<MDBModalBody>
							<ModalSuccess success={success} message="A new user has been added!">
								<Fragment>
									{!formType && <SelectUserType setFormType={this.setFormType} />}
									{companiesLoading && (
										<div className="text-center">
											<MDBIcon icon="spinner" spin />
										</div>
									)}
									{!companiesLoading && formType && this.showForm(formType)}
									<ExpiredTokenAlert expiredToken={expiredToken} />
									<Alert show={!companiesLoading && error} color="danger">
										{error}
									</Alert>
								</Fragment>
							</ModalSuccess>
						</MDBModalBody>
					</MDBModal>
				</StyledModal>
			</>
		);
	}
}

export default CreateUserModal;
