import Methods from './Methods';

class UserAPI extends Methods {
	get(id = '', params) {
		return super.get(`/user/${id}`, params);
	}

	create(data) {
		return super.post('/user', data);
	}

	update(id, data) {
		return super.put(`/user/${id}`, data);
	}

	delete(id) {
		return super.delete(`/user/${id}`);
	}
}

const userAPI = new UserAPI();

export default userAPI;
