import React, { Component } from 'react';
import { MDBBtn, MDBContainer, MDBRow, MDBIcon, MDBCol } from 'mdbreact';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { selectFileNames, formatImagesForDb } from '../helpers';
import { uploadImagesToAWS } from '../requests';
import { Alert, FileUpload } from 'components';
import { productAPI } from 'api';

const StyledImg = styled.img`
	width: 100% !important;
	max-width: 200px !important;
	-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
`;

const SetPrimaryImg = styled.span`
	&:hover {
		text-decoration: underline;
	}
`;

class EditImages extends Component {
	static propTypes = {
		id: PropTypes.string,
		product: PropTypes.object,
	};

	state = {
		error: '',
		confirmDeleteImgId: '',
		images: [],
		loading: false,
		updatedImgId: '',
		imagesToUpload: [],
		submitLoading: false,
		imageUpdateLoading: false,
	};

	componentDidMount() {
		this.setState({ loading: true });
		this.getImages();
	}

	onImageSubmit = async () => {
		try {
			this.setState({ submitLoading: true });
			const { product } = this.props;
			const { imagesToUpload } = this.state;
			const urls = await uploadImagesToAWS(imagesToUpload, product);
			const imagesToSave = formatImagesForDb(urls).map((x) => x.url);
			await productAPI.saveImg(product.id, imagesToSave);
			await this.getImages();
			this.setState({ submitLoading: false });
		} catch (e) {
			this.setState({ submitLoading: false, error: 'An error occurred while uploading the image' });
		}
	};

	setPlurality = (totalImages) => {
		return totalImages === 1 ? 'image' : 'images';
	};

	imageOnChange = (e) => {
		const files = Array.from(e.target.files);
		const maxImages = 5 - this.state.images.length;

		if (files.length > maxImages) {
			this.setState({
				error: `You attempted to upload ${files.length} ${this.setPlurality(
					files.length
				)} and only ${maxImages} more ${this.setPlurality(maxImages)}  can be uploaded.`,
			});
		} else {
			this.setState({
				error: '',
				imagesToUpload: files,
			});
		}
	};

	getImages = async () => {
		try {
			const res = await productAPI.fetchImages(this.props.product.id);
			this.setState({ images: res.data.result, loading: false });
		} catch (e) {
			this.setState({ error: 'An error occurred loading the product images', loading: false });
		}
	};

	updateImg = (imageId) => async () => {
		this.setState({ imageUpdateLoading: true, updatedImgId: imageId });
		try {
			await productAPI.updateImg(this.props.product.id, imageId, { primary: true });
			await this.getImages();
			this.setState({ imageUpdateLoading: false });
		} catch (e) {
			this.setState({
				error: 'An error occurred while updating the image',
				imageUpdateLoading: false,
			});
		}
	};

	deleteImg = (imgId) => async () => {
		this.setState({ imageUpdateLoading: true, updatedImgId: imgId });
		try {
			await productAPI.deleteSingleImage(this.props.product.id, imgId);
			if (this.state.images.length === 1) {
				await productAPI.saveImg(this.props.product.id, [
					'https://dummyimage.com/350x350/20232a/bababa.png&text=Product+Image+Coming+Soon',
				]);
			}
			await this.getImages();
			this.setState({ imageUpdateLoading: false });
		} catch (e) {
			this.setState({ error: 'An error occurred deleting the image', loading: false });
		}
	};

	onDismiss = () => this.setState({ error: '' });

	render() {
		const {
			images,
			submitLoading,
			loading,
			error,
			imageUpdateLoading,
			updatedImgId,
			imagesToUpload,
		} = this.state;
		const totalImgs = 5 - images.length;
		if (loading) {
			return (
				<div className="text-center">
					<MDBIcon icon="spinner" spin style={{ color: '#4b515d' }} />
				</div>
			);
		}
		return (
			<MDBContainer>
				{this.state.images.map((img) => (
					<MDBRow key={img.id}>
						<MDBCol lg="3">
							<div className="mb-2">
								<StyledImg className="img-fluid img-thumbnail" src={img.url} alt="Products" />
							</div>
						</MDBCol>
						<MDBCol lg="9">
							{img.primary ? (
								<div>
									<span>
										<MDBIcon className="text-success" icon="check" />
										{'  '}
										Primary
									</span>
								</div>
							) : (
								<>
									<SetPrimaryImg onClick={this.updateImg(img.id)} className="pointer">
										Set primary
									</SetPrimaryImg>
									{imageUpdateLoading && updatedImgId === img.id && <MDBIcon icon="spinner" spin />}
								</>
							)}
							<>
								<div
									className="pointer "
									onClick={() => this.setState({ confirmDeleteImgId: img.id })}
								>
									<span>
										<MDBIcon className="text-danger" icon="trash-alt" />
										{'  '}
										Delete
									</span>
								</div>
								{this.state.confirmDeleteImgId === img.id && (
									<>
										<span>Are you sure?</span>
										<span onClick={this.deleteImg(img.id)} className="ml-1 text-danger pointer">
											Yes
										</span>
										<span
											onClick={() => this.setState({ confirmDeleteImgId: '' })}
											className="ml-1 text-primary pointer"
										>
											No
										</span>
									</>
								)}
							</>
						</MDBCol>
					</MDBRow>
				))}
				<div className="my-2 w-50">
					{totalImgs > 0 ? (
						<label>
							A total of 5 images can be uploaded for each product. You may upload {totalImgs} more{' '}
							{this.setPlurality(totalImgs)}
						</label>
					) : (
						<label>
							You have reached the max number of images for this product. To add a new image, please
							remove an existing image.
						</label>
					)}
					<FileUpload
						multiple
						onChange={this.imageOnChange}
						label={selectFileNames(imagesToUpload)}
						id="imagesToUpload"
						name="imagesToUpload"
					/>
					{imagesToUpload.length > 0 && (
						<MDBBtn disabled={submitLoading} onClick={this.onImageSubmit} size="md">
							{submitLoading ? <MDBIcon icon="spinner" spin /> : 'Submit'}
						</MDBBtn>
					)}
				</div>
				<Alert show={error} color="danger" dismiss onDismiss={this.onDismiss}>
					{error}
				</Alert>
			</MDBContainer>
		);
	}
}

export default EditImages;
