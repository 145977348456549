import React from 'react';
import { ContentMessage } from 'components';
import PropTypes from 'prop-types';

const ErrorMsg = ({ message }) => (
	<ContentMessage message={message} icon="exclamation-triangle" size="3x" className="red-text" />
);

ErrorMsg.propTypes = {
	message: PropTypes.string
};

export default ErrorMsg;
