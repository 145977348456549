import React, { Component } from 'react';
import { MDBModalBody, MDBModal, MDBModalHeader } from 'mdbreact';
import PropTypes from 'prop-types';

import { ExpiredTokenAlert, ModalSuccess } from 'components';
import SelectEditOptions from './SelectEditOptions';
import EditForm from './EditForm';
import DeleteModal from './DeleteModal';
import EditImages from './EditImages';

class EditProductModal extends Component {
	static propTypes = {
		onClose: PropTypes.func,
		product: PropTypes.object,
		showModal: PropTypes.bool,
		deleteModal: PropTypes.bool,
		permissions: PropTypes.string,
	};

	initialState = {
		success: false,
		wasModified: false,
		expiredToken: false,
		formType: '',
		showModal: false,
	};

	state = { ...this.initialState };

	onClose = () => {
		this.props.onClose();
		this.setState(this.initialState);
	};

	setFormType = (formType) => {
		this.setState({ formType });
	};

	selectOption = (formType) => this.setState({ formType });

	setExpiredToken = (expiredToken) => this.setState({ expiredToken });

	setTitle = () => {
		return this.props.deleteModal ? 'Delete' : 'Edit';
	};

	onSuccess = () => this.setState({ success: true });

	editOptions = (formType, product, permissions) => {
		if (this.props.deleteModal) {
			return <DeleteModal product={product} closeModal={this.onClose} />;
		}
		if (!formType) {
			return <SelectEditOptions selectOption={this.selectOption} />;
		}
		if (formType === 'productDetails') {
			return (
				<EditForm
					product={product}
					onSuccess={this.onSuccess}
					setExpiredToken={this.setExpiredToken}
				/>
			);
		}
		if (formType === 'images') {
			return <EditImages product={product} />;
		}
	};

	render() {
		const { showModal, product, permissions } = this.props;
		const { expiredToken, success, formType } = this.state;
		return (
			<MDBModal toggle={this.onClose} isOpen={showModal} size="lg">
				<MDBModalHeader toggle={this.onClose}>
					<b>{this.setTitle()}</b> {this.props.product.name}
				</MDBModalHeader>
				<MDBModalBody>
					<ModalSuccess success={success} message="Your product has been updated!">
						{this.editOptions(formType, product, permissions)}
						<ExpiredTokenAlert expiredToken={expiredToken} />
					</ModalSuccess>
				</MDBModalBody>
			</MDBModal>
		);
	}
}

export default EditProductModal;
