import React from 'react';
import { MDBIcon } from 'mdbreact';
import PropTypes from 'prop-types';

import { ErrorMsg } from 'components';

/**
 * @description Fetches data for infinite loading. Takes a function as its child component
 * Maintains state and passes cumulative fetched data to child component
 * onScroll function should not be bound to the state of another component since this maintains state
 * and passes it to child
 */

class InfiniteScroll extends React.Component {
	static propTypes = {
		onScroll: PropTypes.func,
		totalCount: PropTypes.string,
		children: PropTypes.func,
		initialData: PropTypes.array,
		onError: PropTypes.string
	};

	state = {
		page: 1,
		loading: false,
		result: [],
		error: ''
	};

	LIMIT = 20;

	componentDidMount() {
		window.onscroll = this.scrollListener;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.initialData !== this.props.initialData) {
			this.setState({ result: [], page: 1 });
			this.onScroll(this.state.page, this.state.loading);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('onscroll', this.setScrollListener);
	}

	scrollListener = async () => {
		const { page, loading } = this.state;
		await this.onScroll(page, loading);
	};

	onScroll = async (page, loading) => {
		try {
			const { offsetHeight, scrollTop, scrollHeight } = document.documentElement;
			const nextOffset = page * this.LIMIT;
			if (loading || page >= Number(this.props.totalCount) / this.LIMIT) return;
			if (offsetHeight + Math.ceil(scrollTop) >= scrollHeight) {
				this.setState({ loading: true });
				const response = await this.props.onScroll({
					limit: this.LIMIT,
					offset: nextOffset
				});
				this.setState({
					page: page + 1,
					result: [...this.state.result, ...response.data.result],
					loading: false
				});
			}
		} catch (e) {
			this.setState({ error: this.props.onError });
		}
	};

	render() {
		if (this.state.error) return <ErrorMsg message={this.state.error} />;
		return (
			<>
				{this.props.children([...this.props.initialData, ...this.state.result])}
				{this.state.loading && (
					<div className="mt-2 text-center">
						<MDBIcon icon="spinner" spin />
					</div>
				)}
			</>
		);
	}
}

export default InfiniteScroll;
