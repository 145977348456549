import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
	setSideNavCategory,
	setSideNavSubCategory,
	fetchCategories,
	fetchCartTotal
} from 'redux/actions';
import { productAPI } from 'api';
import ProductsList from './ProductsList';
import { Loading } from 'components';

class ProductsContainer extends PureComponent {
	static propTypes = {
		match: PropTypes.object,
		subCategory: PropTypes.string,
		setSideNavCategory: PropTypes.func,
		setSideNavSubCategory: PropTypes.func,
		fetchCartTotal: PropTypes.func
	};

	state = {
		error: '',
		products: null
	};

	componentDidMount() {
		window.scrollTo(0, 0);
		this.getProducts();
	}

	componentDidUpdate(prevProps) {
		if (this.props.subCategory !== prevProps.subCategory) {
			this.getProducts();
		}
	}

	getProducts = () => {
		const { category, subcategory } = this.props.match.params;
		productAPI
			.fetch(undefined, { category, subcategory })
			.then(res => this.setState({ products: res.data.result }))
			.catch(() =>
				this.setState({
					error: 'Uh oh, an error occurred while loading the products',
					products: null
				})
			);
	};

	componentWillUnmount() {
		if (!window.location.pathname.split('/').includes('inventory')) {
			this.props.setSideNavCategory(null);
			this.props.setSideNavSubCategory(null);
		}
	}

	render() {
		const { error, products } = this.state;
		return (
			<Loading notFound={products && !products.length} error={error}>
				<ProductsList
					{...this.props}
					fetchProducts={this.getProducts}
					products={products}
					fetchCartTotal={this.props.fetchCartTotal}
				/>
			</Loading>
		);
	}
}

const mapStateToProps = ({
	userDetails: { user },
	sideNavDetails: { activeSubCategory, categories, hideSideNav }
}) => {
	return {
		user,
		categories,
		hideSideNav,
		subCategory: activeSubCategory
	};
};

export default connect(
	mapStateToProps,
	{
		setSideNavCategory,
		setSideNavSubCategory,
		fetchCategories,
		fetchCartTotal
	}
)(ProductsContainer);
