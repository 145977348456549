import React, { useState, useContext } from 'react';
import { MDBModalBody, MDBModal, MDBModalHeader, MDBBtn, MDBIcon } from 'mdbreact';

import { estimateAPI, invoiceAPI } from 'api';
import { ContentMessage, Alert, ExpiredTokenAlert } from 'components';
import { checkExpiredSession, selectErrMsg } from 'util/general';
import { Context } from './Context';

const CancelModal = () => {
	const ctx = useContext(Context);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [expiredToken, setExpiredToken] = useState(false);

	const toggle = () => ctx.setState({ showCancelModal: false });

	const onCancel = async () => {
		setLoading(true);
		try {
			if (ctx.props.orderType === 'Estimate') {
				await ctx.qbo.rejectEstimate();
				await estimateAPI.update(ctx.props.order.id, { status: 'Voided' });
			} else {
				if (ctx.props.order.status !== 'Pending') {
					await ctx.qbo.voidInvoice();
				}
				await invoiceAPI.update(ctx.props.order.id, { status: 'Voided' });
			}
			await ctx.props.fetchData();
			ctx.setState({ showCancelModal: false });
		} catch (e) {
			const expiredToken = checkExpiredSession(e);
			setExpiredToken(expiredToken);
			setLoading(false);
			setError(!expiredToken && selectErrMsg(e));
		}
	};

	return (
		<MDBModal toggle={toggle} isOpen={ctx.state.showCancelModal} size="md">
			<MDBModalHeader toggle={toggle}>Cancel Order</MDBModalHeader>
			<MDBModalBody>
				<ContentMessage
					size="4x"
					icon="exclamation-triangle"
					className="amber-text"
					message="Are you sure you want to cancel this order?"
				/>
				<div className="text-center">
					<MDBBtn color="danger" outline disabled={loading} onClick={onCancel} size="md">
						{loading ? <MDBIcon spin icon="spinner" /> : 'Yes'}
					</MDBBtn>
					<MDBBtn outline color="primary" disabled={loading} onClick={toggle} size="md">
						No
					</MDBBtn>
				</div>
				<Alert show={error} color="danger" dismiss onDismiss={() => setError('')}>
					{error}
				</Alert>
				<ExpiredTokenAlert expiredToken={expiredToken} />
			</MDBModalBody>
		</MDBModal>
	);
};

export default CancelModal;
