import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CompanyDetailsContainer from './CompanyDetailsContainer';

const Routes = () => (
	<Switch>
		<Route exact path="/admin/company/:id" component={CompanyDetailsContainer} />
	</Switch>
);

export default Routes;
