import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ children, target }) =>
	target ? ReactDOM.createPortal(children, target) : <div />;

Portal.propTypes = {
	children: PropTypes.node,
	target: PropTypes.object
};

export default Portal;
