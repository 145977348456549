import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SidebarButton from './SidebarButton';

const SidebarStyle = styled.div`
	position: fixed;
	padding-top: 80px;
	top: 0;
	left: 0;
	width: 250px;
	height: 100%;
	min-height: 100%;
	overflow-x: hidden;
	background-color: #323a4b;
	-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	transition: -webkit-transform 0.3s ease-out 0s;
	z-index: 100;

	> div {
		padding-right: 20px;
		overflow-y: scroll;
		width: 297px;
		height: 100%;

		> ul {
			list-style-type: none;
		}
	}

	@media (min-width: 768px) {
		padding-top: 48px;
		transform: ${({ isSidebarClosed }) =>
			isSidebarClosed ? `translateX(-100%)` : `translateX(0%)`};
	}

	@media (max-width: 768px) {
		transform: ${({ visible }) => (visible ? `translateX(0%)` : `translateX(-100%)`)};
	}
`;

const Sidebar = props => {
	const { categories, visible, doNotShowSideNav, loading, error } = props;
	return (
		<SidebarStyle
			visible={visible}
			isSidebarClosed={doNotShowSideNav || loading || error}
			role="navigation"
		>
			<div>
				<ul className="nav">
					{categories &&
						Object.keys(categories).map(category => (
							<SidebarButton
								{...props}
								key={category}
								category={category}
								subCategories={categories[category]}
							/>
						))}
				</ul>
			</div>
		</SidebarStyle>
	);
};

Sidebar.propTypes = {
	setSideNavCategory: PropTypes.func.isRequired,
	setSideNavSubCategory: PropTypes.func.isRequired,
	categories: PropTypes.object,
	openedCategory: PropTypes.string,
	activeSubCategory: PropTypes.string,
	loading: PropTypes.bool.isRequired,
	doNotShowSideNav: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	visible: PropTypes.bool.isRequired
};

export default Sidebar;
