import React from 'react';
import { Col } from 'mdbreact';
import PropTypes from 'prop-types';

const DisplayImages = ({ img, index, name, pictureMover }) => {
	return (
		<div style={{ cursor: 'pointer' }} className="mb-3 mx-auto">
			<img
				src={img}
				alt={`Images of ${name}`}
				className="img-thumbnail img-fluid z-depth-1"
				onClick={() => pictureMover(index)}
			/>
		</div>
	);
};

DisplayImages.propTypes = {
	img: PropTypes.string,
	index: PropTypes.number,
	name: PropTypes.string,
	pictureMover: PropTypes.func
};

const RenderImages = ({ images, name, size, className, pictureMover }) => {
	if (!images.length) {
		return null;
	}

	if (size === 'lg') {
		return (
			<Col className={className}>
				{images.map(
					(image, i) =>
						i > 0 && (
							<DisplayImages
								index={i}
								name={name}
								key={image}
								img={image}
								pictureMover={pictureMover}
							/>
						)
				)}
			</Col>
		);
	}
	if (size === 'sm') {
		return images.map(
			(image, i) =>
				i > 0 && (
					<div key={image} className={className}>
						<DisplayImages img={image} index={i} name={name} pictureMover={pictureMover} />
					</div>
				)
		);
	}
};

RenderImages.propTypes = {
	images: PropTypes.array,
	name: PropTypes.string,
	size: PropTypes.string,
	className: PropTypes.string,
	pictureMover: PropTypes.func
};

export default RenderImages;
