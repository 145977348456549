import React from 'react';
import PropTypes from 'prop-types';

const CheckboxList = ({ checkedInputs, disabled, inputs, label, onChange }) => {
	if (inputs.length < 1) return <h5>Nothing was found</h5>;
	return (
		<div className="mb-3">
			{inputs.map((input, i) => (
				<div key={input.qboId} style={{ backgroundColor: i % 2 === 0 ? 'rgba(0,0,0,.05)' : '' }}>
					<label className="p-2">
						<input
							disabled={disabled(input)}
							className="mr-3"
							checked={checkedInputs.includes(input.qboId)}
							onChange={onChange}
							type="checkbox"
							value={input.qboId}
						/>
						<div style={{ display: 'inline-block' }}>{label(input)}</div>
					</label>
				</div>
			))}
		</div>
	);
};

CheckboxList.propTypes = {
	checkedInputs: PropTypes.array,
	disabled: PropTypes.func,
	label: PropTypes.func,
	inputs: PropTypes.array,
	onChange: PropTypes.func
};

export default CheckboxList;
