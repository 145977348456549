const userInitialState = {
	user: null,
	token: null,
	loading: false,
	error: false,
	errorDetail: null,
};

export default (state = userInitialState, { type, payload }) => {
	switch (type) {
		case 'UPDATE_USER_IN_STATE':
			return {
				...state,
				...payload,
			};
		case 'VALIDATE_JWT_PENDING':
			return {
				...state,
				loading: true,
			};
		case 'VALIDATE_JWT_FULFILLED':
			return {
				...state,
				loading: false,
				error: false,
				errorDetail: null,
				user: payload.data.user,
				token: sessionStorage.getItem('token'),
			};
		case 'VALIDATE_JWT_REJECTED':
			sessionStorage.clear();
			return {
				...state,
				error: true,
				errorDetail: 'there was an error while logging in',
				loading: false,
			};
		case 'SET_CART':
			return {
				...state,
				user: {
					...state.user,
					cart: payload,
				},
			};
		case 'FETCH_CART_TOTAL_FULFILLED':
			return {
				...state,
				user: {
					...state.user,
					cart: {
						...state.user.cart,
						cartTotal: payload.data.result[0].cartTotal,
					},
				},
			};
		default:
			return state;
	}
};
