const sideNavInitialState = {
	openedCategory: null,
	activeSubCategory: null,
	categories: null,
	loading: true,
	error: false,
	errorDetail: null,
	doNotShowSideNav: false,
	visible: false,
};

export default (state = sideNavInitialState, { type, payload, meta }) => {
	switch (type) {
		case 'TOGGLE_SIDENAV':
			return {
				...state,
				visible: payload,
			};
		case 'SET_SIDENAV_VISIBILITY':
			return {
				...state,
				doNotShowSideNav: payload,
			};
		case 'SET_SIDENAV_CATEGORY':
			return {
				...state,
				openedCategory: payload,
			};
		case 'SET_SIDENAV_SUBCATEGORY':
			return {
				...state,
				activeSubCategory: payload,
			};
		case 'FETCH_CATEGORIES_PENDING':
			return {
				...state,
				loading: meta.loading,
				error: false,
				errorDetail: null,
			};
		case 'FETCH_CATEGORIES_FULFILLED':
			return {
				...state,
				loading: false,
				error: false,
				errorDetail: null,
				categories: payload.data.result,
			};
		case 'FETCH_CATEGORIES_REJECTED':
			return {
				...state,
				error: true,
				loading: false,
				errorDetail: 'The inventory did not load',
			};
		default:
			return state;
	}
};
