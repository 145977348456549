import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { authAPI } from 'api';
import { validateJWT } from 'redux/actions';
import ResetPassView from './ResetPassView';

class ResetPassContainer extends Component {
	static propTypes = {
		match: PropTypes.object,
		history: PropTypes.object,
		validateJWT: PropTypes.func
	};

	state = {
		email: '',
		id: '',
		loading: false
	};

	componentDidMount() {
		this.setState({ loading: true });
		const { token } = this.props.match.params;
		sessionStorage.setItem('token', token);
		authAPI
			.passwordReset()
			.then(res => this.setState({ loading: false, email: res.data.email, id: res.data.id }))
			.catch(() => this.props.history.push('/'));
	}

	render() {
		const { loading, email, id } = this.state;
		if (loading) return <div className="loader" />;
		return <ResetPassView {...this.props} email={email} id={id} />;
	}
}

export default connect(
	null,
	{
		validateJWT
	}
)(ResetPassContainer);
