import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol } from 'mdbreact';

import { userAPI } from 'api';
import { Form, Alert } from 'components';
import { formData } from './formData';

class ResetPassView extends Component {
	static propTypes = {
		id: PropTypes.string,
		email: PropTypes.string,
		history: PropTypes.object,
		validateJWT: PropTypes.func
	};

	state = {
		id: this.props.id,
		loading: false,
		success: false,
		error: '',
		user: {
			email: this.props.email,
			password: '',
			password2: ''
		}
	};

	onChange = e => {
		this.setState({
			user: {
				...this.state.user,
				[e.target.name]: e.target.value
			}
		});
	};

	onSubmit = () => {
		const {
			id,
			user: { password, password2 }
		} = this.state;

		if (password !== password2) {
			return this.setState({ error: 'Passwords do not match' });
		}

		userAPI
			.update(id, { password })
			.then(() => {
				this.props.validateJWT();
				this.setState({ success: true });
			})
			.catch(() => this.setState({ error: 'An error occurred while resetting your password' }));
	};

	render() {
		const {
			onChange,
			onSubmit,
			state: { loading, error, success, user }
		} = this;
		return (
			<MDBRow>
				<MDBCol lg="8" className="offset-lg-2">
					<MDBCard>
						<MDBCardBody>
							<MDBCardTitle>Reset Your Password</MDBCardTitle>
							<Form
								onChange={onChange}
								onSubmit={onSubmit}
								loading={loading}
								inputs={formData(user)}
							/>
							<Alert show={error} color="danger">
								{error}
							</Alert>
							<Alert show={success} color="success">
								Your password has been set!{' '}
								<span className="text-primary pointer" onClick={() => this.props.history.push('/')}>
									Click here
								</span>{' '}
								to go the home page.
							</Alert>
						</MDBCardBody>
					</MDBCard>
				</MDBCol>
			</MDBRow>
		);
	}
}

export default ResetPassView;
