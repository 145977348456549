import React, { Component } from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact';
import PropTypes from 'prop-types';

import NewProductModal from './NewProductModal';

class AddProductModal extends Component {
	static propTypes = {
		user: PropTypes.object,
		fetchProducts: PropTypes.func.isRequired,
		fetchCategories: PropTypes.func.isRequired
	};

	state = {
		showModal: false
	};

	onClose = () => {
		this.setState({ showModal: false });
		this.props.fetchProducts();
		this.props.fetchCategories(false);
	};

	toggleModal = () => this.setState({ showModal: true });

	render() {
		const { user } = this.props;
		const permissions = user ? user.permissions : '';
		if (!permissions.includes('admin')) return null;
		return (
			<div className="mb-3 d-flex justify-content-end">
				<MDBBtn onClick={this.toggleModal} size="md">
					<MDBIcon icon="plus" /> Add Product
				</MDBBtn>
				<NewProductModal onClose={this.onClose} showModal={this.state.showModal} />
			</div>
		);
	}
}

export default AddProductModal;
