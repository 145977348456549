import request from './request';

export default class Methods {
	get(url, params = {}, headers = {}) {
		return request('get', url, undefined, params, headers);
	}

	post(url, data, headers = {}) {
		return request('post', url, data, undefined, headers);
	}

	put(url, data, headers = {}) {
		return request('put', url, data, undefined, headers);
	}

	delete(url, headers = {}) {
		return request('delete', url, undefined, undefined, headers);
	}
}
