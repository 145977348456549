import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setSideNavCategory, setSideNavSubCategory } from '../../redux/actions';
import Sidebar from './Sidebar';

class SidebarContainer extends PureComponent {
	static propTypes = {
		activeSubCategory: PropTypes.string,
		setSideNavCategory: PropTypes.func,
		setSideNavSubCategory: PropTypes.func
	};
	componentDidMount() {
		const path = window.location.pathname.split('/');
		if (path.includes('inventory') && !this.props.activeSubCategory) {
			this.props.setSideNavCategory(path[2] && path[2].replace(/%20/g, ' '));
			this.props.setSideNavSubCategory(path[3] && path[3].replace(/%20/g, ' '));
		}
	}

	render() {
		return <Sidebar {...this.props} />;
	}
}

const mapStateToProps = ({ sideNavDetails }) => ({
	...sideNavDetails
});

export default connect(
	mapStateToProps,
	{ setSideNavCategory, setSideNavSubCategory }
)(SidebarContainer);
