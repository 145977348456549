import React, { Component } from 'react';
import { MDBNavbar, MDBNavbarNav, MDBIcon } from 'mdbreact';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LoginModal } from 'components';
import { formatName } from 'util/general';
import { updateUserInState, toggleSidenav } from 'redux/actions';
import NavItem from './NavItem';
import Dropdown from './Dropdown';

const NavStyle = styled(MDBNavbar)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0 20px;
	color: white;

	@media (min-width: 768px) {
		flex-direction: row;
	}
	@media (min-width: 576px) {
		padding: 0 48px;
	}
`;

const ShowSidenavIcon = styled(MDBIcon)`
	position: fixed;
	top: 22px;
	left: 20px;
	cursor: pointer;
	font-size: 22px;

	@media (min-width: 768px) {
		display: none;
	}
	@media (min-width: 576px) {
		left: 48px;
	}
`;

class Nav extends Component {
	static propTypes = {
		user: PropTypes.object,
		hideToggle: PropTypes.bool,
		visible: PropTypes.bool.isRequired,
		updateUserInState: PropTypes.func.isRequired,
		toggleSidenav: PropTypes.func.isRequired
	};

	state = {
		active: window.location.pathname
	};

	// sets the current url route to state
	componentDidUpdate() {
		if (this.state.active !== window.location.pathname) {
			this.setState({
				active: window.location.pathname
			});
		}
	}

	// clears sessions storage and logs a user out
	logout = () => {
		sessionStorage.clear();
		this.props.updateUserInState({
			user: null,
			token: null,
			accessToken: null,
			refreshToken: null,
			loading: false,
			error: false,
			errorDetail: null
		});
	};

	toggleSidenav = () => {
		this.props.toggleSidenav(!this.props.visible);
	};

	checkLogIn = () => this.props.user;

	render() {
		const {
			toggleSidenav,
			state: { active },
			props: { hideToggle, user }
		} = this;
		return (
			<NavStyle className="py-2" color="stylish-color" dark expand="xs" fixed="top" scrolling>
				{!hideToggle && <ShowSidenavIcon onClick={toggleSidenav} icon="bars" />}
				<div>
					<Link className="text-white navbar-brand py-0" to="/">
						Scottsdale Event Décor
					</Link>
				</div>
				<div>
					<MDBNavbarNav className="flex-row" right>
						<NavItem active={active} to="/">
							Home
						</NavItem>
						<NavItem active={active} to="/gallery">
							Gallery
						</NavItem>
						{!user && <LoginModal />}
						{user && (
							<Dropdown
								permissions={user.permissions}
								name={formatName(user)}
								logout={this.logout}
							/>
						)}
					</MDBNavbarNav>
				</div>
			</NavStyle>
		);
	}
}

const mapStateToProps = ({ userDetails, sideNavDetails }) => {
	return {
		user: userDetails.user,
		hideToggle: sideNavDetails.doNotShowSideNav,
		visible: sideNavDetails.visible
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		{ updateUserInState, toggleSidenav }
	)(Nav)
);
