import React, { Component } from 'react';
import { MDBIcon } from 'mdbreact';
import PropTypes from 'prop-types';

import { Form, Alert } from 'components';
import { qboAPI } from 'api';
import { selectErrMsg, checkExpiredSession } from 'util/general';
import { formData } from './formData';
import { updateProduct } from '../requests';

class EditForm extends Component {
	static propTypes = {
		product: PropTypes.object,
		onSuccess: PropTypes.func,
		setExpiredToken: PropTypes.func
	};

	state = {
		loading: false,
		categories: null,
		categoriesLoading: true,
		error: '',
		product: { ...this.props.product }
	};

	componentDidMount() {
		this.getCategories();
	}

	onChange = e => {
		const name = e.target.name;
		let subcategory = this.state.product.subcategory;
		if (name === 'category') {
			subcategory = '';
		}
		this.setState({
			product: {
				...this.state.product,
				subcategory,
				[name]: e.target.value
			}
		});
	};

	getCategories = () => {
		qboAPI
			.getCategories()
			.then(result => {
				const categories = result.data.result;
				const category = categories.categories.find(x => x.Name === this.state.product.category);
				const subcategory = categories.subcategories.find(
					x => x.Name === this.state.product.subcategory
				);

				this.setState({
					categories,
					product: {
						...this.state.product,
						category: `${category.Id}__${category.Name}`,
						subcategory: `${subcategory.Id}__${subcategory.Name}`
					},
					categoriesLoading: false
				});
			})
			.catch(e => {
				const expiredToken = checkExpiredSession(e);
				this.setState({
					expiredToken,
					success: false,
					categories: {
						categories: [],
						subcategories: []
					},
					categoriesLoading: false,
					error: !expiredToken && selectErrMsg(e)
				});
			});
	};

	onSubmit = async () => {
		this.setState({ loading: true });
		try {
			await updateProduct(this.state.product);
			this.setState({ loading: false });
			this.props.onSuccess();
		} catch (e) {
			if (checkExpiredSession(e)) {
				this.props.setExpiredToken(true);
				this.setState({ loading: false });
			} else {
				this.setState({ error: selectErrMsg(e), loading: false });
			}
		}
	};

	onDismiss = () => this.setState({ error: '' });

	render() {
		const { categories, categoriesLoading, error, loading, product } = this.state;
		if (categoriesLoading) {
			return (
				<div className="text-center">
					<MDBIcon icon="spinner" spin style={{ color: '#4b515d' }} />
				</div>
			);
		}
		return (
			<>
				<Alert color="danger" dismiss onDismiss={this.onDismiss} show={error}>
					{error}
				</Alert>
				<Form
					columns={2}
					onChange={this.onChange}
					onSubmit={this.onSubmit}
					loading={loading}
					inputs={formData(product, categories)}
				/>
			</>
		);
	}
}

export default EditForm;
