import Methods from './Methods';

class CartAPI extends Methods {
	getCart(id = '', params) {
		return super.get(`/cart/${id}`, params);
	}

	getCartProducts(id, params) {
		return super.get(`/cart/${id}/products`, params);
	}

	createCart(data) {
		return super.post('/cart', data);
	}

	saveCartProduct(cartId, productId, product) {
		return super.post(`/cart/${cartId}/cartProduct/${productId}`, product);
	}

	updateCart(id, data) {
		return super.put(`/cart/${id}`, data);
	}

	copyCart(cartId, activeCartId) {
		return super.put(`/cart/${cartId}/copy/${activeCartId}`);
	}

	deleteCart(id) {
		return super.delete(`/cart/${id}`);
	}

	deleteCartProduct(id) {
		return super.delete(`/cartProduct/${id}`);
	}

	bulkDeleteCartProduct(data) {
		return super.delete(`/cartProduct/deleteBulk?ids=${data}`);
	}

	bulkSaveCartProduct(data) {
		return super.post('/saveBulk/cartProduct', data);
	}
}

const cartAPI = new CartAPI();

export default cartAPI;
