import React, { Component } from 'react';
import { MDBIcon, MDBModalBody, MDBModal, MDBModalHeader } from 'mdbreact';
import PropTypes from 'prop-types';

import { qboAPI } from 'api';
import { selectErrMsg, checkExpiredSession, removeProperty } from 'util/general';
import { uploadImagesToAWS, createProduct, createCategory } from '../requests';
import { productForm, categoryForm } from './formData';
import { Form, Portal, Alert, ExpiredTokenAlert, ModalSuccess } from 'components';
import SelectProductType from './SelectProductType';

const uploadElement = () => document.querySelectorAll('.custom-file')[0];

const ref = React.createRef();

class NewProductModal extends Component {
	static propTypes = {
		showModal: PropTypes.bool,
		onClose: PropTypes.func
	};

	initialState = {
		categories: {
			categories: [],
			subcategories: []
		},
		categoriesLoading: false,
		error: '',
		expiredToken: false,
		fileError: '',
		formType: '',
		loading: false,
		showModal: false,
		showForm: false,
		success: false,
		category: {
			name: '',
			isSubCategory: false,
			category: ''
		},
		product: {
			name: '',
			id: '',
			category: '',
			subcategory: '',
			description: '',
			price: '',
			quantity: '',
			imagesToUpload: []
		}
	};

	state = { ...this.initialState };

	onProductChange = e => {
		let fileError;
		let files = e.target.files;
		let value = e.target.value;
		if (files) {
			const arr = Array.from(files);
			fileError = this.checkFileUploadError(arr);
			value = fileError ? [] : arr;
		}
		if (!fileError) {
			ref.current.classList.remove('is-invalid');
		}
		this.setState({
			...this.state,
			fileError,
			product: {
				...this.state.product,
				[e.target.name]: value
			}
		});
	};

	checkFileUploadError = files => {
		if (files.length > 5) {
			ref.current.value = '';
			ref.current.classList.add('is-invalid');
			return 'Only 5 images can be uploaded at a time';
		}

		const sizeError = files.filter(file => file.size >= 100000).map(file => file.name);
		if (sizeError.length === 1) {
			ref.current.value = '';
			ref.current.classList.add('is-invalid');
			return `Image ${sizeError[0]} has exceeded the max file size of 100kb`;
		} else if (sizeError.length > 1) {
			ref.current.value = '';
			ref.current.classList.add('is-invalid');
			return `Images ${sizeError.toString()} have exceeded the max file size of 100kb`;
		}
	};

	onProductSubmit = async () => {
		try {
			this.setState({ loading: true });
			const { product } = this.state;
			const { imagesToUpload } = product;
			const finalURLs = await uploadImagesToAWS(imagesToUpload, product);
			const finalProduct = removeProperty('imagesToUpload')(product);
			await createProduct(finalProduct, finalURLs);
			this.setState({ loading: false, success: true, expiredToken: false });
		} catch (e) {
			const expiredToken = checkExpiredSession(e);
			const error = !expiredToken && selectErrMsg(e);
			this.setState({ error, loading: false, success: false, expiredToken });
		}
	};

	getCategories = () => {
		qboAPI
			.getCategories()
			.then(result => {
				this.setState({
					categories: result.data.result,
					categoriesLoading: false
				});
			})
			.catch(e => {
				const expiredToken = checkExpiredSession(e);
				this.setState({
					expiredToken,
					success: false,
					categories: {
						categories: [],
						subcategories: []
					},
					categoriesLoading: false,
					error: !expiredToken && selectErrMsg(e)
				});
			});
	};

	onClose = () => {
		this.setState(this.initialState);
		this.props.onClose();
	};

	onDismiss = () => this.setState({ error: '' });

	setFormType = formType => {
		this.setState({ formType, categoriesLoading: true }, this.getCategories);
	};

	onCategoryChange = e => {
		let value = e.target.value;
		if (e.target.name === 'isSubCategory') value = e.target.checked;
		this.setState({
			...this.state,
			category: {
				...this.state.category,
				[e.target.name]: value
			}
		});
	};

	onCategorySubmit = () => {
		this.setState({ loading: true });
		createCategory(this.state.category)
			.then(() => {
				this.setState({ loading: false, success: true, expiredToken: false });
			})
			.catch(e => {
				const expiredToken = checkExpiredSession(e);
				this.setState({
					expiredToken,
					success: false,
					loading: false,
					error: !expiredToken && selectErrMsg(e)
				});
			});
	};

	showForm = formType => {
		const { loading, category, categories, product } = this.state;
		let inputs;
		let onSubmit;
		let onChange;
		let columns;
		if (formType === 'product') {
			columns = 2;
			inputs = productForm(product, ref, categories);
			onSubmit = this.onProductSubmit;
			onChange = this.onProductChange;
		} else {
			columns = 1;
			inputs = categoryForm(category, categories);
			onSubmit = this.onCategorySubmit;
			onChange = this.onCategoryChange;
		}
		return (
			<Form
				columns={columns}
				onChange={onChange}
				onSubmit={onSubmit}
				loading={loading}
				inputs={inputs}
			/>
		);
	};

	render() {
		const { showModal } = this.props;
		const { categoriesLoading, fileError, error, formType, expiredToken, success } = this.state;
		return (
			<MDBModal toggle={this.onClose} isOpen={showModal} size="lg">
				<MDBModalHeader toggle={this.onClose}>Add a new product</MDBModalHeader>
				<MDBModalBody>
					<ModalSuccess success={success} message="Your product has been saved!">
						{!formType && <SelectProductType setFormType={this.setFormType} />}
						{categoriesLoading && (
							<div className="text-center">
								<MDBIcon icon="spinner" spin />
							</div>
						)}
						{!categoriesLoading && formType && this.showForm(formType)}
						<ExpiredTokenAlert expiredToken={expiredToken} />
						<Alert show={error} color="danger" dismiss onDismiss={this.onDismiss}>
							{error}
						</Alert>
						{fileError && (
							<Portal target={uploadElement()}>
								<div className="invalid-feedback">{fileError}</div>
							</Portal>
						)}
					</ModalSuccess>
				</MDBModalBody>
			</MDBModal>
		);
	}
}

export default NewProductModal;
