import React from 'react';
import PropTypes from 'prop-types';

import ContentMessage from './ContentMessage';

const ModalSuccess = ({ success, message, children }) => {
	if (!success) {
		return children;
	}
	return (
		<div
			style={{ minHeight: '300px' }}
			className="d-flex flex-column align-items-center justify-content-around"
		>
			<ContentMessage
				size="5x"
				far={true}
				icon="check-circle"
				className="green-text"
				message={message}
			/>
		</div>
	);
};

ModalSuccess.propTypes = {
	success: PropTypes.bool.isRequired,
	message: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.node])
};

export default ModalSuccess;
