import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { authAPI } from 'api';
import { updateUserInState } from '../../redux/actions';
import { ErrorMsg } from 'components';

const StyledCallback = styled.div`
	z-index: 100000;
	background-color: white;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`;

class Callback extends Component {
	static propTypes = {
		updateUserInState: PropTypes.func.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	};

	state = {
		error: false,
		loading: false
	};

	componentDidMount() {
		authAPI
			.fetchAccessToken(this.props.location.search)
			.then(res => {
				this.props.updateUserInState({ user: res.data.user });
				this.props.history.push('/');
			})
			.catch(() => {
				this.setState({ error: true });
			});
	}

	render() {
		if (!sessionStorage.getItem('token')) {
			return <Redirect to="/" />;
		}
		if (this.state.error) {
			sessionStorage.clear();
			return <ErrorMsg message="Something went wrong while logging in." />;
		}
		return (
			<StyledCallback>
				<div className="loader" />
			</StyledCallback>
		);
	}
}

export default connect(
	null,
	{ updateUserInState }
)(Callback);
