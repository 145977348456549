import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	setSideNavVisibility,
	setSideNavCategory,
	setSideNavSubCategory
} from '../../redux/actions';
import PropTypes from 'prop-types';

class HideSideNav extends Component {
	static propTypes = {
		setSideNavVisibility: PropTypes.func.isRequired,
		setSideNavCategory: PropTypes.func.isRequired,
		setSideNavSubCategory: PropTypes.func.isRequired,
		children: PropTypes.node.isRequired
	};

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.setSideNavVisibility(true);
		this.props.setSideNavCategory(null);
		this.props.setSideNavSubCategory(null);
	}

	componentWillUnmount() {
		this.props.setSideNavVisibility(false);
	}

	componentDidUpdate() {
		this.props.setSideNavVisibility(true);
	}

	render() {
		const { children } = this.props;

		const childrenWithProps = React.Children.map(children, child =>
			React.cloneElement(child, { ...this.props })
		);

		return childrenWithProps;
	}
}

export default connect(
	null,
	{ setSideNavVisibility, setSideNavCategory, setSideNavSubCategory }
)(HideSideNav);
