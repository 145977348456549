import React from 'react';
import { Route, Switch } from 'react-router-dom';

import UsersRoutes from './users/Routes';
import CompanyRoutes from './companies/Routes';
import { SecuredRoute, HideSideNav, OrderTabs } from 'components';

const Routes = () => (
	<SecuredRoute requiredPermissions={['admin', 'superadmin']}>
		<HideSideNav>
			<Switch>
				<Route path="/admin/users" component={UsersRoutes} />
				<Route path="/admin/company" component={CompanyRoutes} />
				<Route exact path="/admin/orders" component={OrderTabs} />
			</Switch>
		</HideSideNav>
	</SecuredRoute>
);

export default Routes;
