import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const CalculateTotal = ({ total }) => {
	const [trucking, setTrucking] = useState(0);
	const [loading, setLoading] = useState(true);
	const [err, setErr] = useState(null);

	useEffect(() => {
		const getLabor = async () => {
			try {
				const res = await axios.get('/trucking');
				const data = res.data.result || {};
				const labor = data.value || 0;
				setTrucking(labor);
			} catch (e) {
				setErr(`failed to retrieve trucking fee: ${e}`);
			} finally {
				setLoading(false);
			}
		};
		getLabor();
	}, []);

	if (loading) {
		return null;
	}

	const taxes = (total + trucking) * 0.081;

	return (
		<div style={{ fontSize: '14px' }} className="d-flex justify-content-end">
			{err && <div>{err}</div>}
			<div>
				<div>Rental Subtotal: ${parseFloat(Math.round(total * 100) / 100).toFixed(2)}</div>
				<div>Trucking: ${parseFloat(Math.round(trucking * 100) / 100).toFixed(2)}</div>
				<div>Taxes(8.1%): ${parseFloat(Math.round(taxes * 100) / 100).toFixed(2)}</div>
				<div style={{ height: '1px', backgroundColor: 'black', margin: '3px 0px' }} />
				<div style={{ fontSize: '22px' }} className="font-weight-bolder">
					Est Total: ${parseFloat(Math.round((total + trucking + taxes) * 100) / 100).toFixed(2)}
				</div>
				<div style={{ fontSize: '14px' }}>
					Labor to be determined. Trucking minimum charge of 350. Tax is based on the delivery city
					the items are rented in.
				</div>
			</div>
		</div>
	);
};

CalculateTotal.propTypes = {
	total: PropTypes.number,
	percent: PropTypes.number,
};

CalculateTotal.defaultProps = {
	total: 0,
};

export default CalculateTotal;
