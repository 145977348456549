import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { MDBContainer } from 'mdbreact';

import HomeRouter from './home/Routes';
import GalleryRouter from './gallery/Routes';
import InventoryRouter from './inventory/Routes';
import CheckoutRouter from './checkout/Routes';
import ProfileRouter from './profile/Routes';
import OrdersRouter from './orders/Routes';
import CartRouter from './carts/Routes';
import CallbackRouter from './callback/Callback';
import AdminRouter from './admin/Routes';
import ResetPassRouter from './resetPass/Routes';
import TosRouter from './tos/Router';

/**
 * Application routes. Each page has a Routes.js file for all routes
 * related to that page. All Routes.js files get imported here
 */

const AppRouter = () => (
	<MDBContainer className="mb-3">
		<Switch>
			<Route exact path="/" component={HomeRouter} />
			<Route exact path="/gallery" component={GalleryRouter} />
			<Route path="/inventory" component={InventoryRouter} />
			<Route path="/checkout" component={CheckoutRouter} />
			<Route path="/profile" component={ProfileRouter} />
			<Route path="/orders" component={OrdersRouter} />
			<Route path="/carts" component={CartRouter} />
			<Route path="/callback" component={CallbackRouter} />
			<Route path="/admin" component={AdminRouter} />
			<Route path="/reset" component={ResetPassRouter} />
			<Route path="/tos" component={TosRouter} />
			<Route component={HomeRouter} />
		</Switch>
	</MDBContainer>
);

export default AppRouter;
