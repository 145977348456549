import React from 'react';
import { Route, Switch } from 'react-router-dom';

import UserTabs from './usersList/UserTabs';
import UserDetailsContainer from './userDetails/UserDetailsContainer';

const Routes = () => (
	<Switch>
		<Route exact path="/admin/users" component={UserTabs} />
		<Route exact path="/admin/users/:id" component={UserDetailsContainer} />
	</Switch>
);

export default Routes;
