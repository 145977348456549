import React, { Component } from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact';
import PropTypes from 'prop-types';

class SelectUserType extends Component {
	static propTypes = {
		selectOption: PropTypes.func.isRequired
	};

	state = {
		selectedOption: ''
	};

	onClick = e => {
		this.setState({
			selectedOption: e.target.name
		});
	};

	render() {
		const { selectedOption } = this.state;

		return (
			<div>
				<h4>What would you like to modify?</h4>
				<div className="text-center my-4">
					<MDBBtn
						size="md"
						active={selectedOption === 'images'}
						name="images"
						onClick={this.onClick}
					>
						Images
					</MDBBtn>
					<MDBBtn
						size="md"
						active={selectedOption === 'productDetails'}
						name="productDetails"
						onClick={this.onClick}
					>
						Product Details
					</MDBBtn>
				</div>
				{selectedOption && (
					<div className="text-right">
						<MDBBtn size="md" onClick={() => this.props.selectOption(selectedOption)}>
							Continue
							{'   '}
							<MDBIcon icon="arrow-right" />
						</MDBBtn>
					</div>
				)}
			</div>
		);
	}
}

export default SelectUserType;
