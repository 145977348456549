import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Loading, EmptyCart } from 'components';
import cartAPI from 'api/CartAPI';
import CartView from './CartView';

class CartContainer extends Component {
	static propTypes = {
		user: PropTypes.object
	};
	state = {
		cart: null,
		error: '',
		loading: true
	};
	componentDidMount() {
		const { user } = this.props;
		if (user) {
			cartAPI
				.getCartProducts(user.cart.id, { isActive: true })
				.then(res => {
					this.setState({ cart: res.data.result, loading: false, error: '' });
				})
				.catch(() =>
					this.setState({ error: 'Something went wrong while loading the cart', loading: false })
				);
		}
	}

	render() {
		const { loading, error, cart } = this.state;

		if (cart && cart.length && !cart[0].CartProducts.length) {
			return <EmptyCart />;
		}

		return (
			<Loading loading={loading} error={error}>
				<CartView {...this.props} cart={cart && cart[0]} products={cart && cart[0].CartProducts} />
			</Loading>
		);
	}
}

const mapStateToProps = ({ userDetails: { user } }) => {
	return {
		user
	};
};

export default connect(mapStateToProps)(CartContainer);
