import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Tabs, SelectOrder } from 'components';
import OrdersTable from './OrdersTable';

class OrderTabs extends Component {
	state = {
		params: {
			UserId: this.props.match.params.id
		}
	};

	onSelect = params => {
		this.setState({ params });
	};

	render() {
		const { hideQboSelect, user } = this.props;
		const { params } = this.state;
		return (
			<>
				<div className=" mb-3 d-flex align-items-center justify-content-between">
					<h2 className="mb-0">Orders</h2>
					<SelectOrder onSelect={this.onSelect} hideQboSelect={hideQboSelect} />
				</div>
				<Tabs>
					<div label="Estimates">
						<OrdersTable user={user} params={params} orderType="estimate" />
					</div>
					<div label="Invoices">
						<OrdersTable user={user} params={params} orderType="invoice" />
					</div>
				</Tabs>
			</>
		);
	}
}

const mapStateToProps = ({ userDetails: { user } }, ownProps) => ({
	...ownProps,
	user
});

OrderTabs.propTypes = {
	user: PropTypes.object,
	params: PropTypes.object,
	hideQboSelect: PropTypes.bool,
	match: PropTypes.object
};

OrderTabs.defaultProps = {
	params: {},
	hideQboSelect: false
};

export default connect(mapStateToProps)(OrderTabs);
