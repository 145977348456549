import React from 'react';
import { MDBBtn } from 'mdbreact';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { setStatusColor } from 'util/general';

export const tableData = (data, orderType) => {
	const rows = data.map(order => {
		return {
			id: order.id,
			'Customer/Company': (
				<>
					<Link to={`/admin/users/${order.UserId}`} className="blue-link">
						{order.User.displayName}
					</Link>
					<div style={{ fontSize: '12px' }}>Company - {order.User.ownCompany}</div>
				</>
			),
			'No.': order.DocNumber,
			'Event Date': moment(order.eventDate).format('MMMM Do YYYY'),
			Status: <span className={setStatusColor(order.status)}>{order.status}</span>,
			Modified: order.hasBeenModified ? (
				<span className="text-warning font-weight-bold">Modified</span>
			) : (
				'Not Modified'
			),
			'Group Name': order.groupName,
			View: (
				<Link className="text-white" to={`/orders/${orderType}/${order.id}`}>
					<MDBBtn color="blue" outline size="sm">
						View
					</MDBBtn>
				</Link>
			)
		};
	});

	const columns = [
		'Customer/Company',
		'No.',
		'Event Date',
		'Status',
		'Modified',
		'Group Name',
		'View'
	];

	if (!window.location.pathname.includes('/admin/orders')) {
		columns.shift();
		rows.forEach(row => {
			delete row.Customer;
		});
	}

	return {
		columns,
		rows
	};
};
