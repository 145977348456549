import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MDBCard, MDBCardBody } from 'mdbreact';
import styled from 'styled-components';

import { Tabs, SearchBar } from 'components';
import CreateUserModal from './createUserModal/CreateUserModal';
import ImportUserModal from './createUserModal/ImportUserModal';
import UserTable from './UserTable';
import CompanyTab from './CompanyTab';

const StyledDiv = styled.div`
	display: flex;
	flex-direction: column;
	button {
		padding: 0.5rem 1.6rem;
		font-size: 0.64rem;
	}

	@media (min-width: 576px) {
		flex-direction: row;
		justify-content: space-between;
		button {
			padding: 0.7rem 1.6rem;
			font-size: 0.7rem;
		}
	}
`;

class UserTabs extends Component {
	static propTypes = {
		user: PropTypes.object
	};

	state = {
		params: {},
		newUserPermissions: ''
	};

	setNewUserPermissions = perm => {
		this.setState({ newUserPermissions: perm });
	};

	clearNewUserPermissions = () => {
		this.setState({ newUserPermissions: '' });
	};

	onChange = e => {
		const params = e.target.value ? { like: { ownCompany: { $like: `${e.target.value}%` } } } : {};
		this.setState({ params });
	};

	onCompanyChange = e => {
		const params = e.target.value ? { like: { name: { $like: `${e.target.value}%` } } } : {};
		this.setState({ params });
	};

	render() {
		return (
			<>
				<StyledDiv className="mb-3 align-items-center">
					<h2 className="mb-0">Users</h2>
					<div>
						<CreateUserModal setNewUserPermissions={this.setNewUserPermissions} />
						<ImportUserModal />
					</div>
				</StyledDiv>
				<Tabs>
					<div label="Customers">
						<MDBCard>
							<MDBCardBody>
								<SearchBar
									className="ml-auto"
									onChange={this.onChange}
									searchPlaceholder="customer"
								/>
								<UserTable
									clear={this.clearNewUserPermissions}
									newUserPermissions={this.state.newUserPermissions}
									params={this.state.params}
									permissions="customer"
								/>
							</MDBCardBody>
						</MDBCard>
					</div>
					<div label="Companies">
						<MDBCard>
							<MDBCardBody>
								<SearchBar
									className="ml-auto"
									onChange={this.onCompanyChange}
									searchPlaceholder="company"
								/>
								<CompanyTab
									clear={this.clearNewUserPermissions}
									newUserPermissions={this.state.newUserPermissions}
									params={this.state.params}
									permissions="company"
								/>
							</MDBCardBody>
						</MDBCard>
					</div>
					{this.props.user.permissions === 'superadmin' && (
						<div label="Admins">
							<MDBCard>
								<MDBCardBody>
									<SearchBar
										className="ml-auto"
										onChange={this.onChange}
										searchPlaceholder="admin"
									/>
									<UserTable
										clear={this.clearNewUserPermissions}
										newUserPermissions={this.state.newUserPermissions}
										params={this.state.params}
										permissions="admin"
									/>
								</MDBCardBody>
							</MDBCard>
						</div>
					)}
				</Tabs>
			</>
		);
	}
}

const mapStateToProps = ({ userDetails: { user } }) => ({ user });

export default connect(mapStateToProps)(UserTabs);
