import Methods from './Methods';

class AuthAPI extends Methods {
	fetchUser(email, password) {
		return super.post('/auth/user', { email, password });
	}

	fetchAccessToken(queryString) {
		return super.get(`/auth/accessToken${queryString}`);
	}

	validateJWT() {
		return super.get('/auth/jwt');
	}

	passwordReset() {
		return super.get('/auth/resetLink');
	}
}

const authAPI = new AuthAPI();

export default authAPI;
