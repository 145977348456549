import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { OrderDetailsContainer, SecuredRoute, HideSideNav, OrderTabs } from 'components';

const Routes = () => (
	<SecuredRoute requiredPermissions={['admin', 'superadmin', 'customer']}>
		<HideSideNav>
			<Switch>
				<Route exact path="/orders" component={OrderTabs} />
				<Route exact path="/orders/estimate/:id" component={OrderDetailsContainer} />
				<Route exact path="/orders/invoice/:id" component={OrderDetailsContainer} />
			</Switch>
		</HideSideNav>
	</SecuredRoute>
);

export default Routes;
