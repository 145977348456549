import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledInput = styled.input`
	width: 50%;
	@media (min-width: 768px) {
		width: 25%;
	}
`;

const SearchBar = ({ className, onChange, searchPlaceholder }) => (
	<div className="form-group">
		<StyledInput
			onChange={onChange}
			type="text"
			className={`form-control ${className}`}
			id="userSearch"
			name="userSearch"
			placeholder={`Search by ${searchPlaceholder}`}
		/>
	</div>
);

SearchBar.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	searchPlaceholder: PropTypes.string
};

export default SearchBar;
