import React from 'react';
import { MDBNavItem, MDBDropdown, MDBDropdownMenu, MDBDropdownToggle } from 'mdbreact';
import PropTypes from 'prop-types';

import AdminDropdown from './AdminDropdown';
import CustomerDropdown from './CustomerDropdown';
import DropdownItem from './DropdownItem';

const Dropdown = ({ name, logout, permissions }) => (
	<MDBNavItem>
		<MDBDropdown>
			<MDBDropdownToggle nav caret className="p-1">
				{name}
			</MDBDropdownToggle>
			<MDBDropdownMenu>
				<DropdownItem to="/profile" icon="user-alt">
					Profile
				</DropdownItem>
				{permissions === 'customer' && <CustomerDropdown />}
				{permissions.includes('admin') && <AdminDropdown />}
				<div className="dropdown-divider" />
				<DropdownItem to="/" icon="sign-out-alt" onClick={logout}>
					Logout
				</DropdownItem>
			</MDBDropdownMenu>
		</MDBDropdown>
	</MDBNavItem>
);

Dropdown.propTypes = {
	logout: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	permissions: PropTypes.string
};

export default Dropdown;
