import React from 'react';
import PropTypes from 'prop-types';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import styled from 'styled-components';

const StyledTable = styled(MDBTable)`
	td {
		font-weight: 400;
	}
	td {
		padding: 0 !important;
	}

	th,
	td {
		padding: 0.55rem !important;
	}
`;

const Table = ({ data: { columns, rows } }) => {
	return (
		<StyledTable hover responsive>
			<MDBTableHead color="blue-grey lighten-5">
				<tr>
					{columns.map(item => (
						<th key={item}>
							<b>{item}</b>
						</th>
					))}
				</tr>
			</MDBTableHead>
			<MDBTableBody>
				{rows.map(rowItem => (
					<tr key={rowItem.id}>
						{columns.map(colItem => (
							<td key={colItem}>{rowItem[colItem]}</td>
						))}
					</tr>
				))}
			</MDBTableBody>
		</StyledTable>
	);
};

Table.propTypes = {
	data: PropTypes.shape({
		columns: PropTypes.array,
		rows: PropTypes.array
	})
};

Table.defaultProps = {
	data: {
		colums: [],
		rows: []
	}
};

export default Table;
