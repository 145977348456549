import React, { Component, Fragment } from 'react';
import { Router } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MDBAlert, MDBContainer } from 'mdbreact';

import browserHistory from 'util/general';
import { Navbar, Sidebar } from 'components';
import { fetchCategories, validateJWT } from '../redux/actions';
import AppRouter from './AppRouter';

const AppStyles = styled.div`
	display: flex;
	height: 100vh;
	padding-top: 48px;

	> main {
		padding-top: 60px;
		width: 100%;
		transition: margin-left 0.3s ease-out 0s;
		position: relative;

		@media (min-width: 768px) {
			padding-top: 20px;
			margin-left: ${({ isSidebarClosed }) => (isSidebarClosed ? `0px` : `250px`)};
		}

		@media (max-width: 768px) {
			margin-left: 0px;
		}
	}
`;

// App
class App extends Component {
	static propTypes = {
		fetchCategories: PropTypes.func,
		validateJWT: PropTypes.func,
		error: PropTypes.string,
		user: PropTypes.object,
		isSidebarClosed: PropTypes.bool,
	};

	componentDidMount() {
		if (!this.props.user && sessionStorage.getItem('token')) {
			if (!window.location.pathname.includes('/reset/')) {
				this.props.validateJWT();
			}
		}
		this.props.fetchCategories();
	}

	render() {
		const { isSidebarClosed, error } = this.props;
		return (
			<Router history={browserHistory}>
				<Fragment>
					<Navbar />
					<AppStyles isSidebarClosed={isSidebarClosed}>
						<Sidebar />
						<main role="main">
							{error && (
								<MDBContainer>
									<MDBAlert color="danger">{this.props.error}</MDBAlert>
								</MDBContainer>
							)}
							<AppRouter />
						</main>
					</AppStyles>
				</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = ({
	userDetails: { user, errorDetail: userErrorDetail },
	sideNavDetails: { doNotShowSideNav, loading, errorDetail: sidenavErrorDetail },
}) => {
	return {
		user,
		error: userErrorDetail || sidenavErrorDetail,
		isSidebarClosed: doNotShowSideNav || loading || sidenavErrorDetail,
	};
};

export default connect(mapStateToProps, {
	fetchCategories,
	validateJWT,
})(App);
