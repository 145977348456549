import { MDBInput } from 'mdbreact';
import styled from 'styled-components';

const StyledInput = styled(MDBInput)`
	border-bottom: 1px solid #ced4da !important;
	padding: 12px 0px !important;
	color: black !important;
`;

export const formData = credentials => [
	{
		inputType: StyledInput,
		value: credentials.email,
		id: 'loginEmail',
		name: 'email',
		label: 'Email',
		group: true,
		required: true,
		'data-error-msg': 'Please provide a valid email address',
		type: 'email'
	},
	{
		inputType: StyledInput,
		value: credentials.password,
		id: 'loginPasword',
		name: 'password',
		label: 'Password',
		group: true,
		required: true,
		type: 'password'
	}
];

export const resetData = email => [
	{
		inputType: StyledInput,
		value: email,
		id: 'resetEmail',
		name: 'resetEmail',
		label: 'Email',
		group: true,
		required: true,
		'data-error-msg': 'Please provide a valid email address',
		type: 'email'
	}
];
