import { createBrowserHistory } from 'history';

export default createBrowserHistory();

export const formatColumnName = function(string) {
	return string.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
};

export const formatName = function(user) {
	if (user && typeof user === 'object') {
		return `${user.firstName} ${user.lastName}`;
	}
	return '';
};

export const checkExpiredSession = function(error) {
	return (
		(error &&
			error.response &&
			error.response.headers &&
			error.response.headers.expiredtoken === 'true') ||
		false
	);
};

export const selectErrMsg = e =>
	(e && e.response && e.response.data && e.response.data.message) || 'An error occurred';

export const selectResponse = response => response.data.result;

export const setStatusColor = function(status) {
	let className =
		status === 'Pending'
			? 'text-warning'
			: status === 'Open'
			? 'text-success'
			: status === 'Voided'
			? 'text-black-50'
			: 'text-black-50';

	className += ' font-weight-bold';
	return className;
};

export const removeProperty = prop => ({ [prop]: _, ...rest }) => rest;

export const trimPostData = data => {
	return data && data.constructor === Object
		? JSON.parse(JSON.stringify(data).replace(/"\s+|\s+"/g, '"'))
		: data;
};

export const upperCaseFirstLetter = word => word.charAt(0).toUpperCase() + word.slice(1);

export const clone = obj => JSON.parse(JSON.stringify(obj));

export const sumTotal = cart => {
	const total = cart.CartProducts.reduce(
		(total, currentValue) => (total += Number(currentValue.total)),
		0
	);
	return total;
};
