import React from 'react';
import { MDBDropdownItem, MDBIcon } from 'mdbreact';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DropdownItem = ({ icon, to, children, onClick }) => {
	return (
		<MDBDropdownItem onClick={onClick}>
			<MDBIcon icon={icon} />
			<Link to={to}>{children}</Link>
		</MDBDropdownItem>
	);
};

DropdownItem.propTypes = {
	onClick: PropTypes.func,
	icon: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
	children: PropTypes.string.isRequired
};

DropdownItem.defaultProps = {
	onClick: () => {}
};

export default DropdownItem;
