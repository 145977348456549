import React, { Component } from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDiv = styled.div`
	display: flex;
	flex-direction: column;
	@media (min-width: 576px) {
		display: block;
	}
`;

class SelectUserType extends Component {
	static propTypes = {
		setFormType: PropTypes.func.isRequired
	};

	state = {
		type: ''
	};

	selectUserType = e => {
		this.setState({
			type: e.target.name
		});
	};

	render() {
		const { type } = this.state;
		return (
			<div>
				<h4>Which type of user do you want to create?</h4>
				<StyledDiv className="text-center my-4">
					<MDBBtn
						size="md"
						active={type === 'company'}
						name="company"
						onClick={this.selectUserType}
					>
						Company
					</MDBBtn>
					<MDBBtn
						size="md"
						active={type === 'customer'}
						name="customer"
						onClick={this.selectUserType}
					>
						Customer
					</MDBBtn>
					<MDBBtn size="md" active={type === 'admin'} name="admin" onClick={this.selectUserType}>
						Admin
					</MDBBtn>
				</StyledDiv>
				{type && (
					<div className="text-right">
						<MDBBtn size="md" onClick={() => this.props.setFormType(type)}>
							Continue
							{'   '}
							<MDBIcon icon="arrow-right" />
						</MDBBtn>
					</div>
				)}
			</div>
		);
	}
}

export default SelectUserType;
