import Methods from './Methods';

class EstimateAPI extends Methods {
	get(id = '', params) {
		return super.get(`/estimate/${id}`, params);
	}

	create(data) {
		return super.post('/estimate', data);
	}

	update(id, data) {
		return super.put(`/estimate/${id}`, data);
	}
}

const estimateAPI = new EstimateAPI();

export default estimateAPI;
