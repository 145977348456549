import React from 'react';
import { connect } from 'react-redux';

import Summary from './SummaryView';
import { setCart } from '../../../redux/actions';

const SummaryContainer = props => <Summary {...props} />;

const mapStateToProps = ({ userDetails: { user } }, ownProps) => {
	const hasState = !!ownProps.location.state;
	const state = ownProps.location.state || {};
	const products = state.products;
	const eventDetails = state.eventDetails;
	return {
		hasState,
		user,
		products,
		eventDetails
	};
};

export default connect(
	mapStateToProps,
	{ setCart }
)(SummaryContainer);
