import React, { Component } from 'react';
import { MDBCard, MDBCardBody, MDBBtn, MDBCollapse } from 'mdbreact';
import PropTypes from 'prop-types';

import { BackButton, Form } from 'components';
import { eventDetails, willCallDetails } from './formData';

class EventForm extends Component {
	static propTypes = {
		user: PropTypes.object,
		eventDetails: PropTypes.object,
		products: PropTypes.array,
		history: PropTypes.object,
		location: PropTypes.object
	};

	state = {
		collapse: false,
		eventDetails: {
			customerName: this.props.user.ownCompany,
			groupName: '',
			eventDate: '',
			location: '',
			operationsManager: '',
			eventStartTime: '',
			eventEndTime: '',
			room: '',
			commentsOnEvent: '',
			loadInTime: '',
			setByTime: '',
			strikeTime: '',
			commentsOnSetup: '',
			willCall: {
				willCallCustomerName: this.props.user.ownCompany,
				willCallPickupDate: '',
				willCallPickupTime: '',
				willCallReturnDate: '',
				willCallReturnTime: ''
			}
		}
	};

	componentDidMount() {
		const { eventDetails } = this.props;
		if (eventDetails && eventDetails) {
			this.setState({
				eventDetails: {
					...eventDetails
				}
			});
		}
	}

	submitHandler = () => {
		this.props.history.push({
			pathname: '/checkout/summary',
			state: {
				eventDetails: this.state.eventDetails,
				products: this.props.products
			}
		});
	};

	handleChange = ({ target: { name, value } }) => {
		if (name.includes('willCall')) {
			this.setState({
				eventDetails: {
					...this.state.eventDetails,
					willCall: {
						...this.state.eventDetails.willCall,
						[name]: value
					}
				}
			});
		} else {
			this.setState({
				eventDetails: {
					...this.state.eventDetails,
					[name]: value
				}
			});
		}
	};

	toggle = () => this.setState({ collapse: !this.state.collapse });

	render() {
		const { state, toggle, handleChange, submitHandler } = this;
		return (
			<>
				<BackButton to="/checkout/cart" state={this.props.location.state} />
				<MDBCard>
					<MDBCardBody>
						<div className="text-center">
							<h4 className="mb-3">Event Details</h4>
						</div>
						<Form
							columns={3}
							btnSize="md"
							btnColor="default"
							btnIcon="arrow-right"
							btnText="Go To Summary"
							onChange={handleChange}
							onSubmit={submitHandler}
							inputs={eventDetails(state.eventDetails)}
						/>
						<div className="text-center">
							<MDBBtn size="md" onClick={toggle}>
								Will Call Order
							</MDBBtn>
							<MDBCollapse className="mt-3" isOpen={state.collapse}>
								<Form
									columns={2}
									hideBtn={true}
									autoFocus={false}
									onChange={handleChange}
									inputs={willCallDetails(state.eventDetails.willCall)}
								/>
							</MDBCollapse>
						</div>
					</MDBCardBody>
				</MDBCard>
			</>
		);
	}
}

export default EventForm;
