import React from 'react';
import PropTypes from 'prop-types';

import { ErrorMsg, ContentMessage } from 'components';

const Loading = ({
	loading,
	error,
	children,
	notFound,
	notFoundIcon,
	notFoundClassName,
	notFoundSize,
}) => {
	if (loading) {
		return <div className="loader" />;
	}

	if (error) {
		return <ErrorMsg message={error} />;
	}

	if (notFound) {
		return (
			<ContentMessage
				message="Nothing was found"
				icon={notFoundIcon}
				size={notFoundSize}
				className={notFoundClassName}
			/>
		);
	}

	return children;
};

Loading.propTypes = {
	loading: PropTypes.bool,
	error: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
	notFoundSize: PropTypes.string,
	notFoundIcon: PropTypes.string,
	notFound: PropTypes.bool,
	notFoundClassName: PropTypes.string,
};

Loading.defaultProps = {
	loading: false,
	notFoundSize: '3x',
	notFoundIcon: 'list-ul',
	notFoundClassName: 'default-icon-color',
};

export default Loading;
