import React, { Component } from 'react';
import { MDBModalBody, MDBIcon, MDBBtn, MDBModal, MDBModalHeader } from 'mdbreact';
import PropTypes from 'prop-types';

import { Alert, ContentMessage } from 'components';
import { userAPI } from 'api';

class CreateUserModal extends Component {
	static propTypes = {
		show: PropTypes.bool,
		hideModal: PropTypes.func,
		fetchUsers: PropTypes.func,
		userIdToDelete: PropTypes.string
	};

	state = {
		error: '',
		loading: false,
		show: this.props.show
	};

	onDelete = async id => {
		this.setState({ loading: true });
		try {
			await userAPI.delete(id);
			await this.props.fetchUsers();
			this.setState({ loading: false });
			this.onHide();
		} catch (e) {
			this.setState({ error: 'An error occurred while deleting the user', loading: false });
		}
	};

	onHide = () => {
		this.setState({ error: '' });
		this.props.hideModal();
	};

	render() {
		const { error, loading } = this.state;
		const { show, userIdToDelete } = this.props;
		return (
			<MDBModal toggle={this.onHide} isOpen={show} size="md">
				<MDBModalHeader toggle={this.onHide}>Delete user</MDBModalHeader>
				<MDBModalBody>
					<div className="text-center">
						<ContentMessage
							message="This will permanently delete the user from the site. Are you sure?"
							icon="trash-alt"
							size="4x"
							className="red-text"
						/>
						<MDBBtn
							outline
							size="md"
							color="danger"
							disabled={loading}
							onClick={() => this.onDelete(userIdToDelete)}
						>
							{loading ? <MDBIcon icon="spinner" spin /> : 'Yes'}
						</MDBBtn>
						<MDBBtn outline color="primary" disabled={loading} onClick={this.onHide} size="md">
							No
						</MDBBtn>
						<Alert show={error} color="danger" dismiss onDismiss={this.onDismiss}>
							{error}
						</Alert>
					</div>
				</MDBModalBody>
			</MDBModal>
		);
	}
}

export default CreateUserModal;
