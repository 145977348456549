import React from 'react';

import DropdownItem from './DropdownItem';

const AdminDropdown = () => (
	<>
		<DropdownItem icon="users" to="/admin/users">
			Users
		</DropdownItem>
		<DropdownItem icon="archive" to="/admin/orders">
			Orders
		</DropdownItem>
	</>
);

export default AdminDropdown;
