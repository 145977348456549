import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MDBBtn, MDBIcon } from 'mdbreact';

import browserHistory from 'util/general';
import { ContentMessage, Alert } from 'components';
import { productAPI, qboAPI } from 'api';

class DeleteModal extends Component {
	static propTypes = {
		closeModal: PropTypes.func,
		product: PropTypes.object.isRequired
	};

	state = {
		loading: false,
		error: ''
	};

	onDelete = async () => {
		const { product, closeModal } = this.props;
		this.setState({ loading: true });
		try {
			await Promise.all([
				qboAPI.qboUpdateProduct({ Id: product.qboId, SyncToken: product.syncToken, Active: false }),
				productAPI.delete(product.id)
			]);
			closeModal();
			browserHistory.goBack();
		} catch (e) {
			this.setState({ error: 'An error occurred while deleting the product', loading: false });
		}
	};

	onDismiss = () => this.setState({ error: '' });

	render() {
		const { loading, error } = this.state;
		if (loading) {
			return (
				<div className="text-center">
					<MDBIcon size="4x" icon="spinner" spin style={{ color: '#4b515d' }} />
				</div>
			);
		}
		return (
			<div className="text-center">
				<ContentMessage
					message="Are you sure you want to delete this?"
					icon="trash-alt"
					size="4x"
					className="red-text"
				/>
				<MDBBtn onClick={this.onDelete} size="md">
					Yes
				</MDBBtn>
				<MDBBtn onClick={this.props.closeModal} size="md">
					No
				</MDBBtn>
				<Alert show={error} color="danger" dismiss onDismiss={this.onDismiss}>
					{error}
				</Alert>
			</div>
		);
	}
}

export default DeleteModal;
