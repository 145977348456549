import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSelect = styled.select`
	width: 58px !important;
	max-width: 58px !important;
	padding: 0 1.5rem 0 0.5rem !important;
`;

const InventorySelect = ({ name, onChange, quantity, value, startAt, ...props }) => {
	const createSelectItems = value => {
		const items = [];
		let i = startAt;
		while (i <= value) {
			items.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
			i++;
		}
		return items;
	};

	return (
		<Fragment>
			<StyledSelect
				{...props}
				className="browser-default custom-select"
				onChange={onChange}
				name={name}
				value={value}
			>
				{createSelectItems(quantity)}
			</StyledSelect>
		</Fragment>
	);
};

InventorySelect.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func,
	startAt: PropTypes.number,
	quantity: PropTypes.number,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

InventorySelect.defaultProps = {
	startAt: 0
};

export default InventorySelect;
