import React from 'react';
import { MDBTooltip } from 'mdbreact';
import PropTypes from 'prop-types';

const ToolTipConditional = ({ shouldRender, children, placement, tooltipContent }) => {
	return shouldRender ? (
		<div style={{ display: 'inline-block' }}>
			<MDBTooltip domElement placement={placement}>
				<div>{children}</div>
				<div>{tooltipContent}</div>
			</MDBTooltip>
		</div>
	) : (
		children
	);
};

ToolTipConditional.propTypes = {
	shouldRender: PropTypes.bool,
	children: PropTypes.node,
	placement: PropTypes.string,
	tooltipContent: PropTypes.string
};

export default ToolTipConditional;
