import React from 'react';

const Tos = () => {
	return (
		<div>
			<h2 className="text-center mb-3">Privacy Policy & End User License Agreement</h2>
			<p>
				Whereas, LICENSOR, Scottsdale Event Decor LLC, an Arizona Limited Liability Company,
				hereinafter referred to as (“LICENSOR”) develops proprietary computer programs and sells use
				licenses for such proprietary computer programs together with or apart from accompanying
				copyrighted material and documentation and
			</p>
			<p>
				Whereas, End User desires to obtain the benefits thereof and, in return for which, is
				willing to abide by the obligations and fee agreements applicable to LICENSOR’s use licenses
				in LICENSOR’s proprietary computer programs.
			</p>
			<p>
				NOW THEN, for good and valuable consideration, including but not limited to license grant in
				accordance with this Agreement by LICENSOR to End User’s covenant regarding LICENSOR’s
				proprietary rights. LICENSOR agrees to sell End User materials representing LICENSOR’s
				product or products subject to the following terms and conditions:
			</p>
			<p>Witnesseth:</p>
			<h3 className="my-3">Article I. Exclusive Source</h3>
			<p>
				End User shall obtain all LICENSOR authorized product materials through LICENSOR or
				LICENSOR’s authorized Dealer and no other source. LICENSOR authorized product materials
				include, but are not limited to, manuals, license agreements and media upon which company’s
				proprietary computer programs are recorded, except for archival copies, as defined in
				ARTICLE III. ARCHIVAL COPIES of this Agreement. End User shall make no copies of any kind of
				any of the materials furnished by LICENSOR or LICENSOR’s authorized Dealer, unless
				specifically authorized to do so in writing signed by an officer of LICENSOR.
			</p>
			<h3 className="my-3">Article II. Proprietary Rights of Licensor</h3>
			<p>
				End User agrees that LICENSOR retains exclusive ownership of the trademarks represented by
				its company name and logo, and all of the documentation and computer recorded data related
				thereto. End User also agrees that all techniques, algorithms, and processes contained in
				LICENSOR’s computer program products or any modification or extraction thereof constitute
				TRADE SECRETS OF LICENSOR and will be safeguarded by End User, but in no event shall End
				User exercise less than due diligence and care in accordance with the laws of the country of
				purchase and International Law, whichever operates to best protect the interests of
				LICENSOR. End User shall not copy, reproduce, re-manufacture or in any way duplicate all or
				any part of LICENSOR products WHETHER MODIFIED OR TRANSLATED INTO ANOTHER LANGUAGE OR NOT,
				or in any documentation, or in any other material provided by LICENSOR in association with
				LICENSOR’s computer program products except as specified in this Agreement and in accordance
				with the terms and conditions of this Agreement which remain in force. End User agrees that
				unauthorized distributing, copying, duplications, or otherwise reproducing ALL OR ANY PART
				OR TRANSLATED PART of the computer program products provided by LICENSOR or the failure to
				protect such computer programs will actually and materially damage LICENSOR. End User agrees
				that in the event End User breaches this agreement, End User will be liable for damages as
				may be determined by a court of competent jurisdiction.
			</p>
			<h3 className="my-3">Article III. Archival Copies</h3>
			<p>
				End User may make archival copies of those portions of LICENSOR’s product(s) that are
				provided on a machine readable media, provided such copies are for the End User’s personal
				use on one microcomputer and that no more than one such copy is used at any time unless End
				User has paid for multiple copy use as described in ARTICLE IV of this Agreement.
			</p>
			<h3 className="my-3">Article IV. Multiple Copy Use</h3>
			<p>
				LICENSOR use licenses are applicable to a single microcomputer installation in the event End
				User intends to use a LICENSOR product or any part thereof on more than one microcomputer,
				the license fee for each such multiple use must be purchased. Quantity discounts in
				accordance with LICENSOR policy will apply. In the event of simultaneous use, a license must
				be obtained for each instance of possible simultaneous execution.
			</p>
			<h3 className="my-3">Article V. Transfer of End User License</h3>
			<p>
				In the event End User wishes to transfer the rights granted by this Agreement, retaining
				none thereby, a letter requesting such transfer accompanied by the original diskette or
				label therefrom, must be sent to LICENSOR. LICENSOR will then select the most appropriate
				method for distribution of the transferred rights.
			</p>
			<h3 className="my-3">Article VI. Limited Warranty Policy</h3>
			<p>
				LICENSOR warrants that all materials furnished by LICENSOR constitute an accurate
				manufacture of LICENSOR products and will replace any such LICENSOR furnished material to be
				thus defective, provided such defect is found within ten days of purchase by End User.
				However, LICENSOR makes NO express or implied warranty of any kind with regard to
				performance or accuracy of data of any kind nor for any consequential damages resulting
				therefrom whether through loss or inaccuracy of data of any kind nor for any consequential
				damages resulting therefrom whether through LICENSOR negligence or not. LICENSOR will not
				honor any warranty where a LICENSOR product has been subjected to physical abuse or used in
				defective or non-compatible equipment. LICENSOR will not honor any warranty for which there
				is no signed End User Agreement Acknowledgment on file at LICENSOR offices. The products
				provided are intended for commercial use only.
			</p>
			<h3 className="my-3">Article VII. Update Policy</h3>
			<p>
				LICENSOR may, from time to time, revise the performance of its products and in doing so,
				incur NO obligation to furnish such revisions to any LICENSOR customer. At LICENSOR’s
				option, LICENSOR may provide such revisions to its Dealers. Also, at LICENSOR’s option,
				LICENSOR may provide its dealers and End Users with a revision newsletter from time to time.
				(No update or customer service or newsletter distribution will be provided unless a properly
				signed End User Agreement Acknowledgment is on file at LICENSOR offices.)
			</p>
			<h3 className="my-3">Article VIII. Customer Service</h3>
			<p>
				It is LICENSOR’s customary practice to provide reasonable assistance and support in the use
				of its products to its customers through its dealer network. End Users therefore are
				expected to obtain customer service from their Dealer.
			</p>
			<h3 className="my-3">Article IX. Termination of End User License</h3>
			<p>
				If any one or more of the provisions of this Agreement is breached, the license granted by
				this Agreement is hereby terminated. Nevertheless, in the event of such termination, all the
				provisions of this Agreement which operate to protect the rights of LICENSOR shall continue
				in force.
			</p>
			<h3 className="my-3">Article X. Injunction Relief</h3>
			<p>
				It is understood and agreed that, notwithstanding any other provisions of this Agreement,
				LICENSOR has the unequivocal right to obtain timely injunctive relief to protect the
				proprietary rights of LICENSOR.
			</p>
			<h3 className="my-3">Article XI. Governing Law</h3>
			<p>
				When signed in the United States, this Agreement shall be interpreted in accordance with the
				laws of the State of Arizona. When signed in any other country, this agreement will be
				interpreted in accordance with International Law. In the event any part of this Agreement is
				invalidated by court or legislative action of competent jurisdiction, the remainder of this
				Agreement shall remain in binding effect.
			</p>
			<h3 className="my-3">Article XII. Legal Fees</h3>
			<p>
				In the event of legal action brought by either party, the prevailing party shall be entitled
				to reimbursement of legal fees as set by court action
			</p>
			<h3 className="my-3">Article XIII. Entire Agreement</h3>
			<p>
				This Agreement constitutes the entire agreement between the parties and supersedes any prior
				agreements. This Agreement may only be changed by mutual written consent.
			</p>
			<h3 className="my-3">Article XIV. End User Agreement Acknowledgment</h3>
			<p>
				By checking the I AGREE BOX, the End User hereby accepts all the terms and conditions of
				this Agreement without exception, deletion or alteration. End User recognizes that any use
				of LICENSOR products without checking the I AGREE BOX will be considered a breach of
				contract, subject to liquidated damages and otherwise unlawful and an unauthorized use of
				LICENSOR’s trade secrets and proprietary products.
			</p>
		</div>
	);
};

export default Tos;
