import axios from 'axios';

import { trimPostData } from 'util/general';

/**
 * @param {string} method HTTP Method
 * @param {string} url API Endpoint
 * @param {object} params Additional request parameters
 * @param {object} data Data to create a resource
 * @param {object} headers Custom headers
 */
export default (method, url, data, params = {}, headers = {}) =>
	axios({
		method,
		url,
		params,
		headers,
		responseType: 'json',
		data: trimPostData(data),
	});

axios.interceptors.response.use(
	(config) => {
		const { headers: { access_token, refresh_token, realm_id, token } = {} } = config;
		if (token) {
			sessionStorage.setItem('token', token);
		}
		if (realm_id) {
			sessionStorage.setItem('realm_id', realm_id);
		}
		if (access_token) {
			sessionStorage.setItem('access_token', access_token);
			sessionStorage.setItem('refresh_token', refresh_token);
		}
		return {
			...config,
		};
	},
	(error) => {
		if (error.code === 'ECONNABORTED') {
			error.response = {
				data: { message: 'The server did not respond and the request timed out' },
				headers: {},
				request: {},
				status: 408,
				statusText: 'ECONNABORTED',
			};
		}
		if (!error.response) {
			error.response = {
				data: { message: 'An error occurred during the request' },
				headers: {},
				request: {},
				status: 500,
			};
		}
		return Promise.reject(error);
	}
);

axios.interceptors.request.use(
	(config) => {
		let headers = {};
		const defaultHeaders = {
			Authorization: 'Bearer ' + sessionStorage.getItem('token'),
			access_token: sessionStorage.getItem('access_token'),
			realm_id: sessionStorage.getItem('realm_id'),
			refresh_token: sessionStorage.getItem('refresh_token'),
		};
		if (!config.url.includes('.s3')) {
			headers = defaultHeaders;
		}
		return {
			...config,
			timeout: 15000,
			headers: {
				...config.headers,
				...headers,
			},
		};
	},
	(error) => Promise.reject(error)
);
