import styled from 'styled-components';

const StyledModal = styled.div`
	color: black;
	display: inline;
	> span {
		cursor: pointer;
		color: white;
	}
`;

export default StyledModal;
