import Methods from './Methods';

class CompanyAPI extends Methods {
	getCompany(id = '', params) {
		return super.get(`/company/${id}`, params);
	}

	createCompany(data) {
		return super.post('/company', data);
	}

	updateCompany(id, data) {
		return super.put(`/company/${id}`, data);
	}

	deleteCompany(id) {
		return super.delete(`/company/${id}`);
	}
}

const companyAPI = new CompanyAPI();

export default companyAPI;
