import { MDBInput } from 'mdbreact';

export const eventDetailsCol1 = values => {
	return [
		{
			size: 'sm',
			type: 'text',
			required: true,
			disabled: true,
			className: 'h-25 push-label-up',
			id: 'customerName',
			name: 'customerName',
			label: 'Customer Name',
			inputType: MDBInput,
			value: values.customerName
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.groupName,
			label: 'Group Name (required)',
			name: 'groupName',
			id: 'groupName',
			type: 'text',
			required: true,
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.eventDate,
			label: 'Date of Event (required)',
			name: 'eventDate',
			id: 'eventDate',
			type: 'date',
			required: true,
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.location,
			label: 'Location (required)',
			name: 'location',
			id: 'location',
			type: 'text',
			required: true,
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.operationsManager,
			label: "Operation's Manager",
			name: 'operationsManager',
			id: 'operationsManager',
			type: 'text',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.eventStartTime,
			label: 'Start Time of Event',
			name: 'eventStartTime',
			id: 'eventStartTime',
			type: 'time',
			className: 'h-25 push-label-up'
		}
	];
};

export const eventDetailsCol2 = values => {
	return [
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.eventEndTime,
			label: 'End Time of Event',
			name: 'eventEndTime',
			id: 'eventEndTime',
			type: 'time',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.room,
			label: 'Venue/Room',
			name: 'room',
			id: 'room',
			type: 'text',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.loadInTime,
			label: 'Load-in Time',
			name: 'loadInTime',
			id: 'loadInTime',
			type: 'time',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.setByTime,
			label: 'Set-by Time',
			name: 'setByTime',
			id: 'setByTime',
			type: 'time',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.strikeTime,
			label: 'Strike Time',
			name: 'strikeTime',
			id: 'strikeTime',
			type: 'time',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.commentsOnSetup,
			label: 'Set/Strike Comments/Notes',
			name: 'commentsOnSetup',
			id: 'commentsOnSetup',
			type: 'textarea',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.commentsOnEvent,
			label: 'Location Comments/Notes',
			name: 'commentsOnEvent',
			id: 'commentsOnEvent',
			type: 'textarea',
			className: 'h-25 push-label-up'
		}
	];
};

// TODO: remove this and use the above functions
export const eventDetails = values => {
	return [
		{
			size: 'sm',
			type: 'text',
			required: true,
			disabled: true,
			className: 'h-25 push-label-up',
			id: 'customerName',
			name: 'customerName',
			label: 'Customer Name',
			inputType: MDBInput,
			value: values.customerName
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.groupName,
			label: 'Group Name (required)',
			name: 'groupName',
			id: 'groupName',
			type: 'text',
			required: true,
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.eventDate,
			label: 'Date of Event (required)',
			name: 'eventDate',
			id: 'eventDate',
			type: 'date',
			required: true,
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.location,
			label: 'Location (required)',
			name: 'location',
			id: 'location',
			type: 'text',
			required: true,
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.operationsManager,
			label: "Operation's Manager",
			name: 'operationsManager',
			id: 'operationsManager',
			type: 'text',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.eventStartTime,
			label: 'Start Time of Event',
			name: 'eventStartTime',
			id: 'eventStartTime',
			type: 'time',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.eventEndTime,
			label: 'End Time of Event',
			name: 'eventEndTime',
			id: 'eventEndTime',
			type: 'time',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.room,
			label: 'Venue/Room',
			name: 'room',
			id: 'room',
			type: 'text',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.loadInTime,
			label: 'Load-in Time',
			name: 'loadInTime',
			id: 'loadInTime',
			type: 'time',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.setByTime,
			label: 'Set-by Time',
			name: 'setByTime',
			id: 'setByTime',
			type: 'time',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.strikeTime,
			label: 'Strike Time',
			name: 'strikeTime',
			id: 'strikeTime',
			type: 'time',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.commentsOnSetup,
			label: 'Set/Strike Comments/Notes',
			name: 'commentsOnSetup',
			id: 'commentsOnSetup',
			type: 'textarea',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.commentsOnEvent,
			label: 'Location Comments/Notes',
			name: 'commentsOnEvent',
			id: 'commentsOnEvent',
			type: 'textarea',
			className: 'h-25 push-label-up'
		}
	];
};

export const willCallDetails = values => {
	return [
		{
			disabled: true,
			size: 'sm',
			inputType: MDBInput,
			value: values.willCallCustomerName,
			label: 'Customer Name',
			name: 'willCallCustomerName',
			id: 'willCallCustomerName',
			type: 'text',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.willCallPickupDate,
			label: 'Pick Up Date',
			name: 'willCallPickupDate',
			id: 'willCallPickupDate',
			type: 'date',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.willCallPickupTime,
			label: 'Pick Up Time',
			name: 'willCallPickupTime',
			id: 'willCallPickupTime',
			type: 'time',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.willCallReturnDate,
			label: 'Return Date',
			name: 'willCallReturnDate',
			id: 'willCallReturnDate',
			type: 'date',
			className: 'h-25 push-label-up'
		},
		{
			size: 'sm',
			inputType: MDBInput,
			value: values.willCallReturnTime,
			label: 'willCallReturnTime',
			name: 'willCallReturnTime',
			id: 'willCallReturnTime',
			type: 'time',
			className: 'h-25 push-label-up'
		}
	];
};
