import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { MDBBtn } from 'mdbreact';
import styled from 'styled-components';

import { Alert } from 'components';
import { saveProduct } from '../requests';
import AddProductBtn from '../AddProductBtn';
import { selectPrimaryImg } from '../helpers';

const StyledImg = styled.img`
	cursor: pointer;
	width: 100% !important;
	max-width: 300px !important;
	max-height: 400px !important;
	-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
`;

class ProductCard extends PureComponent {
	static propTypes = {
		user: PropTypes.object,
		product: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		fetchCartTotal: PropTypes.func.isRequired,
	};

	state = {
		selectedQuantity: 0,
		success: false,
		error: false,
	};

	onChange = (e) => {
		this.setState({ selectedQuantity: parseInt(e.target.value) });
	};

	viewShowPage = (productId) => () => {
		this.props.history.push({
			pathname: `${this.props.location.pathname}/${productId}`,
		});
	};

	saveToCart = () => {
		const { user, product } = this.props;
		const cart = user ? user.cart : {};
		const { selectedQuantity } = this.state;
		if (selectedQuantity > 0 && selectedQuantity <= product.quantity) {
			saveProduct(user, product, selectedQuantity)
				.then(() => this.props.fetchCartTotal(cart.id))
				.then(() => this.setState({ success: true, error: false }))
				.catch(() => this.setState({ error: true, success: false }));
		}
	};

	onDismiss = () => {
		this.setState({ success: false, error: false });
	};

	render() {
		const { product } = this.props;
		const { id, name, Images } = product;
		const permissions = this.props.user && this.props.user.permissions;
		return (
			<div className="row mb-3 text-center text-md-left animated fadeInUpBig">
				<div className="col-md-5 mb-3 mb-sm-3">
					<StyledImg
						onClick={this.viewShowPage(id)}
						className="img-fluid"
						src={selectPrimaryImg(Images)}
						alt={name}
					/>
				</div>
				<div className="col-md-7 border-bottom pb-3 pb-sm-3">
					<h3 className="mb-2">{name}</h3>
					<AddProductBtn
						permissions={permissions}
						product={product}
						onChange={this.onChange}
						saveToCart={this.saveToCart}
						selectedQuantity={this.state.selectedQuantity}
					/>
					<MDBBtn size="md" onClick={this.viewShowPage(id)}>
						More Info
					</MDBBtn>
					<Alert
						show={this.state.success}
						className="mt-2 w-50"
						color="success"
						onDismiss={this.onDismiss}
						dismiss
					>
						Success!
					</Alert>
					<Alert
						show={this.state.error}
						className="mt-2 w-50"
						color="danger"
						onDismiss={this.onDismiss}
						dismiss
					>
						an error occurred
					</Alert>
				</div>
			</div>
		);
	}
}

export default ProductCard;
