import React, { useState } from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact';
import PropTypes from 'prop-types';

const SelectUserType = props => {
	const [type, setType] = useState('');
	const setFormType = type => () => setType(type);

	return (
		<div>
			<h4>What would you like to create?</h4>
			<div className="text-center my-4">
				<MDBBtn
					size="md"
					active={type === 'category'}
					name="categoryBtn"
					onClick={setFormType('category')}
				>
					Category
				</MDBBtn>
				<MDBBtn
					size="md"
					active={type === 'product'}
					name="productBtn"
					onClick={setFormType('product')}
				>
					Product
				</MDBBtn>
			</div>
			{type && (
				<div className="text-right">
					<MDBBtn size="md" onClick={() => props.setFormType(type)}>
						Continue
						{'   '}
						<MDBIcon icon="arrow-right" />
					</MDBBtn>
				</div>
			)}
		</div>
	);
};

SelectUserType.propTypes = {
	setFormType: PropTypes.func.isRequired
};

export default SelectUserType;
