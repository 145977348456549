import { MDBInput } from 'mdbreact';

import { SelectBox } from 'components';

/**
 *
 * @param {object} values from state to map to form inputs
 * @description All inputs must have an id, name, and inputType, which is a component
 * add data-error-msg for a custom error message for that input type
 */

export const companyForm = values => {
	return [
		{
			inputType: MDBInput,
			value: values.name,
			id: 'company',
			name: 'company',
			label: 'Company',
			group: true,
			required: true,
			type: 'text'
		},
		{
			inputType: MDBInput,
			value: values.zipCode,
			id: 'zipCode',
			name: 'zipCode',
			label: 'Zip Code',
			group: true,
			required: true,
			type: 'text'
		},
		{
			inputType: MDBInput,
			value: values.street,
			id: 'street',
			name: 'street',
			label: 'Street',
			group: true,
			required: true,
			type: 'text'
		},
		{
			inputType: MDBInput,
			value: values.city,
			id: 'city',
			name: 'city',
			label: 'City',
			group: true,
			required: true,
			type: 'text'
		},
		{
			inputType: MDBInput,
			value: values.state,
			id: 'state',
			name: 'state',
			label: 'State',
			group: true,
			required: true,
			type: 'text'
		},
		{
			inputType: MDBInput,
			value: values.phone,
			id: 'phone',
			name: 'phone',
			label: 'Phone',
			group: true,
			type: 'text'
		}
	];
};

export const subCustomerForm = (values, companies) => {
	return [
		{
			inputType: SelectBox,
			id: 'CompanyId',
			name: 'CompanyId',
			required: true,
			'data-error-msg': 'Please select a parent company',
			options: [
				{
					option: 'Choose a parent company',
					value: ''
				},
				...companies.map(company => ({
					option: company.name,
					value: `${company.qboId} ${company.id} ${company.displayName}`
				}))
			]
		},
		{
			inputType: MDBInput,
			value: values.company,
			id: 'company',
			name: 'company',
			label: 'Company',
			group: true,
			required: true,
			type: 'text'
		},
		{
			inputType: MDBInput,
			value: values.displayName,
			id: 'displayName',
			name: 'displayName',
			label: 'Display Name',
			group: true,
			required: true,
			type: 'text'
		},
		{
			inputType: MDBInput,
			value: values.email,
			id: 'email',
			name: 'email',
			label: 'Email',
			group: true,
			required: true,
			'data-error-msg': 'Please provide a valid email address',
			type: 'email'
		},
		{
			inputType: MDBInput,
			value: values.firstName,
			id: 'firstName',
			name: 'firstName',
			label: 'First Name',
			group: true,
			required: true,
			type: 'text'
		},
		{
			inputType: MDBInput,
			value: values.lastName,
			id: 'lastName',
			name: 'lastName',
			label: 'Last Name',
			group: true,
			required: true,
			type: 'text'
		},
		{
			inputType: MDBInput,
			value: values.phone,
			id: 'phone',
			name: 'phone',
			label: 'Phone',
			group: true,
			required: true,
			type: 'text'
		}
	];
};

export const adminForm = values => {
	return [
		{
			inputType: MDBInput,
			value: values.email,
			id: 'email',
			name: 'email',
			label: 'Email',
			group: true,
			required: true,
			'data-error-msg': 'Please provide a valid email address',
			type: 'email'
		},
		{
			inputType: MDBInput,
			value: values.firstName,
			id: 'firstName',
			name: 'firstName',
			label: 'First Name',
			group: true,
			required: true,
			type: 'text'
		},
		{
			inputType: MDBInput,
			value: values.lastName,
			id: 'lastName',
			name: 'lastName',
			label: 'Last Name',
			group: true,
			required: true,
			type: 'text'
		},
		{
			inputType: MDBInput,
			value: values.phone,
			id: 'phone',
			name: 'phone',
			label: 'Phone',
			group: true,
			type: 'text'
		}
	];
};
