import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBRow, MDBCard, MDBCardBody, MDBCardTitle, MDBInput, MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';
import axios from 'axios';

import { userAPI } from 'api';
import { Alert, Form } from 'components';
import { formData } from './formData';

class Profile extends Component {
	static propTypes = {
		user: PropTypes.object,
	};

	state = {
		password: '',
		password2: '',
		error: false,
		success: false,
		loading: false,
		trucking: {
			value: 0,
		},
		setTruckingSuccess: false,
	};

	componentDidMount() {
		if (
			(this.props.user && this.props.user.permissions === 'admin') ||
			this.props.user.permissions === 'superadmin'
		) {
			axios
				.get('/trucking')
				.then((res) => {
					const data = res.data.result || {};
					this.setState({ trucking: data });
				})
				.catch((err) => {
					this.setState({ error: `An error occurred while fetching the trucking fee: ${err}` });
				});
		}
	}

	onChange = (e) => this.setState({ [e.target.name]: e.target.value });

	onSubmit = () => {
		const { password, password2 } = this.state;

		if (password.trim() !== password2.trim()) {
			this.setState({
				error: 'Password does not match',
			});
			return;
		}
		this.setState({ loading: true });
		userAPI
			.update(this.props.user.id, { password: password.trim() })
			.then(() => this.setState({ success: true, loading: false }))
			.catch(() =>
				this.setState({ error: 'An error occurred while updating your profile', loading: false })
			);
	};

	onSubmitTruckingFee = () => {
		axios
			.put(`/trucking/${this.state.trucking.id}`, { value: this.state.trucking.value })
			.then(() => {
				this.setState({ setTruckingSuccess: true });
			})
			.catch(() => {
				this.setState({ error: 'An error occurred while changing the trucking fee' });
			});
	};

	onDismiss = () =>
		this.setState({
			password: '',
			password2: '',
			success: false,
			error: false,
			setTruckingSuccess: false,
		});

	render() {
		const {
			onDismiss,
			state: { success, error },
			props: { user },
		} = this;
		return (
			<MDBRow>
				<div className="col-lg-8 offset-lg-2">
					<MDBCard className="mx-0">
						<MDBCardBody>
							<MDBCardTitle>{`${user.firstName} ${user.lastName}`}</MDBCardTitle>
							<p>{user.email}</p>
							<h4>Change Password</h4>
							<Form
								onChange={this.onChange}
								onSubmit={this.onSubmit}
								loading={this.state.loading}
								inputs={formData(this.state)}
							/>
							<Alert show={success} className="mt-2" color="success" dismiss onDismiss={onDismiss}>
								Your password has been updated
							</Alert>
							<Alert show={error} className="mt-2" color="danger" dismiss onDismiss={onDismiss}>
								{error}
							</Alert>
						</MDBCardBody>
						{user.permissions === 'admin' ||
							(user.permissions === 'superadmin' && (
								<MDBCardBody>
									<form>
										<Alert
											show={this.state.setTruckingSuccess}
											className="mt-2"
											color="success"
											dismiss
											onDismiss={onDismiss}
										>
											The trucking fee has been changed
										</Alert>
										<MDBCardTitle>Change Trucking Fee</MDBCardTitle>
										<MDBInput
											value={this.state.trucking.value}
											onChange={(e) => {
												this.setState((state) => ({
													trucking: {
														...state.trucking,
														value: e.target.value,
													},
												}));
											}}
											type="number"
											label="Trucking Fee"
											id="trucking-fee"
										/>
										<div className="text-right">
											<MDBBtn size="md" type="button" onClick={this.onSubmitTruckingFee}>
												Submit
											</MDBBtn>
										</div>
									</form>
								</MDBCardBody>
							))}
					</MDBCard>
				</div>
			</MDBRow>
		);
	}
}

const mapStateToProps = ({ userDetails: { user } }) => ({
	user,
});

export default connect(mapStateToProps)(Profile);
