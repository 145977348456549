import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Loading, EmptyCart } from 'components';
import { cartAPI } from 'api';
import CartView from '../../checkout/cartView/CartView';

class CartContainer extends Component {
	static propTypes = {
		user: PropTypes.object,
		match: PropTypes.object
	};

	state = {
		cart: null,
		error: '',
		loading: true
	};

	componentDidMount() {
		if (this.props.user) {
			cartAPI
				.getCartProducts(this.props.match.params.id)
				.then(res => {
					this.setState({ cart: res.data.result, loading: false, error: false });
				})
				.catch(() =>
					this.setState({ error: 'Something went wrong while loading the cart', loading: false })
				);
		}
	}

	render() {
		const { loading, error } = this.state;

		if (this.state.cart && this.state.cart.length && !this.state.cart[0].CartProducts.length) {
			return <EmptyCart />;
		}

		return (
			<Loading loading={loading} error={error}>
				<CartView
					{...this.props}
					cart={this.state.cart && this.state.cart[0]}
					products={this.state.cart && this.state.cart[0].CartProducts}
				/>
			</Loading>
		);
	}
}

const mapStateToProps = ({ userDetails: { user } }) => {
	return {
		user
	};
};

export default connect(mapStateToProps)(CartContainer);
