import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import Home from '../home/Home';
import ProductsContainer from './productList/ProductsListContainer';
import ShowPage from './showPage/ShowPageContainer';

const Routes = ({ match: { path } }) => {
	return (
		<Switch>
			<Route exact path={`${path}/:category/:subcategory`} component={ProductsContainer} />
			<Route exact path={`${path}/:category/:subcategory/:productId`} component={ShowPage} />
			<Route path="/inventory" component={Home} />
		</Switch>
	);
};

Routes.propTypes = {
	match: PropTypes.object.isRequired
};

export default Routes;
