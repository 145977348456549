import { MDBInput } from 'mdbreact';

export const formData = data => [
	{
		inputType: MDBInput,
		value: data.password,
		id: 'password',
		name: 'password',
		label: 'Password',
		group: true,
		required: true,
		type: 'password',
		minLength: 3,
		'data-error-msg': 'Password must be at least 3 characters'
	},
	{
		inputType: MDBInput,
		value: data.password2,
		id: 'loginPasword',
		name: 'password2',
		label: 'Confirm Password',
		group: true,
		required: true,
		type: 'password',
		minLength: 3,
		'data-error-msg': 'Password must be at least 3 characters'
	}
];
