import Methods from './Methods';

class ProductAPI extends Methods {
	// GET
	fetch(id = '', params) {
		return super.get(`/product/${id}`, params);
	}

	fetchImages(id, params) {
		return super.get(`/product/${id}/image`, params);
	}

	fetchCategories() {
		return super.get('/categories');
	}

	// POST

	create(data) {
		return super.post('/product', data);
	}

	presignedUrl(id, data) {
		return super.post(`/product/${id}/presignedUrl`, data);
	}

	saveImg(id, data) {
		return super.post(`/product/${id}/image`, data);
	}

	// PUT

	update(id, data) {
		return super.put(`/product/${id}`, data);
	}

	uploadImageToAWS({ url, file }) {
		return super.put(url, file, { 'Content-Type': file.type });
	}

	updateImg(id, imageId, data) {
		return super.put(`/product/${id}/image/${imageId}`, data);
	}

	// DELETE

	delete(id) {
		return super.delete(`/product/${id}`);
	}

	deleteSingleImage(productId, imageId) {
		return super.delete(`/product/${productId}/image/${imageId}`);
	}
}

const productAPI = new ProductAPI();

export default productAPI;
