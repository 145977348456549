import React, { Component } from 'react';
import { MDBBtn, MDBIcon, MDBCard, MDBInput } from 'mdbreact';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BackButton, Table, Alert, EmptyCart, CalculateTotal } from 'components';
import { cartAPI } from 'api';
import { tableData } from './tableData';

const StyledInput = styled(MDBInput)`
	width: 40%;
	@media (min-width: 768px) {
		width: 25%;
	}
`;

const errorMsg = 'there was an error while updating the cart';

class CartView extends Component {
	static propTypes = {
		activeCartId: PropTypes.string,
		cartName: PropTypes.string,
		cart: PropTypes.object,
		products: PropTypes.array,
		history: PropTypes.object,
		location: PropTypes.object,
		match: PropTypes.object,
		fetchCartProducts: PropTypes.func,
	};

	state = {
		error: false,
		success: false,
		products: this.props.products,
		cartName: this.props.cart.cartName,
	};

	nameCart = ({ target: { value } }) => {
		this.setState({ cartName: value });
		cartAPI.updateCart(this.props.cart.id, { cartName: value }).catch(() =>
			this.setState({
				error: errorMsg,
			})
		);
	};

	// used to update the quantity to checkout
	onChange = ({ target }) => {
		const productId = target.getAttribute('data-id');
		const price = target.getAttribute('data-price');
		const value = target.value;
		const productToUpdate = {
			qty: value,
			price: parseInt(value * price),
		};
		cartAPI
			.saveCartProduct(this.props.cart.id, productId, productToUpdate)
			.then(() => {
				// updates the appropriate object with the new quantity and price
				const updated = this.state.products.map((product) => {
					if (product.id === productId) {
						if (value > 0 && value <= product.quantity) {
							product.qty = value;
							product.total = Number(product.price) * Number(value);
						}
					}
					return product;
				});
				this.setState({ products: updated, error: '' });
			})
			.catch(() => this.setState({ error: errorMsg }));
	};

	deleteProduct = (product) => () => {
		cartAPI
			.deleteCartProduct(product.CartProductId)
			.then(() => {
				const products = this.state.products.filter((x) => x.name !== product.name);
				this.setState({ products });
			})
			.catch(() => this.setState({ error: errorMsg }));
	};

	onDismiss = () => this.setState({ error: '' });

	sumTotal = () => {
		const total = this.state.products.reduce(
			(total, currentValue) => (total += Number(currentValue.total)),
			0
		);
		return total;
	};

	render() {
		const { location } = this.props;
		const { products, error } = this.state;
		const total = this.sumTotal();
		if (products && !products.length) return <EmptyCart />;
		return (
			<>
				<BackButton to="/" />
				<MDBCard className="p-3">
					<Alert show={error} color="danger" className="mb-4">
						{error}
					</Alert>
					<StyledInput
						value={this.state.cartName}
						label="Cart Name"
						name="cartName"
						onChange={this.nameCart}
						group
						size="sm"
						type="text"
					/>
					<Table data={tableData(products, this.onChange, this.deleteProduct)} />
					<CalculateTotal total={total} />
					<div className="text-right mt-2">
						{this.props.cart.isActive && (
							<Link
								className="text-white"
								to={{
									pathname: '/checkout/event',
									state: {
										products: this.state.products,
										eventDetails: location.state ? location.state.eventDetails : undefined,
									},
								}}
							>
								<MDBBtn size="md">
									Next <MDBIcon icon="arrow-right" />
								</MDBBtn>
							</Link>
						)}
					</div>
				</MDBCard>
			</>
		);
	}
}

export default CartView;
