import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MDBIcon } from 'mdbreact';
import styled from 'styled-components';

import { checkExpiredSession, selectErrMsg } from 'util/general';
import { Context } from './Context';

const StyledDisplayText = styled.div`
	font-size: 0.8rem;

	@media (min-width: 768px) {
		font-size: initial;
	}
`;

const StyledH = styled.h4`
	font-size: 1.25rem;

	@media (min-width: 768px) {
		font-size: 1.5rem;
	}
`;

const colors = {
	Open: '#00c851',
	Pending: '#fb3',
	Closed: 'darkgrey',
	Completed: 'darkgrey',
	Voided: 'darkgrey'
};
const icons = {
	Open: <MDBIcon icon="check" />,
	Closed: <MDBIcon icon="check" />,
	Completed: <MDBIcon icon="check" />,
	Pending: <MDBIcon far icon="clock" />,
	Voided: <MDBIcon icon="exclamation" />
};

const Details = () => {
	const ctx = useContext(Context);
	const { order, orderType, user } = ctx.props;

	const updateInQbo = async () => {
		try {
			ctx.setState({ loading: true });
			if (ctx.props.orderType === 'Estimate') {
				await ctx.qbo.updateEstimate();
			} else {
				await ctx.qbo.updateInvoice();
			}
			await ctx.afterUpdate();
		} catch (e) {
			const expiredToken = checkExpiredSession(e);
			ctx.setState({
				expiredToken,
				loading: false,
				error: !expiredToken && selectErrMsg(e)
			});
		}
	};

	return (
		<StyledDisplayText>
			<StyledH>
				{orderType} # {order.DocNumber}
			</StyledH>
			{ctx.props.user.permissions !== 'customer' ? (
				<Link to={`/admin/users/${ctx.props.order.UserId}`}>
					<StyledH className="blue-link d-inline-block" style={{ fontWeight: '400' }}>
						{order.customerName}
					</StyledH>
				</Link>
			) : (
				<StyledH className="d-inline-block">{order.customerName}</StyledH>
			)}
			<div>
				<p>
					<span
						style={{
							backgroundColor: colors[order.status],
							color: 'white',
							borderRadius: '3px',
							padding: '4px 8px'
						}}
					>
						{icons[order.status]}
						{'   '}
						<b className="font-weight-bold">{order.status}</b>
					</span>
				</p>
				<p>
					{user.permissions !== 'customer' &&
						(order.isInQbo
							? `This order is IN Quickbooks ${
									order.hasInvoice || orderType === 'Invoice'
										? `and is linked to an ${orderType === 'Invoice' ? 'estimate' : 'invoice'}`
										: ''
							  }`
							: 'This order is NOT in Quickbooks')}
					{user.permissions === 'customer' &&
						(order.hasInvoice || orderType === 'Invoice') &&
						`This order is linked to an ${orderType === 'Invoice' ? 'estimate' : 'invoice'}`}
				</p>
				{user.permissions !== 'customer' && order.hasBeenModified && order.isInQbo && (
					<p>
						<span className="text-warning font-weight-bold">Pending Update: </span>This order has
						been updated.{' '}
						<span className="text-primary pointer" onClick={() => updateInQbo()}>
							Update it in Quickbooks
						</span>
						{ctx.state.loading && <MDBIcon spin icon="spinner" />}
					</p>
				)}
				{user.permissions === 'customer' && order.hasBeenModified && order.isInQbo && (
					<p>
						<span className="text-warning font-weight-bold">Pending Approval </span>
						{order.orderType === 'Estimate' && (
							<span>You may request an invoice once your update has been approved.</span>
						)}
					</p>
				)}
			</div>
		</StyledDisplayText>
	);
};

export default Details;
