import React from 'react';
import { MDBIcon } from 'mdbreact';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledMessage = styled.div`
	margin-top: 25px;
	text-align: center;
`;

const ContentMessage = ({ icon, size, className, message, far }) => {
	return (
		<StyledMessage>
			<MDBIcon far={far} icon={icon} size={size} className={className} />
			<br />
			<br />
			<span className="font-weight-bolder" style={{ color: '#828b94' }}>
				{message}
			</span>
		</StyledMessage>
	);
};

ContentMessage.propTypes = {
	far: PropTypes.bool,
	icon: PropTypes.string.isRequired,
	size: PropTypes.string.isRequired,
	className: PropTypes.string,
	message: PropTypes.string.isRequired
};

ContentMessage.defaultProps = {
	far: false
};

export default ContentMessage;
