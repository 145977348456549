import React, { useState } from 'react';
import { MDBCard, MDBCardBody, MDBIcon, MDBTooltip } from 'mdbreact';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BackButton, SearchBar } from 'components';
import UserTable from '../users/usersList/UserTable';

const SpanLeft = styled.div`
	display: inline-block;
	border-right: solid 1px #b8b8b8;
	padding-right: 15px;
	cursor: pointer;
`;
const SpanCenter = styled.div`
	display: inline-block;
	padding: 0 15px;
	cursor: pointer;
`;

const CompanyDetails = ({ company }) => {
	const [params, setParams] = useState({ CompanyId: company.id });

	const onChange = e => {
		const newParams = e.target.value
			? { like: { ownCompany: { $like: `${e.target.value}%` } } }
			: {};
		if (!e.target.value) delete params.like;
		setParams({ ...params, ...newParams });
	};
	return (
		<>
			<BackButton />
			<div className="space-between">
				<div className="mb-4">
					<div>
						<div className="mb-3">
							<h3>
								<MDBTooltip domElement placement="bottom">
									<SpanLeft>{`${company.name}`}</SpanLeft>
									<span>Company</span>
								</MDBTooltip>
								<MDBTooltip domElement placement="bottom">
									<SpanCenter>{`${company.displayName}`}</SpanCenter>
									<span>Display name</span>
								</MDBTooltip>
							</h3>
						</div>
						<div className="mb-3">
							<span>
								{`${company.street}, ${company.city}, ${company.state}, ${company.zipCode}`}
							</span>
						</div>
						{company.phone && (
							<div className="mb-3">
								<span style={{ display: 'inline-block', paddingRight: '15px' }}>
									<MDBIcon icon="phone" size="1x" className="mr-2" />
									{'     '}
									{company.phone}
								</span>
							</div>
						)}
					</div>
				</div>
			</div>
			<MDBCard>
				<MDBCardBody>
					<SearchBar className="ml-auto" onChange={onChange} searchPlaceholder="customer" />
					<UserTable params={params} permissions="customer" />
				</MDBCardBody>
			</MDBCard>
		</>
	);
};

CompanyDetails.propTypes = {
	user: PropTypes.object,
	company: PropTypes.object
};

export default CompanyDetails;
