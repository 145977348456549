import React from 'react';
import { Collapse } from 'mdbreact';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledCategoryBtn = styled.div`
	font-size: 18px;
	cursor: pointer;
	color: white;
	padding: 1rem;
`;

const StyleButton = styled.div`
	width: 250px;
	margin-right: 0px;
`;

const StyledCollapse = styled(Collapse)`
	background-color: white;
	padding: 1rem;
	padding-left: 2rem;
`;

const StyledSubCategory = styled.p`
	color: black;
	margin-left: 1rem;
	margin-bottom: 0.5rem;
	margin-top: 1rem;
	font-size: 16px;
	cursor: pointer;
	:hover {
		color: rgb(109, 109, 109);
	}
`;

const SideBarButton = ({
	category,
	subCategories,
	setSideNavCategory,
	setSideNavSubCategory,
	activeSubCategory,
	openedCategory
}) => {
	// styles for sub category active class
	// also used to set and remove active class on a sub category
	const subCatFontWeight = subCategory => {
		if (activeSubCategory === subCategory) {
			return {
				fontWeight: '700',
				marginLeft: 0,
				paddingLeft: '1rem',
				borderLeft: 'solid 4px #495c7a'
			};
		}
	};

	// used to determine if a main category can open or not.
	const canItOpen = () => openedCategory === category;

	const subCategoryClick = subcategory => () =>
		subcategory !== activeSubCategory
			? setSideNavSubCategory(subcategory)
			: null;

	const categoryClick = category => () => {
		return category !== openedCategory ? setSideNavCategory(category) : null;
	};

	return (
		<StyleButton>
			<StyledCategoryBtn
				category={category}
				openedCategory={openedCategory}
				className="mb-0 d-flex justify-content-between shadow main-btn"
				onClick={categoryClick(category)}
			>
				{category}
				{!subCategories.includes('') && <i className="fa fa-angle-down" />}
			</StyledCategoryBtn>
			{!subCategories.includes('') && (
				<StyledCollapse isOpen={canItOpen()}>
					{subCategories &&
						subCategories.map(subcategory => (
							<Link
								key={subcategory}
								to={`/inventory/${category}/${subcategory}`}
							>
								<StyledSubCategory
									style={{ ...subCatFontWeight(subcategory) }}
									onClick={subCategoryClick(subcategory)}
								>
									{subcategory}
								</StyledSubCategory>
							</Link>
						))}
				</StyledCollapse>
			)}
		</StyleButton>
	);
};

SideBarButton.propTypes = {
	category: PropTypes.string.isRequired,
	setSideNavCategory: PropTypes.func.isRequired,
	setSideNavSubCategory: PropTypes.func.isRequired,
	openedCategory: PropTypes.string,
	activeSubCategory: PropTypes.string,
	subCategories: PropTypes.arrayOf(PropTypes.string)
};

export default SideBarButton;
