import React from 'react';
import { Link } from 'react-router-dom';

import UserOptions from './UserOptions';

export const adminTableData = (data, suspendUser, showModal) => {
	const rows = data.map(user => {
		return {
			id: user.id,
			Name: `${user.firstName} ${user.lastName}`,
			Email: user.email,
			Status: (
				<span className={`${user.active ? 'text-success' : 'text-black-50'} font-weight-bold`}>
					{user.active ? 'Active' : 'Disabled'}
				</span>
			),
			Options: (
				<UserOptions
					user={user}
					showModal={showModal(user.id)}
					onSuspend={suspendUser(user.id, user.active)}
				/>
			)
		};
	});

	const columns = ['Name', 'Email', 'Status', 'Options'];

	return {
		columns,
		rows
	};
};

export const customerTableData = (data, suspendUser, showModal) => {
	const rows = data.map(user => {
		return {
			id: user.id,
			'Customer/Company': (
				<>
					<Link className="blue-link" to={`/admin/users/${user.id}`}>
						{user.displayName}
					</Link>
					<div style={{ fontSize: '12px' }}>Company - {user.ownCompany}</div>
				</>
			),
			Name: `${user.firstName} ${user.lastName}`,
			Email: user.email,
			Status: (
				<span className={`${user.active ? 'text-success' : 'text-black-50'} font-weight-bold`}>
					{user.active ? 'Active' : 'Disabled'}
				</span>
			),
			Options: (
				<UserOptions
					user={user}
					showModal={showModal(user.id)}
					onSuspend={suspendUser(user.id, user.active)}
				/>
			)
		};
	});

	const columns = ['Customer/Company', 'Name', 'Email', 'Status', 'Options'];

	return {
		columns,
		rows
	};
};

export const companyTableData = data => {
	const rows = data.map(company => {
		return {
			id: company.id,
			'Display name': (
				<>
					<Link className="blue-link" to={`/admin/company/${company.id}`}>
						{company.displayName}
					</Link>
				</>
			),
			'Company name': company.name
		};
	});
	const columns = ['Display name', 'Company name'];
	return {
		columns,
		rows
	};
};
