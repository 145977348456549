import React, { useContext } from 'react';
import { MDBCard, MDBCardBody } from 'mdbreact';

import { CalculateTotal, Table, QuickAdd } from 'components';
import { Context } from './Context';
import { tableData } from 'pages/checkout/cartView/tableData';
import { productData } from 'pages/checkout/summary/tableData';
import { clone, sumTotal } from 'util/general';

const CartDetails = () => {
	const ctx = useContext(Context);

	const submit = (product, quantity) => {
		product.qty = Number(quantity);
		product.total = product.qty * product.price;
		product.CartId = ctx.props.cart.id;
		const allProducts = clone(ctx.state.cart);
		allProducts.CartProducts = allProducts.CartProducts.map((x) => {
			if (x.id === product.id) {
				return product;
			}
			return x;
		});
		if (!allProducts.CartProducts.find((x) => x.id === product.id)) {
			allProducts.CartProducts.push(product);
		}
		ctx.setState({ cart: allProducts, hasBeenModified: true });
	};

	const onChangeQty = ({ target }) => {
		const productId = target.getAttribute('data-id');
		const value = target.value;
		const cart = clone(ctx.state.cart);
		// updates the appropriate object with the new quantity and price
		const updated = cart.CartProducts.map((product) => {
			if (product.id === productId) {
				if (value > 0 && value <= product.quantity) {
					product.qty = Number(value);
					product.total = Number(product.price) * Number(value);
				}
			}
			return product;
		});

		ctx.setState({
			hasBeenModified: true,
			cart: {
				...ctx.state.cart,
				CartProducts: updated,
			},
		});
	};

	const deleteProduct = (product) => () => {
		const { cart } = ctx.state;
		const deletedProducts = clone(ctx.state.deletedProducts);
		if (product.CartProductId) {
			deletedProducts.push(product.CartProductId);
		}
		ctx.setState((state) => ({
			hasBeenModified: true,
			deletedProducts: Array.from(new Set([...deletedProducts])),
			cart: {
				...state.cart,
				CartProducts: cart.CartProducts.filter((cartProduct) => cartProduct.name !== product.name),
			},
		}));
	};

	return (
		<MDBCard>
			<MDBCardBody>
				<h4 className="text-center mb-2">Cart</h4>
				{ctx.state.editMode && <QuickAdd user={ctx.props.user} onSubmit={submit} />}
				<Table
					data={
						ctx.state.editMode
							? tableData(ctx.state.cart.CartProducts, onChangeQty, deleteProduct, true)
							: productData(ctx.state.cart.CartProducts)
					}
				/>
				<CalculateTotal total={sumTotal(ctx.state.cart)} />
			</MDBCardBody>
		</MDBCard>
	);
};

export default CartDetails;
