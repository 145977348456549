import { productAPI, cartAPI, authAPI } from 'api';

export const updateUserInState = (data = {}) => ({
	type: 'UPDATE_USER_IN_STATE',
	payload: data
});

export const fetchUser = (email, password) => ({
	type: 'FETCH_USER',
	payload: authAPI.fetchUser(email, password)
});

export const fetchCategories = (loading = true) => ({
	meta: {
		loading
	},
	type: 'FETCH_CATEGORIES',
	payload: productAPI.fetchCategories
});

export const setSideNavVisibility = doNotShowSideNav => {
	return {
		type: 'SET_SIDENAV_VISIBILITY',
		payload: doNotShowSideNav
	};
};

export const toggleSidenav = bool => {
	return {
		type: 'TOGGLE_SIDENAV',
		payload: bool
	};
};

export const setSideNavCategory = category => {
	return {
		type: 'SET_SIDENAV_CATEGORY',
		payload: category
	};
};

export const setSideNavSubCategory = subcategory => {
	return {
		type: 'SET_SIDENAV_SUBCATEGORY',
		payload: subcategory
	};
};

export const validateJWT = () => ({
	type: 'VALIDATE_JWT',
	payload: authAPI.validateJWT
});

export const setCart = cart => ({
	type: 'SET_CART',
	payload: cart
});

export const fetchCartTotal = id => ({
	type: 'FETCH_CART_TOTAL',
	payload: cartAPI.getCartProducts(id)
});
