import React from 'react';
import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';

import { InventorySelect } from 'components';
import DisplayPrice from './DisplayPrice';

const AddProductBtn = ({ permissions, product, onChange, saveToCart, selectedQuantity }) => {
	const { price, quantity, id } = product;
	const showProduct = price > 0 && quantity > 0;

	if (!permissions) {
		return null;
	}
	return (
		<DisplayPrice id={id} showProduct={showProduct}>
			<p>${price}</p>
			<p>{quantity} units in inventory</p>
			<p>SKU: {id}</p>
			{permissions === 'customer' && (
				<>
					<InventorySelect
						name="quantity"
						label="Quantity"
						quantity={quantity}
						onChange={onChange}
						value={selectedQuantity}
					/>
					<br />
					<MDBBtn className="ml-0" size="md" onClick={saveToCart}>
						Add To Cart
					</MDBBtn>
				</>
			)}
		</DisplayPrice>
	);
};

AddProductBtn.propTypes = {
	selectedQuantity: PropTypes.number.isRequired,
	permissions: PropTypes.string,
	product: PropTypes.object.isRequired,
	saveToCart: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired
};

export default AddProductBtn;
