import React, { Component, Fragment } from 'react';
import { MDBBtn, MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import PropTypes from 'prop-types';

class Form extends Component {
	static propTypes = {
		columns: PropTypes.number,
		btnText: PropTypes.string,
		autoFocus: PropTypes.bool,
		loading: PropTypes.bool,
		hideBtn: PropTypes.bool,
		btnPosition: PropTypes.string,
		onSubmit: PropTypes.func,
		inputs: PropTypes.array.isRequired,
		onChange: PropTypes.func.isRequired,
		btnSize: PropTypes.string,
		btnIcon: PropTypes.string,
		btnColor: PropTypes.string
	};

	static defaultProps = {
		columns: 1,
		hideBtn: false,
		loading: false,
		btnText: 'Submit',
		btnSize: 'md',
		btnColor: 'default',
		autoFocus: true,
		onSubmit: () => {},
		btnPosition: 'text-right'
	};

	state = {
		inputError: '',
		inputErrorId: ''
	};

	componentDidMount() {
		const input = this.formNode.querySelectorAll('input, textarea, select')[0];
		return this.props.autoFocus && setTimeout(() => input && input.focus(), 400);
	}

	formNode = null;

	onChange = e => {
		const input = this.formNode.querySelectorAll('.is-invalid')[0];
		if (input && input.validity.valid) {
			this.setState({
				inputError: '',
				inputErrorId: ''
			});
		}
		this.props.onChange(e);
	};

	validateForm = () => {
		const inputs = this.formNode.querySelectorAll('input, textarea, select');
		for (let input of inputs) {
			if (!input.validity.valid) {
				const containsError = Object.keys(input.validity).every(val => val === false);
				if (containsError) {
					this.setState({
						inputError: input.getAttribute('data-error-msg') || 'This field is required',
						inputErrorId: `${input.id}`
					});
					input.focus();
					return false;
				}
			}
		}
		this.setState({
			inputError: '',
			inputErrorId: ''
		});
		return true;
	};

	setColumns(arr, n) {
		const size = Math.ceil(arr.length / n);
		const columns = [...arr];
		const dividedColumns = [];
		while (columns.length) {
			dividedColumns.push(columns.splice(0, size));
		}
		return dividedColumns;
	}

	onKeyPress = event => {
		return event.key === 'Enter' && this.onSubmit();
	};

	onSubmit = () => {
		return this.validateForm() && this.props.onSubmit();
	};

	render() {
		const {
			onSubmit,
			onChange,
			setColumns,
			onKeyPress,
			state: { inputError, inputErrorId },
			props: { inputs, columns, loading, btnIcon, btnText, btnPosition, hideBtn, btnColor, btnSize }
		} = this;
		return (
			<form ref={ref => (this.formNode = ref)} onSubmit={e => e.preventDefault()}>
				<MDBRow>
					{setColumns(inputs, columns).map((column, index, columsArr) => (
						<MDBCol key={index} md={(12 / columsArr.length).toString()}>
							{column.map(values => {
								const inputValues = Object.assign({}, values);
								delete inputValues.inputType;
								const Input = values.inputType;
								return (
									<Fragment key={values.id}>
										<Input
											{...inputValues}
											onChange={onChange}
											onKeyPress={inputValues.type !== 'textarea' ? onKeyPress : undefined}
											className={`${
												inputErrorId === values.id ? 'is-invalid' : ''
											} ${inputValues.className || ''}`}
										/>
										{inputErrorId === `${values.id}` && (
											<div className="invalid-feedback" id={`${values.id}-data-error-msg`}>
												{inputError}
											</div>
										)}
									</Fragment>
								);
							})}
						</MDBCol>
					))}
				</MDBRow>
				<div className={`${btnPosition}`}>
					{!hideBtn && (
						<MDBBtn
							type="button"
							disabled={loading}
							size={btnSize}
							color={btnColor}
							onClick={onSubmit}
						>
							{loading ? <MDBIcon icon="spinner" spin /> : btnText}
							{'  '}
							{btnIcon && <MDBIcon icon={btnIcon} />}
						</MDBBtn>
					)}
				</div>
			</form>
		);
	}
}

export default Form;
