import Methods from './Methods';

class QboAPI extends Methods {
	getCategories() {
		return super.get('/qbo/categories');
	}

	renewToken() {
		return super.get('/qbo/get/renewToken');
	}

	getUsers() {
		return super.get('/qbo/users');
	}

	sendConfirmation(orderType, id, email) {
		return super.post(`/qbo/send/${orderType}/${id}`, { email });
	}

	sendEstimateToQbo(data) {
		return super.post('/qbo/estimate', data);
	}

	sendInvoiceToQbo(data) {
		return super.post('/qbo/invoice', data);
	}

	qboCreateUser(data) {
		return super.post('/qbo/user', data);
	}

	qboCreateProduct(data) {
		return super.post('/qbo/product', data);
	}

	qboUpdateProduct(data) {
		return super.put('/qbo/product', data);
	}

	updateEstimateInQbo(data) {
		return super.put('/qbo/estimate/', data);
	}

	updateInvoiceInQbo(data) {
		return super.put('/qbo/invoice/', data);
	}
}

const qboAPI = new QboAPI();

export default qboAPI;
