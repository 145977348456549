import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Loading } from 'components';
import ShowPage from './ShowPage';
import { productAPI } from 'api';
import { setSideNavCategory, setSideNavSubCategory, fetchCategories } from 'redux/actions';

class ShowPageContainer extends Component {
	static propTypes = {
		location: PropTypes.object,
		match: PropTypes.object,
		product: PropTypes.object,
		setSideNavCategory: PropTypes.func,
		setSideNavSubCategory: PropTypes.func
	};

	state = {
		loading: true,
		error: '',
		product: null
	};

	componentDidMount() {
		window.scrollTo(0, 0);
		this.getProduct();
	}

	getProduct = () => {
		productAPI
			.fetch(this.props.match.params.productId)
			.then(res => {
				const [product] = res.data.result;
				this.setState({ product, loading: false, error: '' });
			})
			.catch(() =>
				this.setState({
					error: 'Uh oh, an error occurred while loading the product',
					products: null,
					loading: false
				})
			);
	};

	/**
	 * Closes the sidenav when not in the inventory
	 */
	componentWillUnmount() {
		if (!window.location.pathname.split('/').includes('inventory')) {
			this.props.setSideNavCategory(null);
			this.props.setSideNavSubCategory(null);
		}
	}

	render() {
		const { loading, error, product } = this.state;
		return (
			<Loading loading={loading} error={error} notFound={!product}>
				<ShowPage {...this.props} fetchProduct={this.getProduct} product={product} />
			</Loading>
		);
	}
}

const mapStateToProps = ({
	userDetails: { user },
	sideNavDetails: { categories, hideSideNav }
}) => {
	return {
		user,
		categories,
		hideSideNav
	};
};

export default connect(
	mapStateToProps,
	{
		setSideNavCategory,
		setSideNavSubCategory,
		fetchCategories
	}
)(ShowPageContainer);
