import React, { Component } from 'react';
import { MDBRow, MDBBtn, MDBIcon } from 'mdbreact';
import PropTypes from 'prop-types';

import CartItem from './CartItem';
import { Alert, InfiniteScroll } from 'components';
import { cartAPI } from 'api';

class CartList extends Component {
	static propTypes = {
		carts: PropTypes.array,
		user: PropTypes.object,
		getCarts: PropTypes.func,
		handleError: PropTypes.func,
		totalCount: PropTypes.string
	};

	state = {
		error: ''
	};

	newCart = () => {
		cartAPI
			.createCart({ isActive: false })
			.then(() => this.props.getCarts())
			.catch(() => this.handleError('An error occurred while creating your cart'));
	};

	handleError = error => {
		this.setState({ error });
	};

	onScroll = params => {
		return cartAPI.getCart(undefined, {
			...params,
			didCheckOut: false,
			order: ['createdAt', 'DESC']
		});
	};

	render() {
		return (
			<div>
				<div
					style={{ marginBottom: '10px' }}
					className="d-flex align-items-center justify-content-between"
				>
					<h2>Carts</h2>
					<MDBBtn size="md" onClick={this.newCart}>
						Create Cart
						<MDBIcon icon="wrench" />
					</MDBBtn>
				</div>
				<Alert
					show={this.state.error}
					color="danger"
					dismiss
					onDismiss={() => this.setState({ error: '' })}
				>
					{this.state.error}
				</Alert>
				<InfiniteScroll
					totalCount={this.props.totalCount}
					onScroll={this.onScroll}
					initialData={this.props.carts}
					onError="An error occurred while loading your carts"
				>
					{pagedData => (
						<MDBRow>
							{pagedData.map(cart => (
								<CartItem
									{...this.props}
									cart={cart}
									key={cart.id}
									handleError={this.handleError}
								/>
							))}
						</MDBRow>
					)}
				</InfiniteScroll>
			</div>
		);
	}
}

export default CartList;
