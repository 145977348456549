import React from 'react';
import PropTypes from 'prop-types';

import ProductCard from './ProductCard';
import NewProductContainer from '../newProduct/NewProductContainer';
import CartIcon from '../CartIcon/CartIcon';

const ProductsList = ({ products, user, fetchProducts, fetchCategories, ...props }) => (
	<>
		<NewProductContainer
			fetchCategories={fetchCategories}
			fetchProducts={fetchProducts}
			user={user}
		/>
		{products &&
			products.map(product => (
				<ProductCard key={product.id} product={product} user={user} {...props} />
			))}
		<CartIcon user={user} />
	</>
);

ProductsList.propTypes = {
	products: PropTypes.array,
	user: PropTypes.object,
	fetchProducts: PropTypes.func.isRequired,
	fetchCategories: PropTypes.func.isRequired
};

export default ProductsList;
